import { formatUnits } from "viem";

export const formatTokenBalance = (
  balance: bigint | undefined,
  decimals: number = 18
) => {
  if (balance === undefined) {
    return "0";
  }
  return formatUnits(balance, decimals);
};

import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import { Alert, Col, Container, Row, Card, CardHeader, CardBody } from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "@/common/showToast";
import { getDateMinusDays } from "@/common/query/QueryPeriods";
import DonutChart from "@/common/charts/donut";
import { fetchUsersByCountry, fetchUsersByDevice, fetchUsersByBrowserName } from "@/services/users/users-rest";
import { AxiosError } from "axios";
import UserGrowth from "../analytics/components/DailyUniqueUsers";
import DailyUniqueLogins from "../analytics/components/DailyUniqueVisitors";

const UserAnalytics = () => {
  const today = new Date().toISOString().split("T")[0];

  const [days, setDays] = useState<number>(30);
  const [toDate, setToDate] = useState(today);
  const [fromDate, setFromDate] = useState(() => getDateMinusDays(days));

  const setRange = (range: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setDays(range);
    setFromDate(getDateMinusDays(range));
    setToDate(today);
  };

  const [error, setError] = useState<string | null>(null);

  const [usersByCountry, setUsersByCountry] = useState([]);
  const [usersByDevice, setUsersByDevice] = useState([]);
  const [usersByBrowserName, setUsersByBrowserName] = useState([]);

  useEffect(() => {
    const differenceInDays = Math.abs(new Date(toDate).getTime() - new Date(fromDate).getTime()) / (24 * 60 * 60 * 1000);
    if (differenceInDays < 0) return showToast("Invalid date range", "warning");

    setDays(differenceInDays);

    const fetchData = async () => {
      const params = { fromDate, toDate, groupByDate: true };

      try {
        const usersByCountryResponse = await fetchUsersByCountry(params);
        setUsersByCountry(usersByCountryResponse.data);

        const usersByDeviceResponse = await fetchUsersByDevice(params);
        setUsersByDevice(usersByDeviceResponse.data);

        const usersByBrowserNameResponse = await fetchUsersByBrowserName(params);
        setUsersByBrowserName(usersByBrowserNameResponse.data);
      } catch (error) {
        if (error instanceof AxiosError) {
          showToast(`Error when fetching server data: ${error.message}`, "warning");
        }
      }
    };

    fetchData();
  }, [fromDate, toDate]);

  const handleDateChange = (dates: Date[]) => {
    if (dates.length === 2) {
      const [start, end] = dates.map(date => date.toISOString().split("T")[0]);
      setFromDate(start);
      setToDate(end);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
              <Row className="g-3 mt-5 mb-2 d-flex justify-content-end">
                    <div className="col-sm-auto">
                      <div className="input-group">
                        <Flatpickr
                          className="form-control border-0 dash-filter-picker shadow"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            defaultDate: [fromDate, toDate],
                          }}
                          onChange={handleDateChange}
                        />
                        <div className="input-group-text bg-primary border-primary text-white">
                          <i className="ri-calendar-2-line"></i>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-rows gap-2 justify-content-end">
                      <button className={"btn" + (days === 30 ? " btn-primary" : " btn-dark")} onClick={setRange(30)}>30</button>
                      <button className={"btn" + (days === 60 ? " btn-primary" : " btn-dark")} onClick={setRange(60)}>60</button>
                      <button className={"btn" + (days === 90 ? " btn-primary" : " btn-dark")} onClick={setRange(90)}>90</button>
                  </div>
              </Row>
          </Row>
          <Row>
            <UserGrowth fromDate={fromDate} toDate={toDate} groupByDate={true} />
          </Row>
          <Row>
          <DailyUniqueLogins fromDate={fromDate} toDate={toDate} groupByDate={true} />
          </Row>
          <Row>
            <Col lg={4} sm={12} xs={12}>
              <Card>
                <CardHeader>Users by Country</CardHeader>
                <CardBody>
                  <DonutChart data={usersByCountry} />
                </CardBody>
              </Card>
            </Col>

            <Col lg={4} sm={12} xs={12}>
              <Card>
                <CardHeader>Users by Device</CardHeader>
                <CardBody>
                  <DonutChart data={usersByDevice} />
                </CardBody>
              </Card>
            </Col>

            <Col lg={4} sm={12} xs={12}>
              <Card>
                <CardHeader>Users by Browser</CardHeader>
                <CardBody>
                  <DonutChart data={usersByBrowserName} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default UserAnalytics;

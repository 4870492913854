export enum userTiers {
  BRONZE = 'Bronze Level',
  SILVER = 'Silver Level',
  GOLD = 'Gold Level',
  PLATINUM = 'Platinum Level',
  PHENOM = 'Phenom Level',
}

export interface UserInfo extends CreateUserDto {
  id: string;
  phone: string;
  countryCode: string;
  firstName: string;
  lastName: string;
  referralCode: string;
  updatedAt: Date;
  tierId?: string;
  tier:{name:string},
  isPropPlayer?: boolean;
  readyPlayerMeId?: string;
  privyId?: string;
  referrerCode?: string;
  referralUser: { alias: string; email: string}
  smartWalletAddress: string;
  mainWalletAddress: string;
  lastLogin?: Date;
  totalGeneratedRake?: number;
  createdAt: Date;
  agreement: string;
  avaturnUser: {id: string}
  userAgreements: [{agreement: string; qualifiedReferralsCount: number; createdAt: string}]
  banned: boolean;
}

export interface CreateUserDto {
  alias: string;
  firstName: string;
  lastName: string;
  email: string;
  isAgent: boolean;
  isAmbassador: boolean;
}

// only editable fields
export interface UpdateUserDto  {
  id: string;
  alias: string;
  firstName: string;
  lastName: string;
  isAgent: boolean;
  isAmbassador: boolean;
  referrerCode?: string;
  referralCode?: string;
  tier?: string;
  isPropPlayer?: boolean;
  banned?: boolean;
}

export const converUserInfoToUpdateUserDto = (
  userInfo: UserInfo
): UpdateUserDto => {
  return {
    id: userInfo.id,
    alias: userInfo.alias,
    firstName: userInfo.firstName,
    banned: userInfo.banned,
    lastName: userInfo.lastName,
    isAgent: userInfo.isAgent,
    isAmbassador: userInfo.isAmbassador,
    referrerCode: userInfo.referrerCode,
    referralCode: userInfo.referralCode,
    tier: userInfo?.tier?.name,
    isPropPlayer: userInfo.isPropPlayer,
  };
};

export enum UserRoles {
  NO_ROLE = "No Role",
  AMBASSADOR = "Ambassador",
  AGENT = "Agent",
}

export interface UserSnap {
  userName: string;
  userPic: string;
  userAddress: string;
}

export interface UserWalletChallenge {
  userId: string;
  address: string;
  signature: string;
}

export interface UserWalletChallengeResponse {
  challenge: string;
}

export interface UserWallet {
  address: string;
  userId: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
}

type UserTier = {
  id: string;
  name: string;
  tier: number;
  rakeback: number;
  discount: number;
  description: string;
  rakeRequired: number;
  validForInDays: number;
};

export type UserTierProgression = {
  currentTier: UserTier;
  nextTier: UserTier | null;
  points: number;
  tierProgressPercentage: number;
};

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string | null;
  countryCode: string | null;
  alias: string;
  referralCode: string | null;
  referrerCode: string | null;
  smartWalletAddress?: string
  tierId: string;
  createdAt: Date;
  updatedAt: Date;
  isAgent: boolean;
  isAmbassador: boolean;
  readyPlayerMeId: string | null;
};

export type UpdateUser = Partial<User> & { id: string };



export interface UserQueryDto {
  fromDate: string;
  toDate: string;
  groupByDate?: boolean;
}


export interface UserAggregate {
  _id: string;
  dataRange: string;
  totalAmount: number;
}
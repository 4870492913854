import { KeyFigureType } from "@/services/rake/rake-types";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";

const KeyFigure: React.FC<{ item?: KeyFigureType }> = ({ item }) => {
  if (!item) return null;

  const {
    cardColor = "default",
    label = "",
    badgeClass = "default",
    badge,
    percentage = 0,
    prefix = "",
    suffix = "",
    separator = "",
    counter = 0,
    decimals = 0,
    icon = "",
  } = item;

  return (
    <Col md={4} sm={12}>
      <Card className={`card-animate bg-${cardColor}`}>
        <CardBody>
          <div className="d-flex align-middle">
            <div className="flex-grow-1 overflow-hidden">
              <p className="text-uppercase fw-bold text-white-50 text-truncate mb-0">
                {label}
              </p>
            </div>
            <div className="flex-shrink-0">
              <h5 className={`fs-14 mb-0 text-${badgeClass}`}>
                {badge && <i className={`fs-13 align-middle ${badge}`}></i>}{" "}
                {percentage} %
              </h5>
            </div>
          </div>
          <div className="d-flex align-bottom justify-content-between mt-4">
            <div>
              <h4 className="fs-22 fw-bold ff-secondary mb-4 text-white">
                <span className="counter-value" data-target="559.25">
                  <CountUp
                    start={0}
                    prefix={prefix}
                    suffix={suffix}
                    separator={separator}
                    end={counter}
                    decimals={decimals}
                    duration={0.5}
                  />
                </span>
              </h4>
            </div>
            <div className="avatar-sm flex-shrink-0">
              <span className="avatar-title rounded fs-3 bg-white bg-opacity-10">
                <i className={`text-white ${icon}`}></i>
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default KeyFigure;

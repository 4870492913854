import React, { useEffect, useState } from "react";
import {
  ButtonGroup,
  Card,
  CardBody,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  OnChangeFn,
  ColumnFiltersState,
  getSortedRowModel,
  VisibilityState,
} from "@tanstack/react-table";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchTablesAllRest } from "@/services/table/table-service";
import DebouncedInput from "@/components/DebounceInput";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "@/routes/routes-enums";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "@/common/showToast";

import { GetTableDto } from "@/services/table/table-filters";
import TablesFilter from "./tables-filter";
import { PokerVariantIds, TableStatus } from "@/common/types/phenomTypes";
import Paginator from "../common/paginator";

interface BackCols {
  accessorKey: string;
  header: string;
  type: string;
  isVisible?: boolean;
  size?: number;
  sortingFn?: any
}

const Tables: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tableId = searchParams.get('tableId');
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const goToTableEdit = (tableId: string) => {
    navigate(`${routes.TABLE_EDIT}/${tableId}`);
  };

  const [modal_backdrop, setmodal_backdrop] = useState<boolean>(false);

  const [take, setTake] = useState(100);
  const [skip, setSkip] = useState(0);
  const [getTableDto, setGetTableDto] = useState<GetTableDto>({ skip, take, name: "" });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["tableData", getTableDto],
    queryFn: () => fetchTablesAllRest(getTableDto),
    staleTime: 60000,
  });

  useEffect(() => {
    if (tableId) {
      setGetTableDto((prev: GetTableDto) => ({ ...prev, tableId: parseInt(tableId, 10) }));
    }
  }, [tableId]);

  const [variantsIds] = useState<PokerVariantIds[]>([]);

  useEffect(() => {
    console.log("trigger by error data")
    if (isError) {
      showToast(`Error when fetching server data: ${error.message}`, "warning");
    }
  }, [isError, error?.message]);

  useEffect(() => {

    console.log("trigger by filter params")

    setGetTableDto((prev) => ({ ...prev, skip, take }));
  }, [skip, take]);

  const columns: BackCols[] = [
    { accessorKey: "name", header: "Table Name", type: "string", size: 300 },
    { accessorKey: "variants", header: "Variant", type: "string", isVisible: false },
    { accessorKey: "maxPlayers", header: "Max Players", type: "num" },
    { accessorKey: "seatCount", header: "Players", type: "num" },
    { accessorKey: "minBuyin", header: "Buy In Min", type: "num" },
    { accessorKey: "maxBuyin", header: "Buy In Max", type: "num" },
    { accessorKey: "smallBlind", header: "Small Blind", type: "num" },
    { accessorKey: "bigBlind", header: "Big Blind", type: "num" },
    { accessorKey: "isPublic", header: "Is Public", type: "bool" },
    { accessorKey: "isRealMoney", header: "Is Real Money", type: "bool" },
    { accessorKey: "status", header: "Status", type: "string" },
  ];

  const columnVisibility = columns.reduce((acc: VisibilityState, column: BackCols) => {
    acc[column.accessorKey] = column?.isVisible !== false;
    return acc;
  }, {});

  const tableInstance = useReactTable({
    data: data?.tables || [],
    columns,
    state: {
      columnFilters,
      columnVisibility,
    },
    onColumnFiltersChange: setColumnFilters as OnChangeFn<ColumnFiltersState>,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  const formatCell = (value: any, type: string) => {
    if (type === "bool") {
      return value ? "Yes" : "No";
    }
    return value;
  };

  const handleSearch = (value: string | number) => {

    if (value === getTableDto.name) return

    setIsDebouncing(true);
    setSkip(0);
    setGetTableDto((prev: GetTableDto) => ({ ...prev, name: value.toString() }));
    setIsDebouncing(false);
  };

  const getSortIcon = (columnId: string) => {
    const sort =  tableInstance.getState().sorting?.find(sort => sort.id === columnId);
    if (sort) {
      return sort.desc === false ? <i className="ri-arrow-up-s-line" /> : <i className="ri-arrow-down-s-line" />;
    } else {
      return <i className="ms-1 ri-arrow-up-down-line" />;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <div>
              <div>
                <div className="d-flex flex-row mb-3 gap-3">
                  <DebouncedInput
                    type="text"
                    className="form-control"
                    placeholder="Search by name..."
                    onChange={handleSearch}
                    value={getTableDto?.name ? getTableDto?.name : ""}
                    isLoading={isDebouncing}
                  />
                  <div className="col-auto me-auto d-flex flex-row gap-4">
                    <button
                      type="button"
                      className="btn btn-soft-primary text-white"
                      onClick={() => navigate(routes.TABLE_CREATE)}
                    >
                      <i className="ri-add-circle-line align-middle me-1"></i>{" "}
                      Create Table
                    </button>
                    <button
                      type="button"
                      className="btn btn-soft-primary text-white"
                      onClick={() => setmodal_backdrop(true)}
                    >
                      <i className="ri-filter-3-line align-middle me-1"></i>{" "}
                      Filter
                    </button>
                  </div>
                </div>
                <TablesFilter
                  modal_backdrop={modal_backdrop}
                  tog_backdrop={() => setmodal_backdrop(!modal_backdrop)}
                  getTableDto={getTableDto}
                  setGetTableDto={setGetTableDto}
                  variants={variantsIds}
                />
                <Card className="card mb-2">
                  <CardBody className="card-body overflow-auto">
                    <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                      <thead>
                        {tableInstance.getHeaderGroups().map((headerGroup) => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <th key={header.id} className="cursor-pointer">
                                {header.isPlaceholder ? null : (
                                  <span onClick={header.column.getToggleSortingHandler()}>
                                    {
                                      columns.find(
                                        (column) =>
                                          column.accessorKey === header.id &&
                                          column.isVisible !== false
                                      )?.header
                                    }
                                    {getSortIcon(header.id)}
                                  </span>
                                )}
                              </th>
                            ))}
                            <th>
                              Actions
                            </th>
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {!isLoading && tableInstance.getRowModel().rows.map((row) => (
                          <tr key={row.original.id}>
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <td 
                                  key={cell.id}
                                  style={{
                                    maxWidth: `${cell.column.getSize()}px`
                                  }}
                                >
                                  {formatCell(cell.getValue(), columns.find(col => col.accessorKey === cell.column.id)?.type || "")}
                                  {cell.column.id === 'name' && (
                                    <p className="small">{cell.row.original.variants[0]?.replace(/([a-z])([A-Z])/g, '$1 $2')}</p>
                                  )}
                                </td>
                              );
                            })}
                            <td>
                              <ButtonGroup>
                                  <UncontrolledDropdown>
                                      <DropdownToggle tag="button" className="btn btn-soft-primary">
                                          <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdownmenu-primary">
                                          <DropdownItem onClick={() => {
                                                const url = `${process.env.REACT_APP_PHENOM_APP}/table/${row.original.id}`;
                                                const width = 1440;
                                                const height = 900;
                                                window.open(url, row.original.id, `height=${height},width=${width}`);
                                              }}>
                                              Open
                                          </DropdownItem>
                                          <DropdownItem onClick={() => navigate(`${routes.TABLE}/${row.original.id}`)}
                                            >
                                              Details
                                          </DropdownItem>
                                          <DropdownItem
                                                onClick={() => goToTableEdit(row.original.id)}
                                                    
                                                >
                                                  Edit
                                          </DropdownItem>
                                      </DropdownMenu>
                                  </UncontrolledDropdown>
                              </ButtonGroup>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <Row className="align-middle mt-2 g-3 text-center text-sm-start">
            <Paginator
              take={take}
              skip={skip}
              showing={data?.tables.length || 0}
              totalCount={data?.totalCount || 0}
              setTake={setTake}
              setSkip={setSkip}
            />
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Tables;

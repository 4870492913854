import axios from "axios";
import { getAccessToken } from "@privy-io/react-auth";

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_PHENOM_API}`,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async (config) => {

    const authToken = await getAccessToken();

    if (!authToken) throw new Error("User not authenticated");

    if (!config.headers["Authorization"])
      config.headers["Authorization"] = `Bearer ${authToken}`;

    return config;
  },
  (error) => Promise.reject(error),
);

import { useState } from 'react';
import { 
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    flexRender
} from "@tanstack/react-table";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import {
    Card, 
    CardBody, 
    Col, 
    Container, 
    Row,
    Button,
    Input,
    Spinner
} from "reactstrap";
import { fetchTableDiffsRest, transferFromTableRest, fundTableRest } from '@/services/table/table-service';
import { useQuery } from '@tanstack/react-query';
import config from "@/config/index";

const TableDiffActions = ({ tableDiffRow }: {
    tableDiffRow: any,
}) => {
    const [resolveAmount, setResolveAmount] = useState(tableDiffRow.diff);
    const [showLoading, setShowLoading] = useState<boolean | null>(null);

    const handleSyncTable = () => {
        const resolveTableParams = {
            contractAddress: tableDiffRow.contractAddress,
            contractId: tableDiffRow.contractId,
            amount: Math.abs(resolveAmount),
            addressTo: config.contracts.smartAccount,
            transactionType: 'RECOVER_FUNDS',
        };

        if (resolveAmount < 0) {
            fundTableRest(resolveTableParams);
        } else if (resolveAmount > 0) {
            transferFromTableRest(resolveTableParams);
        }

        setShowLoading(true);

        setTimeout(() => {
            setShowLoading(false);
        }, 10000);
    }

    return (
        <Row spacing="2">
            {showLoading ? (
                <div className="align-items-center justify-content-center">
                    <Spinner />
                    <p>Processing</p>
                </div>
            ) : (
                <>
                    <Input value={resolveAmount} onChange={e => setResolveAmount(e.target.value)} type="number" placeholder={tableDiffRow.diff?.toFixed(2)} />
                    <Button onClick={handleSyncTable} color="primary">
                        {tableDiffRow.diff < 0 ? 'Add' : 'Remove'}
                    </Button>
                </>
            )}
        </Row>
    )
}

const TableSync = () => {
    const { data: tableDiffs } = useQuery({
		queryKey: ['fetchTableDiffs'],
		queryFn: () => fetchTableDiffsRest(),
	});

    const table = useReactTable({
        data: tableDiffs,
        columns: [
            {
                accessorKey: 'tableName',
                header: 'Table Name',
            },
            {
                accessorKey: 'tableBalance',
                header: 'Table Balance',
                cell: (cell) => cell.getValue()?.toFixed(2)
            },
            {
                accessorKey: 'contractBalance',
                header: 'Contract Balance',
                cell: (cell) => cell.getValue()?.toFixed(2)
            },
            {
                accessorKey: 'tablePlayers',
                header: 'Table Players',
            },
            {
                accessorKey: 'contractPlayers',
                header: 'Contract Players',
            },
            {
                accessorKey: 'diff',
                header: 'Balance Diff',
                cell: (cell) => cell.getValue()?.toFixed(2)
            },
            {
                header: 'Action',
                cell: ({ row }) => row.original.diff !== 0 && <TableDiffActions tableDiffRow={row.original} />
            }
        ],
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <div className="page-content mb-6">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Card className="card mb-2">
                <CardBody className="card-body">
                  <h3 className="mb-5">Sync Table</h3>                  
                </CardBody>
                {!tableDiffs ? (
                    <h3 className="text-center py-4">Loading Table Diffs...</h3>
                ) : (
                    <table className="table table-centered align-middle table-no-wrap" style={{ borderTop: "2px solid #000" }}>
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup?.headers.map(header => (
                                        <th
                                            role="button"
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            onClick={header.column.getToggleSortingHandler()}
                                            style={{ width: `${header.getSize()}px` }}
                                        >
                                            <div className="d-flex justify-content-start align-items-start gap-2">
                                                <div>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                </div>
                                                <div>
                                                    {{
                                                        asc: <FaArrowDown />,
                                                        desc: <FaArrowUp />,
                                                        default: (
                                                            <span>
                                                            <FaArrowDown/>
                                                            <FaArrowUp />
                                                            </span>
                                                        )
                                                    }[header.column.getIsSorted() as string || 'default']}
                                                </div>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map(row => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
}

export default TableSync;
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
} from "reactstrap";

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  ColumnFilter,
  OnChangeFn,
  ColumnFiltersState,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table";

import { useQuery } from "@tanstack/react-query";

import { fetchTemplatesRest } from "@/services/template/templateRest";

import { useNavigate } from "react-router-dom";
import routes from "@/routes/routes-enums";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "@/common/showToast";
import DebouncedInput from "@/components/DebounceInput";

import TemplateFilter from "@/pages/template/templates-filter";

import { GetTableTemplateDto } from "@/services/template/templateFilters";
import Paginator from "../common/paginator"; // Ensure this import is correct

const Templates: React.FC = () => {
  const navigate = useNavigate();

  const goToTemplateTables = (id: string) => {
    navigate(`${routes.TABLES}?templateId=${id.toString()}`);
  };

  const goToEditTemplate = (id: string) => {
    navigate(`${routes.TEMPLATE}/${id.toString()}`);
  };

  const goToTableCreate = () => {
    navigate(`${routes.TEMPLATE_CREATE}`);
  };

  const [modal_backdrop, setmodal_backdrop] = useState<boolean>(false);
  
  const [skip, setSkip] = useState<number>(0);
  const [take, setTake] = useState<number>(25);

  const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([]);
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);
  const [getTableTemplateDto, setGetTableTemplateDto] = useState<GetTableTemplateDto>({ skip, take });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["fetchTables", getTableTemplateDto],
    queryFn: () => fetchTemplatesRest(getTableTemplateDto),
    staleTime: 60000,
  });

  useEffect(() => {
    if (isError) {
      showToast(`Error when fetching server data: ${error.message}`, "warning");
    }
  }, [isError, error]);

  useEffect(() => {
    setGetTableTemplateDto((prev) => ({ ...prev, skip, take }));
  }, [skip, take]);

  const columns = [
    {
      accessorKey: "name",
      header: "Template Name",
      type: "string",
    },
    {
      accessorKey: "name",
      header: "Game Name",
      type: "string",
    },
    {
      accessorKey: "maxPlayers",
      header: "Max Players",
      type: "num",
    },
    {
      accessorKey: "minBuyin",
      header: "Buy In Min",
      type: "num",
    },
    {
      accessorKey: "maxBuyin",
      header: "Buy In Max",
      type: "num",
    },
    {
      accessorKey: "smallBlind",
      header: "Small Blind",
      type: "num",
    },
    {
      accessorKey: "bigBlind",
      header: "Big Blind",
      type: "num",
    },
    {
      accessorKey: "rakePercent",
      header: "Rake Percent",
      type: "num",
    },
    {
      accessorKey: "rakeCap",
      header: "Rake Cap",
      type: "num",
    },
  ];

  const tableInstance = useReactTable({
    data: data?.tableTemplates || [],
    columns,
    state: {
      columnFilters,
      sorting: sortingState,
    },
    onColumnFiltersChange: setColumnFilters as OnChangeFn<ColumnFiltersState>,
    onSortingChange: setSortingState as OnChangeFn<SortingState>,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  const formatCell = (value: any, accessorKey: string) => {
    if (accessorKey === "variants" && Array.isArray(value)) {
      return value.join(", ");
    }
    return value;
  };

  const [valueSearch, setValueSearch] = useState<string | number>("");

  const handleSearch = (value: string | number) => {
    setIsDebouncing(true);
    setValueSearch(value);
    setSkip(0);
    setColumnFilters([{ id: 'name', value }]); // Assuming 'name' is the accessor for the Name column
    setIsDebouncing(false);
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <div>
              <div>
                <div className="d-flex flex-row mb-3 gap-3">
                  <DebouncedInput
                    type="text"
                    className="form-control"
                    placeholder="Search by name..."
                    onChange={handleSearch}
                    value={valueSearch}
                    isLoading={isDebouncing}
                  />
                  <div className="col-auto me-auto d-flex flex-row gap-4">
                    <button
                      type="button"
                      className="btn btn-soft-primary text-white"
                      onClick={goToTableCreate}
                    >
                      <i className="ri-add-circle-line align-middle me-1"></i>{" "}
                      Create
                    </button>
                    <button
                      type="button"
                      className="btn btn-soft-primary text-white"
                      onClick={() => setmodal_backdrop(true)}
                    >
                      <i className="ri-filter-3-line align-middle me-1"></i>{" "}
                      Filter
                    </button>
                  </div>
                </div>

                <TemplateFilter
                  modal_backdrop={modal_backdrop}
                  tog_backdrop={() => setmodal_backdrop(!modal_backdrop)}
                  getTableTemplateDto={getTableTemplateDto}
                  setGetTableTemplateDto={setGetTableTemplateDto}
                />

                <Card className="card mb-2">
                  <CardBody className="card-body">
                    <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                      <thead>
                        {tableInstance.getHeaderGroups().map((headerGroup) => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <th key={header.id} className="cursor-pointer">
                                {header.isPlaceholder ? null : (
                                  <>
                                    <span onClick={() => header.column.toggleSorting()}>
                                      {
                                        columns.filter(
                                          (column) =>
                                            column.accessorKey === header.id
                                        )[0]?.header
                                      }
                                      {header.column.getIsSorted() ? (
                                        header.column.getIsSorted() === "desc" ? (
                                          <i className=" ri-arrow-down-s-line" />
                                        ) : (
                                          <i className=" ri-arrow-up-s-line" />
                                        )
                                      ) : (
                                        <i className="ms-1 ri-arrow-up-down-line" />
                                      )}
                                    </span>
                                  </>
                                )}
                              </th>
                            ))}
                            <th>
                              Actions
                            </th>
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        { !isLoading && tableInstance.getRowModel().rows.map((row) => (
                          <tr key={row.id}>
                            {row.getAllCells().map((cell) => (
                              <td key={cell.id}>
                                {formatCell(cell?.renderValue(), cell.column.id)}
                              </td>
                            ))}
                            <td className="d-flex flex-rows gap-2">
                              <button
                                onClick={() => goToTemplateTables(String(row.original.id))} 
                                className="btn btn-primary">
                                <i className="ri-filter-3-line"></i>
                              </button>
                              <button
                                onClick={() => goToEditTemplate(String(row.original.id))} 
                                className="btn btn-primary">
                                <i className="ri-edit-2-line"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>

                <Row className="align-middle mt-2 g-3 text-center text-sm-start">
                  <Paginator
                    take={take}
                    skip={skip}
                    showing={data?.tableTemplates?.length || 0}
                    totalCount={data?.totalCount || 0}
                    setTake={setTake}
                    setSkip={setSkip}
                  />
                </Row>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Templates;

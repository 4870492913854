import { transferLeftoverRevenueToTreasuryRest } from "@/services/revenue-share/revenue-share-service";
import {
  fundsRewardsManagerRest,
  payRewardsRest,
} from "@/services/rewards/rewards-manager-service";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { WizardTransition } from "../token-flow-orchestrator";
import { useState } from "react";
import { ContractInfo } from "@/components/ContractInfo";
import config from "@/config";

const RewardsDistribution: React.FC<WizardTransition> = ({
  currentState,
  setState,
  setToast,
  enabled,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const rewardsDistribution = () => {
    setSubmitting(true);
    payRewardsRest()
      .then(() => {
        setToast("Rewards Payed", "success");
        setState(9);
      })
      .catch((error) => {
        setToast(`Paying rewards failed ${error}`, "warning");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Card>
      <CardHeader>
        <h2>
          <span className="btn btn-light mx-2">8</span> Rewards Distribution
        </h2>
      </CardHeader>
      <CardBody>
        <ContractInfo
          className="col-sm-12 col-lg-3"
          style={{ borderWidth: 1, minWidth: "fit-content" }}
          contractAddress={config.contracts.rewardsManager}
          contractName="RewardsManager"
          infoConfig={{
            showUsdtBalance: false,
            showPhnmBalance: true,
            variablesToShow: [],
          }}
        />
        <p>
          Calculate the total referral rewards and bonuses for each user, as
          well as the total rake rewards. Create transactions to payout the
          rewards through the RewardsManager contract. Once the payments are
          processed, update the backend records with the payment status and
          transaction details, completing the entire process.
        </p>
      </CardBody>
      <CardFooter>
        <div className="d-flex justify-content-end gap-2">
          <button
            disabled={submitting || !enabled}
            className="btn btn-primary"
            onClick={() => {
              if (!enabled) {
                setToast("Please follow Orchestrator steps", "warning");
                return;
              }
              rewardsDistribution();
            }}
          >
            {submitting ? "Paying Rewards..." : "Pay Rewards"}
          </button>
        </div>
      </CardFooter>
    </Card>
  );
};

export default RewardsDistribution;

export const COLORS = {
  backgroundDark: "#12101A",
  black: "#797979",
  blue: "#0870D9",
  blueElectric: "#3939E8",
  brown: "#4D2400",
  cyan: "#00E6E6",
  dirtyWhite: "#FFFFFF1A",
  grayBaltic: "#302C32",
  grayBalticStrong: "#9f9f9f",
  grayCod: "#191919",
  grayCodLight: "#FFFFFF4D",
  grayDark: "#FFFFFF80",
  grayEvening: "#676879",
  grayField: "#363149",
  grayInactive: "#ffffff30",
  grayJumbo: "#7E7E8B",
  grayMercury: "#222121",
  graySilver: "#FFFFFF4D",
  grayWhite: "#EEEEEE",
  green: "#34C759",
  greenLigth: "#46D34C",
  greenNotes: "#0DC257",
  greenCurrency: "#009973",
  greenDark: "#17B26A",
  orange: "#E39D02",
  orangeBurned: "#A35205",
  orangeDark: "#9F3C22",
  orangeSecondary: "#E89134",
  pink: "#F753C0",
  primaryViolet: "#A958D7",
  primaryVioletLighter: "#B968E2",
  purple: "#A046D3",
  purpleDark: "#A046D31A",
  purpleExtraLight: "#a046d3",
  purpleExtraLightOpacity80: "#a046d380",
  purpleGray: "#363149",
  purpleGrayDark: "#392D45",
  purpleGrayLighter: "#4A4761",
  red: "#FF3B30",
  redSecondary: "#C54528",
  redDarker: "#E30001",
  redLigth: "#D36246",
  secondaryPortage: "#8585F6",
  violetChark: "#130f19",
  violetDark: "#2c273f",
  violetDarker: "#291A3D",
  violetLight: "#363249",
  violetMedium: "#191627",
  violetVeryDark: "#A046D333",
  white: "#FFFFFF",
  white10: "#FFFFFF1A",
  white20: "#FFFFFF33",
  white30: "#FFFFFF4D",
  white40: "#FFFFFF66",
  white50: "#FFFFFF80",
  white60: "#FFFFFF99",
  yellow: "#FFCC00",
  yellowDark: "#F3AA3C",
  yellowLight: "#E7E600",
};

import React from 'react';
import { Col, Row } from 'reactstrap';

const Paginator: React.FC<{
  take: number;
  skip: number;
  showing: number;
  totalCount: number;
  setTake: (value: number) => void;
  setSkip: (value: number) => void;
}> = ({ take, skip, showing, totalCount, setTake, setSkip }) => {
  
  const currentPage = Math.floor(skip / take) + 1;
  const totalPages = Math.ceil(totalCount / take);

  const previousPage = () => {
    setSkip(Math.max(0, skip - take));
  };

  const nextPage = () => {
    setSkip(Math.min(totalCount - take, skip + take));
  };

  const fullPage = () => {
    setTake(totalCount);
    setSkip(0);
  };

  const goToPage = (page: number) => {
    setSkip((page - 1) * take);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
          <button className="page-link" onClick={() => goToPage(i)}>
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <Row className="align-middle mt-2 g-3 text-center text-sm-start">
      <Col sm>
        <div className="text-muted d-flex flex-row gap-2">
          <div>
            Showing
            <span className="fw-semibold ms-1">{showing}</span> of{" "}
            <span className="fw-semibold">{totalCount}</span> Results
          </div>

          {totalCount > take && 
          <button className="page-link text-decoration-underline" onClick={fullPage}>
            show all
          </button>
          }
        </div>
      </Col>
      {totalCount > 0 && (
        <Col sm="auto">
          <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
            {currentPage > 1 && (
              <li className="page-item">
                <button className="page-link" onClick={previousPage}>
                  Previous
                </button>
              </li>
            )}
            {renderPageNumbers()}
            {currentPage < totalPages && (
              <li className="page-item">
                <button className="page-link" onClick={nextPage}>
                  Next
                </button>
              </li>
            )}
          </ul>
        </Col>
      )}
    </Row>
  );
};

export default Paginator;
import React, { useEffect, useState, useCallback } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { PokerVariantIds } from "@/common/types/phenomTypes";
import FormFields from "@/common/forms/FormFields";
import checkVariants from "@/common/forms/table-handling/checkVariants";
import { GameParamDto, BlindStructureDto } from "@/services/game-params/parameters-types";
import InfoField from "@/common/forms/InfoField";
import { blindStructureRest } from "@/services/game-params/parameters-services";

interface TableGameParamProps {
  variant: PokerVariantIds;
  params: Partial<GameParamDto>;
  mixedGame: boolean;
}

const TableGameParamShow: React.FC<TableGameParamProps> = ({ variant, params, mixedGame }) => {
  const { stud: isStud, limit: isLimitGame } = checkVariants([variant]);

  const [ blinds, setBlinds ] = useState<BlindStructureDto | null>(null);


  useEffect(()=>{

    if (!params.rakeStructureId) return

    blindStructureRest(params.rakeStructureId).then((response)=>{setBlinds(response)})


  },[params.rakeStructureId])


  return (
    <Row className="justify-content-center">
      <Col lg={8}>
        <Card className="card mb-2 shadow-lg p-3 mb-5 rounded"  style={{backgroundColor:"#d4d4d6",color:"black"}}>
          <CardBody className="card-body">
            <h4 className="mb-3"  style={{color:"black"}}>Params for {variant}</h4>
            <form>

            <FormFields>
                

                <InfoField
                  name="ante"
                  value={params.ante}
                  lg={6}
                  prefix={"$"}
                />
              </FormFields>

         {(mixedGame && !isLimitGame) && 
              <FormFields>
                <InfoField
                  name="Small Blind Ante"
                  value={params?.sbAnte}
                  lg={6}
                  prefix={"$"}
                />
                <InfoField
                  name="Big Blind Ante"
                  value={params?.bbAnte}
                  lg={6}
                  prefix={"$"}
                />
              </FormFields>
            } 

            {!isLimitGame &&
              <FormFields>
                <InfoField
                  name="Small Blind"
                  value={params?.smallBlind}
                  lg={6}
                  prefix={"$"}
                />
                <InfoField
                  name="Big Blind"
                  value={params?.bigBlind}
                  lg={6}
                  prefix={"$"}
                />
              </FormFields>
              }

              {isLimitGame && (
                <FormFields>
                  <InfoField
                    name="Small Bet"
                    value={params?.smallBet}
                    lg={6}
                    prefix={"$"}
                  />
                  <InfoField
                    name="Big Bet"
                    value={params?.bigBet}
                    lg={6}
                    prefix={"$"}
                  />
                </FormFields>
              )}
              {isLimitGame && (
              <FormFields>
                  <InfoField
                  name="bettingCap"
                  value={params?.bettingCap}
                  lg={6}
                  prefix={"$"}
                />
              </FormFields>
              )}
              {isStud && (
              <FormFields>
                <InfoField
                  name="bringIn"
                  value={params.bringIn}
                  lg={6}
                  prefix={"$"}
                />
              </FormFields>
              )}

              <FormFields>
                <InfoField
                  name="Rake Percent"
                  value={blinds && blinds.rakePercent * 100}
                  lg={6}
                  prefix={"%"}
                />
              </FormFields>
              <FormFields>
                <InfoField
                  name="Rake Cap"
                  value={blinds?.rakeCap}
                  lg={6}
                  prefix={"$"}
                />
                <InfoField
                  name="Rake CapHU"
                  value={blinds?.rakeCapHU}
                  lg={6}
                  prefix={"$"}
                />
              </FormFields>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default TableGameParamShow
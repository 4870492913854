import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import React, { useState } from "react";
import { transferPHNMTotreasuryRest } from "@/services/token/token-services";
import config from "@/config/index";
import { WizardTransition } from "../token-flow-orchestrator";
import { ContractInfo } from "@/components/ContractInfo";

const TransferPHNMtoTreasury: React.FC<WizardTransition> = ({
  currentState,
  setState,
  setToast,
  enabled,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const TransferPHNMtoTreasury = () => {
    setSubmitting(true);
    transferPHNMTotreasuryRest()
      .then(() => {
        setToast("Transferred PHNM balance successfully", "success");
        setState(5);
      })
      .catch((error) => {
        setToast(`Transferring PHNM balance failed ${error}`, "warning");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Card>
      <CardHeader>
        <h2>
          <span className="btn btn-light mx-2">4</span>Transfer PHNM to Treasury
        </h2>
      </CardHeader>
      <CardBody>
        <ContractInfo
          className="col-sm-12 col-lg-3"
          style={{ borderWidth: 1, minWidth: "fit-content" }}
          contractAddress={config.contracts.redemptionVault}
          contractName="RedemptionVault"
          infoConfig={{
            showUsdtBalance: false,
            showPhnmBalance: true,
            variablesToShow: [],
          }}
        />
        <div>
          {" "}
          <p>
            Transfer all PHNM tokens from the Redemption contract to the
            SafeTreasury. The successful transfer will transition the
            orchestrator to the next state.
          </p>
        </div>
      </CardBody>
      <CardFooter>
        <div className="d-flex justify-content-end gap-2">
          <button
            disabled={submitting || !enabled}
            className="btn btn-primary"
            onClick={() => {
              if (!enabled) {
                setToast("Please follow Orchestrator steps", "warning");
                return;
              }
              TransferPHNMtoTreasury();
            }}
          >
            {submitting ? "Transferring..." : "Transfer"}
          </button>
        </div>
      </CardFooter>
    </Card>
  );
};

export default TransferPHNMtoTreasury;

import {
  PokerVariantIds,
} from "@/common/types/phenomTypes";

const checkVariants = (
  variants: PokerVariantIds[]
): {
  stud: boolean;
  limit: boolean;
  capped: boolean;
  mixed: boolean;
} => {
  let stud = false;
  let limit = false;
  let capped = false;
  let mixed = false;

  if (variants.length > 1) {
    mixed = true;
  }

  for (const variant of variants) {

    if (variant.includes("Stud")) {
      stud = true;
    }

    if (variant.includes("FixedLimit")) {
      limit = true;
    }


    if (variant.includes("Capped")) {
      capped = true;
    }

  }


  return { stud, limit, capped, mixed };
};

export default checkVariants;

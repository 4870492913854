import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { KeyFigureType, RakeAggregate } from "@/services/rake/rake-types";
import KeyFigure from "@/common/charts/KeyFigure";
import { Col, Row, Alert } from "reactstrap";
import { QueryPeriods } from "@/common/QueryPeriods";
import { fetchAggregatedRakeRest } from "@/services/rake/rake-service";

const RakeFigures = () => {
  const toDate = useMemo(() => new Date().toISOString().split("T")[0], []);
  const fromDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date.toISOString().split("T")[0];
  }, []);

  const params = useMemo(
    () => ({
      fromDate,
      toDate,
      period: QueryPeriods.day,
      groupByDate: true,
    }),
    [fromDate, toDate]
  );

  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["rakeData", params],
    queryFn: () => fetchAggregatedRakeRest(params),
    staleTime: 60000, // Cache results for 1 minute
  });

  const calculateVariation = useCallback(
    (current: number, previous: number): string => {
      return previous > 0 ? ((current / previous - 1) * 100).toFixed(2) : "0";
    },
    []
  );

  const getRollingSum = useCallback(
    (series: RakeAggregate[], days: number): number => {
      const n = series.length;
      return series
        .slice(Math.max(0, n - days), n)
        .reduce((sum, entry) => sum + entry.totalAmount, 0);
    },
    []
  );

  const calculateKeyFigures = useCallback(
    (series: RakeAggregate[]) => {
      if (!series || series.length === 0) return {};

      const n = series.length;
      const today = series[n - 1].totalAmount;
      const yesterday = series[n - 2]?.totalAmount || 0;
      const variation = calculateVariation(today, yesterday);

      const today7DaySum = getRollingSum(series, Math.min(7, n));
      const variation7Day =
        series.length >= 14
          ? calculateVariation(
              today7DaySum,
              getRollingSum(series, 14) - today7DaySum
            )
          : "0";

      const today30DaySum = getRollingSum(series, Math.min(30, n));
      const variation30Day =
        series.length >= 60
          ? calculateVariation(
              today30DaySum,
              getRollingSum(series, 60) - today30DaySum
            )
          : "0";

      const makeFigure = (stat: string) => {
        return {
          badge: Number(stat) < 0 ? 'ri-arrow-right-down-line' : 'ri-arrow-right-up-line',
          badgeClass: Number(stat) < 0 ? 'danger' : 'success',
          percentage: stat,
          separator: ",",
          prefix: "$",
          suffix: "",
          decimals: 2,
          icon: "bx bx-dollar-circle",
          bgcolor: "secondary",
          cardColor: "primary",
        };
      };

      return {
        dayRake: {
          id: 1,
          label: "Today's Rake",
          counter: today,
          link: "View day rake",
          ...makeFigure(variation),
        },
        weekRake: {
          id: 2,
          label: "Rolling 7 days",
          counter: today7DaySum,
          link: "View week rake",
          ...makeFigure(variation7Day)
        },
        monthRake: {
          id: 3,
          label: "Rolling 30 days",
          counter: today30DaySum,
          link: "View month rake",
          ...makeFigure(variation30Day)
        },
      };
    },
    [calculateVariation, getRollingSum]
  );

  const { dayRake, weekRake, monthRake } = useMemo(
    () => calculateKeyFigures(data || []),
    [data, calculateKeyFigures]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return (
      <Alert color="danger">
        {error?.message || "Failed to fetch data. Please try again later."}
      </Alert>
    );
  }

  return (
    <Row className="gy-2 mb-2 d-flex flex-row justify-content-between">
      <KeyFigure item={dayRake} />
      <KeyFigure item={weekRake} />
      <KeyFigure item={monthRake} />
    </Row>
  );
};

export default RakeFigures;

import axios from "axios";
import { api } from "../api";
import {
  AvaturnSessionResponse,
  AvaturnUser,
  NewAvaturnSession,
  UpdateAvaturnUser,
} from "./avaturn.types";

export const createAvaturnSession = async (avaturnData: NewAvaturnSession) =>
  await api.post<AvaturnSessionResponse>(
    `/v1/avaturn/create-session`,
    avaturnData,
  );

export const createAvaturnUser = async (userId: string) =>
  await api.post<AvaturnUser>(`/v1/avaturn/${userId}`);

export const udpateAvaturnUser = async ({
  id,
  avaturnUser,
}: UpdateAvaturnUser) =>
  await api.patch<AvaturnUser>(`/v1/avaturn/${id}`, avaturnUser);

export const getAvaturnAvatar = async (userId: string) =>
  await api.get<AvaturnUser>(`/v1/avaturn/${userId}`);

export const getAvatarModel = async (url: string) => {
  return axios.get(url);
};

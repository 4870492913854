import { PokerVariantIds } from "@/common/types/phenomTypes";
import { GetTableTemplateDto } from "@/services/template/templateFilters";
import React, { useState } from "react";
import { Modal, ModalBody, Col, Input, ModalHeader, ModalFooter } from "reactstrap";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import './templates-filter.css';


const enum Stakes {
  MICRO = "Micro",
  MEDIUM = "Medium",
  HIGH = "High"
}

export const TemplateFilter: React.FC<{
  modal_backdrop: boolean;
  tog_backdrop: () => void;
  getTableTemplateDto: GetTableTemplateDto;
  setGetTableTemplateDto: (state: GetTableTemplateDto) => void;
}> = ({
  modal_backdrop,
  tog_backdrop,
  getTableTemplateDto,
  setGetTableTemplateDto,
}) => {

  const [minBuyin, setMinBuyIn] = useState<number>(0);
  const [smallBlind, setSmallBlind] = useState<number>(0);
  const [maxBuyin, setMaxBuyIn] = useState<number>(100);
  const [bigBlind, setBigBlind] = useState<number>(0.2);
  const [stakes, setStakes] = useState<Stakes>(Stakes.MICRO);
  

  const [buyInRange, setBuyinRange] = useState({
    min: getTableTemplateDto.minBuyin || minBuyin || 0 ,
    max: getTableTemplateDto.maxBuyin || maxBuyin,
  });

  const [blindRange, setBlindRange] = useState({
    min: getTableTemplateDto.smallBlind || smallBlind || 0,
    max: getTableTemplateDto.bigBlind || bigBlind,
  });

  const [maxPlayers, setMaxPlayers] = useState(getTableTemplateDto.maxPlayers || undefined);


  const handleVariant = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const variant = e.target.value;

    if (variant === "") {
      setGetTableTemplateDto({
        ...getTableTemplateDto,
        variants: undefined,
      });
      return;
    }

    setGetTableTemplateDto({
      ...getTableTemplateDto,
      variants: [variant as PokerVariantIds],
    });
  };

  const handleBuyinChange = (value: number | { min: number; max: number }) => {
    // Check if value is a number or an object with min and max
    if (typeof value === "number") {
    // Handle the case where value is a single number
    // You might need to adjust this logic based on your application's needs
    setBuyinRange({ min: value, max: value });
    setGetTableTemplateDto({
    ...getTableTemplateDto,
    minBuyin: value,
    maxBuyin: value,
    });
    } else {
    // Handle the case where value is an object with min and max
    setBuyinRange(value);
    setGetTableTemplateDto({
    ...getTableTemplateDto,
    minBuyin: value.min,
    maxBuyin: value.max,
    });
    }
    };
    
    const handleBlindChange = (value: number | { min: number; max: number }) => {
    // Check if value is a number or an object with min and max
    if (typeof value === "number") {
    // Handle the case where value is a single number
    // You might need to adjust this logic based on your application's needs
    setBlindRange({ min: value, max: value });
    setGetTableTemplateDto({
    ...getTableTemplateDto,
    smallBlind: value,
    bigBlind: value,
    });
    } else {
    // Handle the case where value is an object with min and max
    setBlindRange(value);
    setGetTableTemplateDto({
    ...getTableTemplateDto,
    smallBlind: value.min,
    bigBlind: value.max,
    });
    }
    };
  const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setGetTableTemplateDto({
      ...getTableTemplateDto,
      [name]: checked ? true : undefined,
    });
  };

  const handleMaxPlayersChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    setMaxPlayers(value)
    setGetTableTemplateDto({
      ...getTableTemplateDto,
      maxPlayers: value > 1 ? value : undefined,
    });
  };


  return (
    <React.Fragment>
      <Modal
        isOpen={modal_backdrop}
        toggle={() => {
          tog_backdrop();
        }}
        backdrop={"static"}
        id="staticBackdrop"
        centered
      >
        <ModalHeader className="bg-dark p-3" toggle={tog_backdrop}>
          Filter Tables
        </ModalHeader>

        <ModalBody className="p-4">
            <div className="mt-3">
                    <h6>Variants</h6>
                  <div className="my-3">
                    <select onChange={handleVariant} className="p-2">
                      <option value=""></option>
                      {Object.values(PokerVariantIds).map((variant : PokerVariantIds) => (
                        <option value={variant} key={variant}>
                          {variant}
                        </option>
                      ))}
                    </select>
                    </div>
                  </div>

              <div className="my-4">
                <h6>Stakes</h6>
                <div className="my-3">
                  <button className={"btn" + (stakes === Stakes.MICRO ? " btn-primary" : " btn-dark")} onClick={()=>{
                    setStakes(Stakes.MICRO)
                    setMinBuyIn(0)
                    setMaxBuyIn(100)
                    setBuyinRange({min: 0, max: 100})
                    setSmallBlind(0)
                    setBigBlind(0.2)
                    setBlindRange({min: 0, max: 0.2})
                    setGetTableTemplateDto({ ...getTableTemplateDto, minBuyin:0, maxBuyin:100, smallBlind:0, bigBlind:0.2})

                  }}>{Stakes.MICRO}</button>
                  <button className={"btn" + (stakes === Stakes.MEDIUM ? " btn-primary" : " btn-dark")} onClick={()=>{
                    setStakes(Stakes.MEDIUM)
                    setMinBuyIn(100)
                    setMaxBuyIn(10000)
                    setBuyinRange({min: 100, max: 10000})
                    setSmallBlind(0.2)
                    setBigBlind(10)
                    setBlindRange({min: 0.2, max: 10})  
                    setGetTableTemplateDto({ ...getTableTemplateDto, minBuyin:100, maxBuyin:1000, smallBlind:0.2, bigBlind:10})
                  }}>{Stakes.MEDIUM}</button>
                  <button className={"btn" + (stakes === Stakes.HIGH ? " btn-primary" : " btn-dark")} onClick={()=>{
                    setStakes(Stakes.HIGH)
                    setMinBuyIn(0)
                    setMaxBuyIn(999999)
                    setBuyinRange({min: 0, max: 999999})
                    setSmallBlind(10)
                    setBigBlind(100000)
                    setBlindRange({min: 10, max: 100000})
                    setGetTableTemplateDto({ ...getTableTemplateDto, minBuyin:10000, maxBuyin:999999, smallBlind:10, bigBlind:100000})
                  }}>{Stakes.HIGH}</button>
                </div>
              </div>
              <div className="my-4">
                <h6>Buy In Range</h6>
                <div className="my-3  p-3">
                  <InputRange
                    maxValue={maxBuyin}
                    minValue={minBuyin}
                    value={buyInRange}
                    onChange={handleBuyinChange}
                    
                  />
                </div>
              </div>
              <div className="mt-3 mb-4">
                <h6>Blinds Range</h6>
                <div className="my-3  p-3">
                  <InputRange
                    maxValue={bigBlind}
                    minValue={smallBlind}
                    value={blindRange}
                    onChange={handleBlindChange}

                  />
                </div>
              </div>


        </ModalBody>
        <ModalFooter className="fs-5 ">
        <div className="d-flex flex-rows gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => tog_backdrop()}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  setGetTableTemplateDto({ skip: getTableTemplateDto.skip, take: getTableTemplateDto.take })
                }
              >
                Clear
              </button>
            </div>  
        </ModalFooter>
      </Modal>
      </React.Fragment>
  );
};

export default TemplateFilter;

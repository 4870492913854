import { polygon } from "viem/chains";
import { Config } from "./config.interface";
import { baseConfig } from "./base.config";

export const productionConfig: Config = {
  ...baseConfig,
  environment: "production",
  isDevelopment: false,
  isProduction: true,
  urls: {
    appBaseUrl: "https://admin.phenompoker.com/",
    phenomApi: "https://api.phenompoker.com/",
    phenomApp: "https://app.phenompoker.com/",
  },
  chain: polygon,
  blockExplorerUrl: "https://polygonscan.com",
  usdtDecimals: 6,
  phnmDecimals: 18,

  contracts: {
    usdt: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    phnm: '0x35d4F6D876d6c14775DF8A74b8dE3A699cF458AC',
    tables: '0x37AEFbD92631Ed932f1C1ed355fAc8d2a8EC68DC',
    oldTables: '0xC10e197E783394D08310F5013F05362a64a891C4',
    revenueShareManager: '0x4A11f00a28B718c6b3360484E1f21772B1c4c4a5',
    rakeManager: '0x1760C02693C5e992cc1e6837686846B7AD84613A',
    oldRevenueShareManager: '0x205282Bfc2B165A67883897B322bee757645BbFc',
    oldRakeManager: '0x982EB8B24F331d93f49E42f7c78c108236F62dA4',
    redemptionManager: '0xD6Bb3F4576f341Bbc8DbC9d0a302205C643783a8',
    redemptionVault: '0xd251fBDF90b8FEeaEA13F5425a186F56c1F3E7E0',
    rewardsManager: '0x06842D94BD5208A44fB581189E8D2D7Da40ED781',
    safeTreasury: '0x329B3A3De340dD7C709301403990bA7eac99fB7f',
    orchestrator: '0x276d28120Bc89FDFf8E52f23B025A2cb53615e0A',
    redemption: '0x1D26bA1e70F19bd1FBdE67B38194e49f910f5f02',
    smartAccount: "0xA3f501efa06457e1d16a6ce0E9c7aAA7cCb347B7",
    poolAccount: "0x43a8cD5887011640f77FF47345F48eCFa986c31C",
    backendDeployer: "0x3814F5029f09D197e9DCFc23f01Fbe4de3503F41"
  },



};

import React, { FC, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Input, Col, Label } from "reactstrap";

interface InfoFieldProps {
  name: string;
  value?: string | any;
  className?: string;
  lg?: number;
  label?: boolean;
  link?:string;
}

const CopyField: FC<InfoFieldProps> = ({ name, value, className, lg = 5, link=undefined, label=true }) => {

    const [copied, setCopied] = React.useState("");
    function handleCopy(value: string) {
        navigator.clipboard.writeText(value)
          .then(() => {
            setCopied(" text-info");
            setTimeout(() => {
              setCopied("");
            },2000)
          })
          .catch(err => {
            console.error('Failed to copy: ', err); // Handle errors (optional)
          });
    };
    return (
    <Col lg={lg} sm={12} className={className}>
      <div>
        { label &&
          <Label htmlFor={name} className={"form-label " + copied}>
            {name} {copied && <span className="text-info">Copied!</span>}
          </Label>
        }
        <div className="input-group">
        <input
          type="text"
          className={"form-control " + copied}
          id={name}
          value={value}
          disabled
        />
            <span className="input-group-text" onClick={() => handleCopy(String(value))}>
                <i className="ri-file-copy-2-line"></i>
            </span>
            { link &&
            <span className="input-group-text" onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}>
              <i className="ri-links-fill"></i>
            </span>}

        </div>
      </div>
    </Col>
  );
};

export default CopyField;

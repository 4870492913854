import React from 'react';
import { Spinner } from 'reactstrap';

interface CustomSpinnerProps {
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  size?: string;
}

const CustomSpinner: React.FC<CustomSpinnerProps> = ({ color = 'primary', size }) => {
  return (
    <div className="d-flex justify-content-center">
      <Spinner className="custom-spinner" color={color} style={{ width: size, height: size }}/>

    </div>
  );
};

export default CustomSpinner;

import { useState } from "react";

const Address: React.FC<{
  contractAddress: string;
  displayShortAddress?: boolean;
}> = ({ contractAddress, displayShortAddress = false }) => {
  const [addressCopied, setAddressCopied] = useState(false);

  const displayAddress =
    contractAddress?.slice(0, 6) + "..." + contractAddress?.slice(-4);
  const handleCopyAddress = () => {
    navigator.clipboard.writeText(contractAddress);
    setAddressCopied(true);
    setTimeout(() => {
      setAddressCopied(false);
    }, 800);
  };
  return (
    <p>
      {displayShortAddress ? displayAddress : contractAddress}{" "}
      {addressCopied ? (
        <i className="ri-checkbox-circle-line ri-lg"></i>
      ) : (
        <i
          className="ri-file-copy-line ri-lg cursor-pointer"
          onClick={handleCopyAddress}
        ></i>
      )}
    </p>
  );
};
export default Address;

import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Card, CardBody, Container, Row, Col, Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { fetchAvailableDealsRest, fetchUserDealsRest, postDealsGrantRest, postDealsRevokeRest } from "@/services/internal-tokens-deals/internal-tokens-deals-service";
import { AvailableInternalTokenDeals, InternalTokensDealsDto } from "@/services/internal-tokens-deals/internal-tokens-deals-types";

const InternalTokenDeals = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [availableDeals, setAvailableDeals] = useState<AvailableInternalTokenDeals[]>([]);
  const [userInternalTokenDeals, setUserInternalTokenDeals] = useState<InternalTokensDealsDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const validationSchema = Yup.object().shape({
    walletAddress: Yup.string().matches(/^0x[a-fA-F0-9]{40}$/, "Invalid Ethereum address").required("Wallet address is required"),
    dealId: Yup.string().required("Please select a deal"),
    originalAmount: Yup.number().typeError("Amount must be a number").positive("Amount must be a positive number").required("Amount is required"),
    startDate: Yup.date().required("Start date is required"),
  });

  type ValidationSchema = Yup.InferType<typeof validationSchema>;

  const { control, handleSubmit, reset, watch, setValue, formState: { errors } } = useForm<ValidationSchema>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      walletAddress: "",
      dealId: "",
      originalAmount: 0,
      startDate: new Date(),
    },
  });

  const watchedWalletAddress = watch("walletAddress");

  useEffect(() => {
    const fetchDeals = async () => {
      if (watchedWalletAddress && watchedWalletAddress.match(/^0x[a-fA-F0-9]{40}$/)) {
        setIsLoading(true);
        setError("");
        try {
          const [availableDealsData, userDealsData] = await Promise.all([
            fetchAvailableDealsRest(),
            fetchUserDealsRest(watchedWalletAddress)
          ]);
          setAvailableDeals(availableDealsData);
          setUserInternalTokenDeals(userDealsData);
          setWalletAddress(watchedWalletAddress);
        } catch (err) {
          setError(`Failed to fetch deals. Please try again.`);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchDeals();
  }, [watchedWalletAddress]);

  useEffect(() => {
    fetchAvailableDealsRest()
      .then(availableDealsData => setAvailableDeals(availableDealsData))
      .catch(error => { setError(`Failed to fetch deals. Please try again.`); });
  }, []);

  const onSubmit = async (data: ValidationSchema) => {
    setIsLoading(true);
    setError("");
    try {
      const selectedDeal = availableDeals.find(deal => deal.totalTimeInSeconds.toString() === data.dealId);
      if (!selectedDeal) throw new Error("Invalid deal selected");

      const result = await postDealsGrantRest({
        walletAddress: data.walletAddress,
        originalAmount: data.originalAmount,
        totalTime: selectedDeal.totalTimeInSeconds,
        vestingPeriod: selectedDeal.vestingPeriodInSeconds,
        startTime: Math.floor(data.startDate.getTime() / 1000),
      });
      
      setUserInternalTokenDeals(result);
      reset({
        walletAddress: data.walletAddress,
        dealId: "",
        originalAmount: 0,
        startDate: new Date(),
      });
    } catch (error: any) {
      setError(`Failed to create deal. Please try again. ${error.message || error?.response?.data?.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRevoke = async (dealIndex: number) => {
    setIsLoading(true);
    setError("");
    try {
      const result = await postDealsRevokeRest({ user: walletAddress, dealIndex });
      setUserInternalTokenDeals(result);
    } catch (err) {
      setError("Failed to revoke deal. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <div>
              <div>
                <Card className="card mb-2">
                  <CardBody className="card-body">
                    <h3 className="mb-4">Internal Token Deals Management</h3>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                      <Label for="to">Wallet Address</Label>
                      <Controller
                        name="walletAddress"
                        control={control}
                        render={({ field }) => <Input {...field} placeholder="0x..." />}
                      />
                      {errors.walletAddress && <Alert color="danger">{errors.walletAddress.message}</Alert>}
                    </FormGroup>

                    {watchedWalletAddress && watchedWalletAddress.match(/^0x[a-fA-F0-9]{40}$/) && (
                      <>
                        <FormGroup>
                          <Label for="dealId">Available Deals</Label>
                          <Controller
                            name="dealId"
                            control={control}
                            render={({ field }) => (
                              <Input type="select" {...field}>
                                <option value="">Select a deal</option>
                                {availableDeals.map(deal => (
                                  <option key={deal.totalTimeInSeconds} value={deal.totalTimeInSeconds}>
                                    {deal.totalTimeInSeconds / (30 * 24 * 60 * 60)} months - Vesting: {deal.vestingPeriodInSeconds / (30 * 24 * 60 * 60)} months
                                  </option>
                                ))}
                              </Input>
                            )}
                          />
                          {errors.dealId && <Alert color="danger">{errors.dealId.message}</Alert>}
                        </FormGroup>

                        <FormGroup>
                          <Label for="amount">Amount</Label>
                          <Controller
                            name="originalAmount"
                            control={control}
                            render={({ field }) => <Input type="number" {...field} />}
                          />
                          {errors.originalAmount && <Alert color="danger">{errors.originalAmount.message}</Alert>}
                        </FormGroup>

                        <FormGroup>
                          <Label for="startDate">Start Date</Label>
                          <Controller
                            name="startDate"
                            control={control}
                            render={({ field }) => (
                              <Flatpickr
                                {...field}
                                options={{ dateFormat: "Y-m-d" }}
                                className="form-control"
                              />
                            )}
                          />
                          {errors.startDate && <Alert color="danger">{errors.startDate.message}</Alert>}
                        </FormGroup>

                        <Button color="primary" type="submit" disabled={isLoading}>
                          {isLoading ? "Creating..." : "Create Deal"}
                        </Button>
                      </>
                    )}
                  			</Form>
                    {error && <Alert color="danger" className="mt-3">{error}</Alert>}

                    {userInternalTokenDeals.length > 0 && (
                      <div className="mt-4">
                        <h4>User Deals</h4>
                        <ul className="list-group">
                          {userInternalTokenDeals.map((deal: InternalTokensDealsDto, index) => (
                          <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                            <div>
                              <strong>Amount:</strong> {deal.originalAmount} (Remaining: {deal.remainingAmount})
                              <br />
                              <strong>Period:</strong> {new Date(Number(deal.startTime) * 1000).toLocaleDateString()} - {new Date(Number(deal.endTime) * 1000).toLocaleDateString()}
                            </div>
                            {!deal.isRevoked && (
                              <Button 
                                color="danger" 
                                size="sm" 
                                onClick={() => handleRevoke(index)} 
                                disabled={isLoading}
                              >
                                {isLoading ? "..." : "Revoke"}
                              </Button>
                            )}
                          </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InternalTokenDeals;
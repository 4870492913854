import { api } from "@/services/api";
import { AvailableInternalTokenDeals, CreateInternalTokenDealsDto, InternalTokensDealsDto, RevokeInternalTokensDealsDto } from "./internal-tokens-deals-types";
// {
//     "originalAmount": "123456000000000000000000",
//     "remainingAmount": "123456000000000000000000",
//     "startTime": "1722616114",
//     "endTime": "1754152114",
//     "vestingPeriod": "15552000",
//     "lastUpdateTime": "1722616114",
//     "isRevoked": false
// },



export const fetchDealsRest = async () => {
  const response = await api.get<CreateInternalTokenDealsDto[]>(`/v1/admin/internal-tokens-deals/`);
  return response.data;
};


export const fetchAvailableDealsRest = async () => {
  const response = await api.get<AvailableInternalTokenDeals[]>(`/v1/admin/internal-tokens-deals/available`);
  return response.data;
};

export const fetchUserDealsRest = async (
  smartWalletAddress: string
) => {
  const response = await api.get<InternalTokensDealsDto[]>(`/v1/admin/internal-tokens-deals/${smartWalletAddress}/info`);
  return response.data;
};

export const postDealsGrantRest = async (
    params: CreateInternalTokenDealsDto
  ) => {
    const response = await api.post<InternalTokensDealsDto[]>(`/v1/admin/internal-tokens-deals/grant`,{
        params
    });
    return response.data;
  };


export const postDealsRevokeRest = async (
  params: RevokeInternalTokensDealsDto
) => {
  const response = await api.post<InternalTokensDealsDto[]>(`/v1/admin/internal-tokens-deals/revoke`,{
      params
  });
  return response.data;
};



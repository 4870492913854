import { PokerVariantIds, TableStatus } from "@/common/types/phenomTypes";
import { GetTableDto } from "@/services/table/table-filters";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Card, CardBody, CardHeader, Modal, ModalBody, Col, Input, Button, ModalHeader, ModalFooter } from "reactstrap";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import './tables-filter.css';
import InputRangeComponent from "./InputRangeComponent";


const enum Stakes {
  MICRO = "Micro",
  MEDIUM = "Medium",
  HIGH = "High"
}

export const TablesFilter: React.FC<{
  modal_backdrop: boolean;
  tog_backdrop: () => void;
  getTableDto: GetTableDto;
  setGetTableDto: (state: GetTableDto) => void;
  variants: PokerVariantIds[];
}> = ({
  modal_backdrop,
  tog_backdrop,
  getTableDto,
  setGetTableDto,
}) => {

  const [minBuyin, setMinBuyIn] = useState<number>(0);
  const [minStake, setMinStake] = useState<number>(0);
  const [maxBuyin, setMaxBuyIn] = useState<number>(100);
  const [maxStake, setMaxStake] = useState<number>(0.2);
  const [stakes, setStakes] = useState<Stakes>(Stakes.MICRO);
  

  const [buyInRange, setBuyinRange] = useState({
    min: getTableDto.minBuyin || minBuyin || 0 ,
    max: getTableDto.maxBuyin || maxBuyin,
  });

  const [blindRange, setBlindRange] = useState({
    min: getTableDto.minStake || minStake || 0,
    max: getTableDto.maxStake || maxStake,
  });

  const [maxPlayers, setMaxPlayers] = useState(getTableDto.maxPlayers || undefined);
  const [seatCount, setSeatCount] = useState(getTableDto.seatCount || undefined);
  const [waitlist, setWaitlist] = useState(getTableDto.waitlist || undefined);
  const [isPublic, setIsPublic] = useState<boolean|undefined>(getTableDto.isPublic || undefined);
  const [isRealMoney, setIsRealMoney] = useState<boolean|undefined>(getTableDto.isRealMoney || undefined);

  const handleVariant = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const variant = e.target.value;

    if (variant === "") {
      setGetTableDto({
        ...getTableDto,
        variants: undefined,
      });
      return;
    }

    setGetTableDto({
      ...getTableDto,
      variants: [variant as PokerVariantIds],
    });
  };

  const handleBuyinChange = (value: number | { min: number; max: number }) => {
    // Check if value is a number or an object with min and max
    if (typeof value === "number") {
    // Handle the case where value is a single number
    // You might need to adjust this logic based on your application's needs
    setBuyinRange({ min: value, max: value });
    setGetTableDto({
    ...getTableDto,
    minBuyin: value,
    maxBuyin: value,
    });
    } else {
    // Handle the case where value is an object with min and max
    setBuyinRange(value);
    setGetTableDto({
    ...getTableDto,
    minBuyin: value.min,
    maxBuyin: value.max,
    });
    }
    };
    
    const handleBlindChange = (value: number | { min: number; max: number }) => {
    // Check if value is a number or an object with min and max
    if (typeof value === "number") {
    // Handle the case where value is a single number
    // You might need to adjust this logic based on your application's needs
    setBlindRange({ min: value, max: value });
    setGetTableDto({
    ...getTableDto,
    minStake: value,
    maxStake: value,
    });
    } else {
    // Handle the case where value is an object with min and max
    setBlindRange(value);
    setGetTableDto({
    ...getTableDto,
    minStake: value.min,
    maxStake: value.max,
    });
    }
    };
  const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setGetTableDto({
      ...getTableDto,
      [name]: checked ? true : undefined,
    });
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as TableStatus;
    setGetTableDto({
      ...getTableDto,
      status: value || undefined,
    });
  };

  const handleMaxPlayersChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    setMaxPlayers(value)
    setGetTableDto({
      ...getTableDto,
      maxPlayers: value > 1 ? value : undefined,
    });
  };

  const handleSeatCountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    setSeatCount(value)
    setGetTableDto({
      ...getTableDto,
      seatCount: value >= 0 ? value : undefined,
    });
  };

  const handleWaitlistChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    setWaitlist(value)
    setGetTableDto({
      ...getTableDto,
      waitlist: value >= 0 ? value : undefined,
    });
  };


  const handleIsPublic = (e: React.ChangeEvent<HTMLSelectElement>) => {

    const value = String(e.target.value);
  
    setGetTableDto({
      ...getTableDto,
      isPublic: value === "true" ? true : undefined
    });
  };


  const handleIsRealMoney = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = String(e.target.value);

    setGetTableDto({
      ...getTableDto,
      isRealMoney: value === "true" ? true : undefined
    });
  };
  

  return (
    <React.Fragment>
      <Modal
        isOpen={modal_backdrop}
        toggle={() => {
          tog_backdrop();
        }}
        backdrop={"static"}
        id="staticBackdrop"
        centered
      >
        <ModalHeader className="bg-dark p-3" toggle={tog_backdrop}>
          Filter Tables
        </ModalHeader>

        <ModalBody className="p-4">
            <div className="mt-3">
                    <h6>Variants</h6>
                  <div className="my-3">
                    <select onChange={handleVariant} className="p-2">
                      <option value=""></option>
                      {Object.values(PokerVariantIds).map((variant) => (
                        <option value={variant} key={variant} selected={getTableDto?.variants?.includes(variant)}>
                          {variant}
                        </option>
                      ))}
                    </select>
                    </div>
                  </div>
              <div className="my-4">
                <h6>Stakes</h6>
                <div className="my-3">
                  <button className={"btn" + (stakes === Stakes.MICRO ? " btn-primary" : " btn-dark")} onClick={()=>{
                    setStakes(Stakes.MICRO)
                    setMinBuyIn(0)
                    setMaxBuyIn(100)
                    setBuyinRange({min: 0, max: 100})
                    setMinStake(0)
                    setMaxStake(0.2)
                    setBlindRange({min: 0, max: 0.2})
                  }}>{Stakes.MICRO}</button>
                  <button className={"btn" + (stakes === Stakes.MEDIUM ? " btn-primary" : " btn-dark")} onClick={()=>{
                    setStakes(Stakes.MEDIUM)
                    setMinBuyIn(100)
                    setMaxBuyIn(10000)
                    setBuyinRange({min: 100, max: 10000})
                    setMinStake(0.2)
                    setMaxStake(10)
                    setBlindRange({min: 0.2, max: 10})  
                  }}>{Stakes.MEDIUM}</button>
                  <button className={"btn" + (stakes === Stakes.HIGH ? " btn-primary" : " btn-dark")} onClick={()=>{
                    setStakes(Stakes.HIGH)
                    setMinBuyIn(10000)
                    setMaxBuyIn(200000)
                    setBuyinRange({min: 10000, max: 200000})
                    setMinStake(10)
                    setMaxStake(100000)
                    setBlindRange({min: 10, max: 100000})
                  }}>{Stakes.HIGH}</button>
                </div>
              </div>
              <div className="my-4">
                <h6>Buy In Range</h6>
                <div className="my-3  p-3">
                  <InputRangeComponent
                    maxValue={maxBuyin}
                    minValue={minBuyin}
                    value={buyInRange}
                    onChange={handleBuyinChange}
                  />
                </div>
              </div>
              <div className="mt-3 mb-4">
                <h6>Stake Range</h6>
                <div className="my-3  p-3">
                  <InputRangeComponent
                    maxValue={maxStake}
                    minValue={minStake}
                    value={blindRange}
                    onChange={handleBlindChange}

                  />
                </div>
              </div>

              <div className="mt-3 d-flex flex-rows">
                <Col md={6} sm={12}>
                  <h6>Table Status</h6>
                  <select onChange={handleStatusChange} value={getTableDto.status || ""} 
                  className="p-2 min100">
                    <option value=""></option>
                    {Object.values(TableStatus).map((status) => (
                      <option value={status} key={status}
                      selected={getTableDto?.status === status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={6} sm={12}>
                  <h6>Table Size</h6>
                  <select onChange={handleMaxPlayersChange} value={getTableDto.maxPlayers || ""} 
                  className="p-2 min100">
                    <option value=""></option>
                    {Array.from({ length: 8 }, (_, i) => i + 2).map((number) => (
                      <option value={number} key={number}
                      
                      selected={getTableDto?.maxPlayers === number}>
                        {number}
                      </option>
                    ))}
                  </select>
                </Col>
              </div>
              <div className="mt-3 d-flex flex-rows">
                <Col md={6} sm={12}>
                  <h6>Seat Count</h6>
                  <select onChange={handleSeatCountChange} value={getTableDto.seatCount || ""} 
                  className="p-2 min100">
                    <option value=""></option>
                      {Array.from({ length: 10 }, (_, i) => i).map((number) => (
                        <option value={number} key={number}
                        selected={getTableDto?.seatCount === number}>
                          {number}
                        </option>
                      ))}
                  </select>
                </Col>
                <Col md={6} sm={12}>
                  <h6>Waitlst</h6>
                  <select onChange={handleWaitlistChange} value={getTableDto.waitlist || ""} 
                  className="p-2 min100">
                    <option value=""></option>
                    {Array.from({ length: 9 }, (_, i) => i + 1).map((number) => (
                      <option value={number} key={number}

                      selected={getTableDto?.waitlist === number}>
                        {number}
                      </option>
                    ))}
                  </select>
                </Col>
              </div>
              <Col lg={10} className="mt-5">
                <div className="d-flex flex-row mt-4 justify-content-end gap-3">
                  <div className="form-check form-switch form-switch-right form-switch-md ms-2 d-flex align-bottom">
                    <div className="d-flex">
                      <span>is Public</span>
                      <Input
                        className="ms-4 form-check-input code-switcher"
                        type="checkbox"
                        checked={getTableDto.isPublic === true}
                        name={"isPublic"}
                        onChange={handleChangeCheckbox}
                      />
                    </div>
                  </div>
                  <div className="form-check form-switch form-switch-right form-switch-md ms-2 d-flex align-bottom">
                    <div className="d-flex">
                      <span>Is Real Money</span>
                      <Input
                        className="ms-4 form-check-input code-switcher"
                        type="checkbox"
                        checked={getTableDto.isRealMoney === true}
                        name={"isRealMoney"}
                        onChange={handleChangeCheckbox}
                      />
                    </div>
                  </div>
                </div>
              </Col>

        </ModalBody>
        <ModalFooter className="fs-5 ">
        <div className="d-flex flex-rows gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-dark"
                onClick={() => tog_backdrop()}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  setGetTableDto({ skip: getTableDto.skip, take: getTableDto.take })
                }
              >
                Clear
              </button>
            </div>  
        </ModalFooter>
      </Modal>
      </React.Fragment>
  );
};

export default TablesFilter;

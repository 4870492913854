import { updatePhnmTokenPrice } from "@/services/revenue-share/revenue-share-service";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { WizardTransition } from "../token-flow-orchestrator";
import { useState } from "react";
import { ContractInfo } from "@/components/ContractInfo";
import config from "@/config";

const UpdatePHNMPrice: React.FC<WizardTransition> = ({
  currentState,
  setState,
  setToast,
  enabled,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const updatePHNMPrice = () => {
    setSubmitting(true);
    updatePhnmTokenPrice()
      .then(() => {
        setState(4);
      })
      .catch((error) => {
        setToast(`Token Price Update failed ${error}`, "warning");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Card>
      <CardHeader>
        <h2>
          <span className="btn btn-light mx-2">3</span>Update PHNM Price
        </h2>
      </CardHeader>
      <CardBody>
        <ContractInfo
          className="col-sm-12 col-lg-3"
          style={{ borderWidth: 1, minWidth: "fit-content" }}
          contractAddress={config.contracts.redemptionVault}
          contractName="RedemptionVault"
          infoConfig={{
            showUsdtBalance: true,
            showPhnmBalance: false,
            variablesToShow: ["tokenPrice"],
          }}
        />
        <div>
          <p>
            Update the PHNM token price in the Redemption contract. This update
            will transition the orchestrator to the next state once it is
            successful.
          </p>
        </div>
      </CardBody>
      <CardFooter>
        <div className="d-flex justify-content-end">
          <button
            disabled={submitting || !enabled}
            className="btn btn-primary"
            onClick={() => {
              if (!enabled) {
                setToast("Please follow Orchestrator steps", "warning");
                return;
              }
              updatePHNMPrice();
            }}
          >
            {submitting ? "Updating..." : "Update Phenom Price"}
          </button>
        </div>
      </CardFooter>
    </Card>
  );
};

export default UpdatePHNMPrice;

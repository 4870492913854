import React from "react";
import {
    Container,
    Row,
    Spinner,
  } from "reactstrap";

const LoadingPage = () => {
  return (
    <React.Fragment>
        <div className="page-content d-flex justify-content-center align-middle" style={{ height: '100vh', width: '100%' }}>
            <Container fluid className="container-fluid">
            <Row className="gy-2 mb-2 d-flex justify-content-center">
                <Spinner>Loading...</Spinner>

            </Row>
            </Container>
        </div>
    </React.Fragment>
  );
}

export default LoadingPage
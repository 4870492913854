import { GetTransactionDto } from "@/services/transactions/transactions-types";
import React, { useCallback, useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import DebouncedInput from "@/components/DebounceInput";

import { TransactionType } from '@/services/transactions/transactions-types';

import "react-input-range/lib/css/index.css";

export const TransactionsFilter: React.FC<{
  modal_backdrop: boolean;
  tog_backdrop: () => void;
  getTransactionDto: GetTransactionDto;
  setGetTransactionDto: React.Dispatch<React.SetStateAction<GetTransactionDto>>;
}> = ({
  modal_backdrop,
  tog_backdrop,
  getTransactionDto,
  setGetTransactionDto
}) => {
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);
  const [localUserAlias, setLocalUserAlias] = useState<string>(getTransactionDto.userAlias || "");
  const [localTableName, setLocalTableName] = useState<string>(getTransactionDto.tableName || "");
  const [localTransactionType, setLocalTransactionType] = useState<string>("");

  useEffect(() => {
    setLocalUserAlias(getTransactionDto.userAlias || "");
    setLocalTableName(getTransactionDto.tableName || "");
  }, [getTransactionDto]);

  const handleUserAlias = useCallback((value: string | number) => {
    setIsDebouncing(true);
    const stringValue = value.toString();
    setLocalUserAlias(stringValue);
    setGetTransactionDto((prev: GetTransactionDto) => ({
      ...prev,
      userAlias: stringValue,
    }));
    setIsDebouncing(false);
  }, [setGetTransactionDto]);

  const handleTransactionType = useCallback((e: any) => {
    const newTransactionType = e.target.value;
    setLocalTransactionType(newTransactionType);
    setGetTransactionDto((prev: GetTransactionDto) => ({
      ...prev,
      type: newTransactionType,
    }));
  }, [setGetTransactionDto]);

  const handleTableName = useCallback((value: string | number) => {
    setIsDebouncing(true);
    const stringValue = value.toString();
    setLocalTableName(stringValue);
    setGetTransactionDto((prev: GetTransactionDto) => ({
      ...prev,
      tableName: stringValue,
    }));
    setIsDebouncing(false);
  }, [setGetTransactionDto]);

  const handleClear = () => {
    setGetTransactionDto((prev: GetTransactionDto) => ({ 
      ...prev,
      userAlias: "",
      tableName: ""
    }));
    setLocalUserAlias("");
    setLocalTableName("");
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modal_backdrop}
        toggle={tog_backdrop}
        backdrop={"static"}
        id="staticBackdrop"
        centered
      >
        <ModalHeader className="bg-dark p-3" toggle={tog_backdrop}>
          Filter Transactions
        </ModalHeader>

        <ModalBody className="p-4">
          <div className="mt-3">
            <h6>User Alias:</h6>
            <div className="my-3">
              <DebouncedInput
                className="form-control"
                placeholder="Search by user alias..."
                onChange={handleUserAlias}
                value={localUserAlias}
                isLoading={isDebouncing}
              />
            </div>
          </div>

          <div className="mt-3">
            <h6>Table Name:</h6>
            <div className="my-3">
              <DebouncedInput
                className="form-control"
                placeholder="Search by table name..."
                onChange={handleTableName}
                value={localTableName}
                isLoading={isDebouncing}
              />
            </div>
          </div>

          <div className="mt-3">
            <h6>Transaction Type:</h6>
            <div className="my-3">
              <select value={localTransactionType} className="form-control" onChange={handleTransactionType}>
                <option value=""></option>
                {Object.keys(TransactionType).map(type => (
                  <option>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </ModalBody>

        <ModalFooter className="fs-5 ">
          <div className="d-flex flex-rows gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-dark"
              onClick={tog_backdrop}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleClear}
            >
              Clear
            </button>
          </div>  
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default TransactionsFilter;
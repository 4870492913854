import React, { useEffect, useState, useMemo } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
} from "reactstrap";

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  ColumnFilter,
  OnChangeFn,
  ColumnFiltersState,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table";

import { getGamesRest } from "@/services/game-params/parameters-services";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "@/common/showToast";

import { GameDto } from "@/services/game-params/parameters-types";
import { useNavigate } from "react-router-dom";
import routes from "@/routes/routes-enums";

const MixedGame: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<GameDto[]>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([]);
  const [sortingState, setSortingState] = useState<SortingState>([]);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await getGamesRest();

        // Filter games with more than 2 variants
        const filteredGames = response.filter(game => game.variants.length > 1);
        setData(filteredGames);
      } catch (error) {
        showToast(`Error when fetching server data: ${(error as Error).message}`, "warning");
      }
    };

    fetchGames();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Mixed Game Name",
        type: "string",
      },
      {
        accessorKey: "variants",
        header: "Variants",
        type: "string",
        cell: (info: { getValue: () => string[] }) => info.getValue().join(", "),
      },
    ],
    []
  );

  const tableInstance = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      sorting: sortingState,
    },
    onColumnFiltersChange: setColumnFilters as OnChangeFn<ColumnFiltersState>,
    onSortingChange: setSortingState as OnChangeFn<SortingState>,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  const goToTableCreate = () => {
    navigate(`${routes.PARAMETERS_MIXED_GAMES_STRUCTURE_CREATE}`);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <div>
              <div>
                <div className="d-flex flex-row mb-3 gap-3">
                  <div className="col-auto me-auto d-flex flex-row gap-4">
                    <button
                      type="button"
                      className="btn btn-soft-primary text-white"
                      onClick={goToTableCreate}
                    >
                      <i className="ri-add-circle-line align-middle me-1"></i>{" "}
                      Create Mixed Game
                    </button>
                  </div>
                </div>
                <Card className="card mb-2">
                  <CardBody className="card-body">
                    <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                      <thead>
                        {tableInstance.getHeaderGroups().map((headerGroup) => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <th key={header.id} className="cursor-pointer">
                                {header.isPlaceholder ? null : (
                                  <>
                                    <span
                                      onClick={() =>
                                        header.column.toggleSorting()
                                      }
                                    >
                                      {
                                        columns.filter(
                                          (column) =>
                                            column.accessorKey === header.id
                                        )[0]?.header
                                      }
                                      {header.column.getIsSorted() ? (
                                        header.column.getIsSorted() === "desc" ? (
                                          <i className=" ri-arrow-down-s-line" />
                                        ) : (
                                          <i className=" ri-arrow-up-s-line" />
                                        )
                                      ) : (
                                        <i className="ms-1 ri-arrow-up-down-line" />
                                      )}
                                    </span>
                                  </>
                                )}
                              </th>
                            ))}
                            <th>Actions</th>
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {tableInstance.getRowModel().rows.map((row) => (
                          <tr key={row.id}>
                            <td>{row.original.name}</td>
                                <td>
                                  <ul>
                                    {row.original.variants.map(variant => (
                                      <li key={variant}>{variant.replace(/([a-z])([A-Z])/g, '$1 $2')}</li>
                                    ))}
                                  </ul>
                                </td>
                            <td>
                              <button
                                onClick={() => navigate(`${routes.PARAMETERS_MIXED_GAMES_STRUCTURE_UPDATE}/${row.original.id}`)}
                                className="btn btn-primary"
                              >
                                <i className="ri-edit-2-line"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default MixedGame;
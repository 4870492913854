const LoadingSpinner = ({
  color = "gray",
  size = "1",
}: {
  color?: string;
  size?: string;
}) => {
  return (
    <div className="flex justify-center items-center h-full">
      <div
        className={`animate-spin rounded-full h-${size} w-${size} border-t-2 border-b-2 border-${color}-900`}
      />
    </div>
  );
};

export default LoadingSpinner;

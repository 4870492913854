import React, { useCallback, useState } from "react";
import "react-input-range/lib/css/index.css";
import { GetUserDto } from "@/services/users/user-filters";
import { UserRoles } from "@/services/users/user-types";
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Input } from 'reactstrap';
import DebouncedInput from "@/components/DebounceInput";
import Flatpickr from 'react-flatpickr';


export const UsersFilter: React.FC<{
  modal_backdrop: boolean;
  tog_backdrop: () => void;
  getUsereDto: GetUserDto;
  setGetUserDto: (state: GetUserDto) => void;}> = ({
  modal_backdrop,
  tog_backdrop,
  getUsereDto,
  setGetUserDto,
}) => {

  const [email, setEmail] = useState<string>("");
  const [alias, setAlias] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [isAgent, setIsAgent] = useState<boolean>(false);
  const [isAmbassador, setIsAmbassador] = useState<boolean>(false);
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);

  const handleRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRole = e.target.value;

    let role : {isAgent?: boolean, isAmbassador?: boolean;} = {isAgent: undefined, isAmbassador: undefined}

    if (selectedRole === UserRoles.AGENT) {
      role = {isAgent: true, isAmbassador: false};
    }

    if (selectedRole === UserRoles.AMBASSADOR) {
      role = {isAgent: false, isAmbassador: true};
    }

    if (selectedRole === UserRoles.NO_ROLE) {
      role = {isAgent: false, isAmbassador: false};
    }

    setGetUserDto({
      ...getUsereDto,
      ...role
    });
  };

  const handleAlias = useCallback((value: string | number) => {
    setIsDebouncing(true);
    setGetUserDto({
      ...getUsereDto,
      alias: value.toString(),
    });
    setIsDebouncing(false);
  }, []);

  const handleReferralCode = useCallback((value: string | number) => {
    setIsDebouncing(true);
    setGetUserDto({
      ...getUsereDto,
      referralCode: value.toString(),
    });
    setIsDebouncing(false);
  }, []);


  const handleSmartWallet = useCallback((value: string | number) => {
    setIsDebouncing(true);
    setGetUserDto({
      ...getUsereDto,
      smartWalletAddress: value.toString(),
    });
    setIsDebouncing(false);
  }, []);

  const handleMainWallet = useCallback((value: string | number) => {
    setIsDebouncing(true);
    setGetUserDto({
      ...getUsereDto,
      mainWalletAddress: value.toString(),
    });
    setIsDebouncing(false);
  }, []);

  const handleCreatedAt = useCallback((dates: Date[]) => {
    if (dates.length > 0) {
      // Assuming you want to use the first date for filtering
      // Adjust according to your needs, e.g., using both start and end dates
      setGetUserDto({
        ...getUsereDto,
        lastLogin: dates[0].toISOString().split("T")[0]
      });
    }
  }, [getUsereDto, setGetUserDto]);

  return (
    <React.Fragment>
      <Modal
        isOpen={modal_backdrop}
        toggle={tog_backdrop}
        backdrop={"static"}
        id="staticBackdrop"
        centered
      >
        <ModalHeader className="bg-dark p-3" toggle={tog_backdrop}>
          Filter Users
        </ModalHeader>
        <ModalBody className="p-4">
          <div className="mt-3">
            <h6>Roles</h6>
            <div className="my-3">
              <select onChange={handleRole} className="p-2">
                <option value=""></option>
                {Object.values(UserRoles).map((role) => (
                  <option value={role} key={role}>
                    {role}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-3">
            <h6>Alias:</h6>
            <div className="my-3">
              <DebouncedInput
              className="form-control"
              placeholder="Search by user alias..."
              onChange={handleAlias}
              value={getUsereDto?.alias || ""}
              isLoading={isDebouncing}
            />
            </div>
          </div>
          <div className="mt-3">
            <h6>Referral Code:</h6>
            <div className="my-3">
              <DebouncedInput
              className="form-control"
              placeholder="Search by referral code..."
              onChange={handleReferralCode}
              value={getUsereDto?.referralCode || ""}
              isLoading={isDebouncing}
            />
            </div>
          </div>
          {/* <div className="mt-3">
          <h6>Last since:</h6>
            <div className="my-3">
            <Flatpickr
              options={{
                mode: "single",
                dateFormat: "Y-m-d",
              }}
              value={getUsereDto?.lastLogin}
              onChange={handleCreatedAt}
            />
            </div>
          </div> */}

          <div className="mt-3">
            <h6>Smart Wallet</h6>
            <div className="my-3">
              <DebouncedInput
              className="form-control"
              placeholder="Search by smart wallet..."
              onChange={handleSmartWallet}
              value={getUsereDto?.smartWalletAddress || ""}
              isLoading={isDebouncing}
            />
            </div>
          </div>
          <div className="mt-3">
            <h6>Main Wallet</h6>
            <div className="my-3">
              <DebouncedInput
              className="form-control"
              placeholder="Search by main wallet..."
              onChange={handleMainWallet}
              value={getUsereDto?.mainWalletAddress || ""}
              isLoading={isDebouncing}
            />
            </div>
          </div>
          
        </ModalBody>
        <ModalFooter className="fs-5">
          <div className="d-flex flex-rows gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-dark"
              onClick={tog_backdrop}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() =>
                setGetUserDto({ skip: getUsereDto.skip, take: getUsereDto.take })
              }
            >
              Clear
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default UsersFilter;


import React from "react";
import { ListGroup, ListGroupItem, Button } from "reactstrap";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import { PokerVariantIds } from "@/common/types/phenomTypes";

interface SortEndParams {
  oldIndex: number;
  newIndex: number;
}

interface VariantItem {
  id: string;
  value: PokerVariantIds;
}

interface SortableItemProps {
  item: VariantItem;
  onRemove: (id: string) => void;
}

const SortableItem = SortableElement<SortableItemProps>(({ item, onRemove }: SortableItemProps) => (
  <ListGroupItem className="d-flex justify-content-between align-items-center">
    {item.value}
    <Button color="danger" size="sm" onClick={() => onRemove(item.id)}>
      Remove
    </Button>
  </ListGroupItem>
));

interface SortableListProps {
  items: VariantItem[];
  onRemove: (id: string) => void;
}

const SortableList = SortableContainer<SortableListProps>(({ items, onRemove }: SortableListProps) => (
  <ListGroup className="sortable-list">
    {items.map((item, index) => (
      <SortableItem key={item.id} index={index} item={item} onRemove={onRemove} />
    ))}
  </ListGroup>
));

interface MultiSelectWithOrderProps {
  variants: PokerVariantIds[];
  setVariants: (variants: PokerVariantIds[]) => void;
}

const MultiSelectWithOrder: React.FC<MultiSelectWithOrderProps> = ({ variants, setVariants }) => {
  // Convert variants to VariantItems with unique ids
  const variantItems: VariantItem[] = variants.map((variant, index) => ({
    id: `${variant}-${index}`,
    value: variant
  }));

  const onSortEnd = ({ oldIndex, newIndex }: SortEndParams) => {
    const newItems = arrayMove(variantItems, oldIndex, newIndex);
    setVariants(newItems.map(item => item.value));
  };

  const onRemove = (idToRemove: string) => {
    const newItems = variantItems.filter((item) => item.id !== idToRemove);
    setVariants(newItems.map(item => item.value));
  };

  return (
    <div className="multi-select-container">
      <SortableList items={variantItems} onSortEnd={onSortEnd} onRemove={onRemove} axis="y" />
    </div>
  );
};

export default MultiSelectWithOrder;
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
} from "reactstrap";

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  ColumnFilter,
  OnChangeFn,
  ColumnFiltersState,
  getSortedRowModel,
  SortingState,
  ColumnDef,
} from "@tanstack/react-table";

import { blindStructuresRest } from "@/services/game-params/parameters-services";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "@/common/showToast";

import { BlindStructureDto } from "@/services/game-params/parameters-types";
import { useNavigate } from "react-router-dom";
import routes from "@/routes/routes-enums";

const BlindStructureList: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<BlindStructureDto[]>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([]);
  const [sortingState, setSortingState] = useState<SortingState>([]);

  useEffect(() => {
    const fetchBlindStructures = async () => {
      try {
        const response = await blindStructuresRest();
        setData(response);
      } catch (error) {
        showToast(`Error when fetching blind structures: ${(error as Error).message}`, "warning");
      }
    };

    fetchBlindStructures();
  }, []);

  const columns: ColumnDef<BlindStructureDto>[] = React.useMemo(
    () => [
      {
        accessorKey: "bettingStructure",
        header: "Betting Structure",
        cell: (info) => (info.getValue() === null || info.getValue() === "") ? "No Limit " :  `LIMIT`,
      },
      {
        accessorKey: "bettingStructure",
        header: "small Bet",
        cell: (info) => info.getValue() ? `$${String(info.getValue()).split('/')[0]}` : "-",
      },
      {
        accessorKey: "bettingStructure",
        header: "Big Bet",
        cell: (info) => info.getValue() ? `$${String(info.getValue()).split('/')[1]}` : "-",
      },
      {
        accessorKey: "blinds",
        header: "Small Blind",
        cell: (info) => info.getValue() ? `$${String(info.getValue()).split('/')[0]}` : "-",
      },
      {
        accessorKey: "blinds",
        header: "Big Blind",
        cell: (info) => info.getValue() ? `$${String(info.getValue()).split('/')[1]}` : "-",
      },
      {
        accessorKey: "rakePercent",
        header: "Rake %",
        cell: (info) => `${(info.getValue<number>() * 100).toFixed(2)}%`,
      },
      {
        accessorKey: "rakeCap",
        header: "Rake Cap",
        cell: (info) => `$${info.getValue()}`,
      },
      {
        accessorKey: "rakeCapHU",
        header: "Rake Cap HU",
        cell: (info) => `$${info.getValue()}`,
      },
    ],
    []
  );

  const tableInstance = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      sorting: sortingState,
    },
    onColumnFiltersChange: setColumnFilters as OnChangeFn<ColumnFiltersState>,
    onSortingChange: setSortingState as OnChangeFn<SortingState>,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  const goToBlindStructureCreate = () => {
    navigate(`${routes.PARAMETERS_RAKE_STRUCTURE_CREATE}`);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <div>
              <div>
              <div className="d-flex flex-row mb-3 gap-3">
                <div className="col-auto me-auto d-flex flex-row gap-4">
                    <button
                      type="button"
                      className="btn btn-soft-primary text-white"
                      onClick={goToBlindStructureCreate}
                    >
                      <i className="ri-add-circle-line align-middle me-1"></i>{" "}
                      Create Blind Structure
                    </button>
                  </div>
                </div>
                <Card className="card mb-2">
                  <CardBody className="card-body">
                    <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                      <thead>
                        {tableInstance.getHeaderGroups().map((headerGroup) => (
                          <tr key={headerGroup.id}>
                            <th>Structure Identifier</th>
                            {headerGroup.headers.map((header) => (
                              <th key={header.id} className="cursor-pointer">
                                {header.isPlaceholder ? null : (
                                  <>
                                    <span
                                      onClick={() =>
                                        header.column.toggleSorting()
                                      }
                                    >
                                      {header.column.columnDef.header as string}
                                      {header.column.getIsSorted() ? (
                                        header.column.getIsSorted() === "desc" ? (
                                          <i className=" ri-arrow-down-s-line" />
                                        ) : (
                                          <i className=" ri-arrow-up-s-line" />
                                        )
                                      ) : (
                                        <i className="ms-1 ri-arrow-up-down-line" />
                                      )}
                                    </span>
                                  </>
                                )}
                              </th>
                            ))}
                            <th>Actions</th>
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {tableInstance.getRowModel().rows.map((row) => (
                          <tr key={row.id}>
                            <td>{row.original.bettingStructure ? row.original.bettingStructure : row.original.blinds} </td>
                            {row.getVisibleCells().map((cell) => (
                              <td key={cell.id}>
                                {cell.column.columnDef.cell && typeof cell.column.columnDef.cell === 'function'
                                  ? cell.column.columnDef.cell(cell.getContext())
                                  : cell.renderValue()}
                              </td>
                            ))}
                            <td>
                              <button
                                onClick={() => navigate(`${routes.PARAMETERS_RAKE_STRUCTURE_UPDATE}/${row.original.id}`)}
                                className="btn btn-primary"
                              >
                                <i className="ri-edit-2-line"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default BlindStructureList;
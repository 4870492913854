import React from 'react';
import { Container, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RakeSharePayoutComponents from './rake-share-payouts-component';

const RakeSharePayout: React.FC = () => {

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <div>
              <h1 className="text-2xl font-bold mb-4">Rake Share Pending Payouts</h1>

              <RakeSharePayoutComponents />

            </div>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default RakeSharePayout;
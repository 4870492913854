import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller, FieldErrors } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Button, Card, CardBody, Col, Container, Row, Spinner
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "@/common/showToast";
import { createTableRest } from "@/services/table/table-service";
import { getGamesRest, blindStructuresRest } from "@/services/game-params/parameters-services";
import { useQuery } from "@tanstack/react-query";
import { PokerVariantIds, TableType } from "@/common/types/phenomTypes";
import { maxPlayersOptions } from "@/common/forms/options/MaxPlayerOptions";
import { useNavigate } from "react-router-dom";
import routes from "@/routes/routes-enums";
import FormFields from "@/common/forms/FormFields";
import FormField from "../template/form-field";
import { TemplateDto } from "@/services/template/templateTypes";
import { timeOutOptions } from "@/common/forms/options/TimeOutOptions";
import { fetchAllTemplatesRest } from "@/services/template/templateRest";
import TableGameParam from "./table-game-params";
import { GameParamDto, BlindStructureDto } from "@/services/game-params/parameters-types";
import { MixedTableDto } from "@/services/table/table-types";

const TableCreate = () => {
  const navigate = useNavigate();

  const { data: games, error: gamesError } = useQuery({
    queryKey: ["getGames"],
    queryFn: () => getGamesRest(),
  });

  const [templates, setTemplates] = useState<TemplateDto[]>([]);
  const [gameVariantTokens, setGameVariantTokens] = useState<string[]>([]);
  const [gameParams, setGameParams] = useState<{ [key: string]: GameParamDto }>({});
  const [gameParamErrors, setGameParamErrors] = useState<{ [key: string]: string }>({});
  const [submission, setSubmission] = useState(false);
  const [blindStructures, setBlindStructures] = useState<BlindStructureDto[]>([]);
  
  
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await fetchAllTemplatesRest();
        setTemplates(response.filter((template: TemplateDto) => template.variants.length === 1));
      } catch (error) {
        showToast("Failed to fetch templates.", "warning");
      }
    };

    fetchTemplates();
  }, []);

  useEffect(() => { 
    blindStructuresRest().then((response) => {
        setBlindStructures(response);
    }).catch((error) => {
      showToast("Failed to fetch rake structures.", "warning");
    });
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Table Name is required"),
    game: Yup.string().required("Game Name is required"),
    variants: Yup.array()
      .of(Yup.mixed().oneOf(Object.values(PokerVariantIds), "Invalid variant"))
      .required("Variants are required"),
    minBuyin: Yup.number().min(0, "Min Buyin must be non-negative").required("Min Buyin is required"),
    maxBuyin: Yup.number().min(0, "Max Buyin must be non-negative")
      .required("Max Buyin is required")
      .moreThan(Yup.ref('minBuyin'), "Max Buyin should be greater than Min Buyin"),
    maxPlayers: Yup.number().min(2, "Max Players must be at least 2").max(9, "Max Players must be at most 9").required("Max Players is required"),
    timeoutInSeconds: Yup.number().required("Time to act is required"),
    isRealMoney: Yup.boolean(),
    isPublic: Yup.boolean(),
    templateId: Yup.number().notRequired(),
  });

  type ValidationSchema = Yup.InferType<typeof validationSchema>;

  const { handleSubmit, setValue, control, formState: { errors }, reset, watch } = useForm<ValidationSchema>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      game: "",
      variants: [],
      minBuyin: 0,
      maxBuyin: 0,
      maxPlayers: 6,
      timeoutInSeconds: 30,
      isRealMoney: false,
      isPublic: false,
      templateId: undefined,
    },
  });

  const handleGameVariant = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "" || !games) return;

    const gameId = e.target.value;
    const gameVariant = games.find((game) => game.id === gameId);

    if (!gameVariant) return;
    
    const variants = gameVariant.variants as PokerVariantIds[];
    const tokens = variants.map((variant, index) => `${gameId}+${variant}#${index}`);

    setGameVariantTokens(tokens);
    setValue("variants", variants);
    setValue("game", gameVariant.name);
    setGameParams({});
    setGameParamErrors({});
  }

  const handleValidGameParam = (token: string, config: GameParamDto) => {
    setGameParams(prev => ({
      ...prev,
      [token]: config
    }));
    setGameParamErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[token];
      return newErrors;
    });
  }

  const handleInvalidGameParam = (token: string) => {
    const [gameId, variantInfo] = token.split('+');
    const [variant, index] = variantInfo.split('#');
    //showToast(`Invalid game configuration on variant #${parseInt(index) + 1}, ${variant}`, "warning");
  }

  function parseToFloatToZero(initialValue: any): number | undefined {
    if (initialValue === undefined) return undefined;
    if (initialValue === "0") return 0;
    const parsedValue = parseFloat(String(initialValue));

    return isNaN(parsedValue) ? 0 : parsedValue;
  }

  function parseToIntOrDefault(initialValue: any, defaultValue: number | undefined): number | undefined {
    if (initialValue === undefined) return undefined;
    if (initialValue === "0") return 0;
    const parsedValue = parseFloat(String(initialValue));

    const fault = parseFloat(String(defaultValue));

    return isNaN(parsedValue) ? isNaN(fault) ? fault : undefined : parsedValue;
  }

  const tableGameParamRefs = useRef<{ [key: string]: React.RefObject<{ validate: () => Promise<boolean> }> }>({});

  useEffect(() => {
    // Initialize refs for each game variant token
    gameVariantTokens.forEach(token => {
      if (!tableGameParamRefs.current[token]) {
        tableGameParamRefs.current[token] = React.createRef();
      }
    });
  }, [gameVariantTokens]);

  const validateGameParams = async () => {
    const newErrors: { [key: string]: string } = {};
    let isValid = true;

    for (const token of gameVariantTokens) {
      const ref = tableGameParamRefs.current[token];
      if (ref && ref.current) {
        const isParamValid = await ref.current.validate();
        if (!isParamValid) {
          const [gameId, variantInfo] = token.split('+');
          const [variant, index] = variantInfo.split('#');
          newErrors[token] = `Invalid configuration for ${variant} (Variant #${parseInt(index) + 1})`;
          isValid = false;
        }
      } else {
        newErrors[token] = `Game configuration is required for game ${token}`;
        isValid = false;
      }
    }

    setGameParamErrors(newErrors);

    if (!isValid) {
      const errorMessages = Object.values(newErrors).join(", ");
      showToast(`Please correct the following errors: ${errorMessages}`, "warning");
    }

    return isValid;
  };


  const onSubmit = async (formData: ValidationSchema) => {

    const isGameParamsValid = await validateGameParams();
    if (!isGameParamsValid) {
      return;
    }

    setSubmission(true);

    try {
      const parsedGameParams: GameParamDto[] = Object.values(gameParams).map(config => ({
        variantId: config.variantId,
        rakeStructureId: config.rakeStructureId,
        bringIn: parseToIntOrDefault(config?.bringIn, undefined),
        ante: parseToIntOrDefault(config?.ante, 0),
        bbAnte: parseToIntOrDefault(config?.bbAnte, 0),
        sbAnte: parseToIntOrDefault(config?.sbAnte, 0),
        smallBet: parseToIntOrDefault(config?.smallBet, 0),
        bigBet: parseToIntOrDefault(config?.bigBet, 0),
        bettingCap: parseToIntOrDefault(config?.bettingCap, 0),
        smallBlind: parseToIntOrDefault(config?.smallBlind, 0),
        bigBlind: parseToIntOrDefault(config?.bigBlind, 0),
      }));

      const firstGame = parsedGameParams[0];

      const tableData: Omit<MixedTableDto,"id"> = {
        type: TableType.CASH,
        gameParams: parsedGameParams,
        isRealMoney: formData.isRealMoney ?? false,
        isPublic: formData.isPublic ?? false,
        timeoutInSeconds: parseInt(formData.timeoutInSeconds as any, 10),
        minBuyin: parseFloat(formData.minBuyin as any),
        maxBuyin: parseFloat(formData.maxBuyin as any),
        maxPlayers: parseInt(formData.maxPlayers as any, 10),

        smallBlind: parseToFloatToZero(String(firstGame.smallBlind)),
        bigBlind: parseToFloatToZero(String(firstGame.bigBlind)),
        smallBet: parseToFloatToZero(String(firstGame.smallBet)),
        bigBet: parseToFloatToZero(String(firstGame.bigBet)),
        
        game: formData.game,
        name: formData.name,
        templateId: undefined,
        handsPerGame: 6
      };


      const response = await createTableRest(tableData);
      showToast("Table created successfully", "success");
      navigate(`${routes.TABLE}/${String(response.data.id)}`);
    } catch (error: any) {
      setSubmission(false);
      handleServerError(error);
    }
  };

  
  const handleServerError = (error: any) => {
    const handleErrors = error.response?.data?.message;

    if (!handleErrors) {
      showToast("Unknown error from server", "warning");
      return;
    }

    const errors = Array.isArray(handleErrors) ? handleErrors : [handleErrors];

    errors.forEach((error: string) => {
      showToast(error, "warning");
    });
  };


  const onError = (errors: FieldErrors<ValidationSchema>) => {
    const errorMessages = Object.entries(errors)
      .map(([field, error]) => error?.message ? `${field}: ${error.message}` : null)
      .filter((message): message is string => typeof message === 'string')
      .join(", ");
    showToast(`Please review provided fields: ${errorMessages}`, "warning");
  };

  return (
    <React.Fragment>
      <div className="page-content mb-6">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Card className="card mb-2">
                <CardBody className="card-body">
                  <h3 className="mb-5">Table Creation</h3>                  
                  <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <FormFields>
                      <FormField
                        name="name"
                        control={control}
                        label="Table Name"
                        error={errors.name?.message}
                      />
                      <FormField
                        name="game"
                        control={control}
                        label="Game Name"
                        error={errors.game?.message}
                      />
                    </FormFields>
                    <FormFields>
                      <Col lg={5} sm={12} className={`mb-4`}>
                        <label htmlFor="variants">Select a Game</label>
                        <div className="input-group">
                          <select
                            onChange={handleGameVariant}
                            className={`form-select ${errors.variants ? "is-invalid" : ""}`}
                          >
                            <option value="">Select game</option>
                            {games && games.map((game) =>
                              <option key={game.id} value={game.id}>
                                {game.name}
                              </option>
                            )}
                          </select>
                        </div>
                        {errors.variants && <span className="invalid-feedback">{errors.variants.message}</span>}
                      </Col>
                     
                        {control._formValues?.variants &&
                          <Col lg={5} sm={12} className={`mb-4`}>
                            <div className="mb-3">
                              <b>Variants</b>
                            </div>
                            <ul>
                              {control._formValues?.variants.map((variant: string) => (
                                <li key={variant}>{variant.replace(/([a-z])([A-Z])/g, '$1 $2')}</li>
                              ))}
                            </ul>
                          </Col>}

                    </FormFields>
                    <FormFields>
                      <FormField
                        name="minBuyin"
                        control={control}
                        label="Min Buyin"
                        error={errors.minBuyin?.message}
                        prefix="$"
                        type="number"
                      />
                      <FormField
                        name="maxBuyin"
                        control={control}
                        label="Max Buyin"
                        error={errors.maxBuyin?.message}
                        prefix="$"
                        type="number"
                      />
                    </FormFields>
                    <FormFields>
                      <FormField
                        type="select"
                        name="maxPlayers"
                        control={control}
                        label="Table Size"
                        error={errors.maxPlayers?.message}
                        options={maxPlayersOptions()}
                      />
                      <FormField
                        type="select"
                        name="timeoutInSeconds"
                        control={control}
                        label="Time to act"
                        error={errors.timeoutInSeconds?.message}
                        options={timeOutOptions()}
                      />
                    </FormFields>
                    <FormFields>
                      <div className="form-check form-switch form-switch-right form-switch-md ms-2 d-flex align-bottom">
                        <div className="d-flex">
                          <span>Is Real Money</span>
                          <Controller
                            name="isRealMoney"
                            control={control}
                            render={({ field }) => (
                              <input
                                className="ms-4 form-check-input code-switcher"
                                type="checkbox"
                                checked={field.value}
                                onChange={e => field.onChange(e.target.checked)}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="form-check form-switch form-switch-right form-switch-md ms-2 d-flex align-bottom">
                        <div className="d-flex">
                          <span>Is Public</span>
                          <Controller
                            name="isPublic"
                            control={control}
                            render={({ field }) => (
                              <input
                                className="ms-4 form-check-input code-switcher"
                                type="checkbox"
                                checked={field.value}
                                onChange={e => field.onChange(e.target.checked)}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </FormFields>

                    {gameVariantTokens.length > 0 &&
                      <div className="mt-4">
                        {gameVariantTokens.map((token, index) => {
                          const [gameId, variantInfo] = token.split('+');
                          const [variant, variantIndex] = variantInfo.split('#');
                          return (
                            <div key={token}>
                              <TableGameParam 
                                key={token}
                                ref={tableGameParamRefs.current[token]}
                                blindStructures={blindStructures}
                                mixedGame={gameVariantTokens.length>1}
                                variant={variant as PokerVariantIds}
                                initialValues={gameParams[token]}
                                onValidConfig={(config) => handleValidGameParam(token, config)}
                                onInvalidConfig={() => handleInvalidGameParam(token)}
                              />
                            </div>
                          );
                        })}
                      </div>
                    }

                    <Col lg={10} className="mt-5">
                      <div className="d-flex flex-row mt-4 justify-content-end">
                        <Button type="submit" className="ms-5 my-4" color="primary" disabled={submission}>
                          {submission && <Spinner size={"sm"} className="mx-2"/>} Create
                        </Button>
                      </div>
                    </Col>

                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default TableCreate;
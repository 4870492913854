import React from "react";
import Route from "./routes";
import { useChainId, useSwitchChain } from "wagmi";
import {} from "viem/chains";
import config from "./config";

function App() {
  const { chains, switchChain } = useSwitchChain();

  React.useEffect(() => {
    switchChain({ chainId: config.chain.id });
  }, []);

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;

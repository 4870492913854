import React, { useEffect, useState } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { updateUserRest } from "@/services/users/users-rest";
import { converUserInfoToUpdateUserDto, UserInfo, UserRoles } from "@/services/users/user-types";


const UserEditRole: React.FC<{
  show: boolean;
  userId: string;
  user: UserInfo;
  role: string;
  modalCallback: (success: boolean, message: string) => void;
  handleClose: any;
  refetch: ()=>void;
}> = ({ show, userId, user, role, modalCallback, handleClose, refetch }) => {
  
  const [submission, setSubmission] = useState(false);

  const handleSubmit = async () => {
    const isAgent = role === UserRoles.AGENT;
    const isAmbassador = role === UserRoles.AMBASSADOR;

    setSubmission(true);
  
     updateUserRest(userId, {
        ...converUserInfoToUpdateUserDto({... user, isAgent, isAmbassador}),
      }).then(()=>{
        refetch()
        modalCallback(true, `${user.alias} role updated successfully`);
      }).catch((error)=>{
        modalCallback(false, `${user.alias} role could not be updated. ${error?.response?.data?.message}`);
      })
 
      handleClose();
      setSubmission(true);



  };

  return (
    <React.Fragment>
      <Modal id="showModal" isOpen={show} toggle={handleClose} centered>
        <ModalHeader className="bg-dark p-3" toggle={handleClose}>
          Edit User Role
        </ModalHeader>
        <Form
          className="tablelist-form"
          onSubmit={(e: any) => {
            e.preventDefault();
            handleSubmit();
            return false;
          }}
        >
          <ModalBody className="fs-5 mb-3">
            <div>
              <div>
                Do you confirm that you want to change user{" "}
                <span className="fw-bold">{user.alias}</span> role from
                <span className="fw-bold mx-1">
                  {user.isAgent
                    ? UserRoles.AGENT
                    : user.isAmbassador
                    ? UserRoles.AMBASSADOR
                    : UserRoles.NO_ROLE}
                </span>
                to <span className="fw-bold">{role}</span>?
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="fs-5 gap-2">
            <div className="gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-dark"
                onClick={handleClose}
              >
                Close
              </button>

              <button type="submit" className="m-4 btn btn-primary" color="primary" disabled={submission}>
                {submission && <Spinner size={"sm"} className="mx-2"/>} Apply
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
export default UserEditRole;

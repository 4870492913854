import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Card, CardBody, CardHeader, Row } from "reactstrap";
import { fetchUsersCount, fetchNewUsersByDay } from "@/services/users/users-rest";
import { UserQueryDto } from "@/services/users/user-types";


import { AxiosError } from "axios";
import { showToast } from "@/common/showToast";
import CountUp from "react-countup";
import UserGrowthChart from "./UserGrowthChart";

interface UserGrowthProps {
  fromDate: string;
  toDate: string;
  groupByDate: boolean;
}

const ChartHeader = ({ name, value, theme = "" }: { name: string, value: number, theme?: string }) => {
    return (
      <div className="p-3 border border-dashed border-start-0 flex-fill ">
        <h5 className={"mb-1 " + theme}>
          <CountUp start={0} end={value} duration={3} separator="," />
        </h5>
        <p className="text-muted mb-0">{name}</p>
      </div>
    )
  }

const UserGrowth: React.FC<UserGrowthProps> = ({ fromDate, toDate, groupByDate }) => {
  const params: UserQueryDto = { fromDate, toDate, groupByDate };

  const { data: usersCount, error: usersCountError } = useQuery({
    queryKey: ["usersCount", params],
    queryFn: () => fetchUsersCount(params),
  });

  const { data: newUsersByDay, error: newUsersByDayError } = useQuery({
    queryKey: ["newUsersByDay", params],
    queryFn: () => fetchNewUsersByDay(params),
  });

  if (usersCountError || newUsersByDayError) {
    const errorMessage = (usersCountError as AxiosError)?.message || (newUsersByDayError as AxiosError)?.message || "Failed to fetch data.";
    showToast(`Error when fetching server data: ${errorMessage}`, "warning");
  }

  return (
    <Card>
      <CardHeader className="border-0 align-middle d-flex">
        <h4 className="card-title mb-0 flex-grow-1">User's Growth</h4>
      </CardHeader>
      <CardHeader className="border-0 align-middle d-flex">
        <Row className="g-0 text-center w-100">
          <div className="d-flex flex-row w-100">
            <ChartHeader name="Total Users" value={usersCount?.usersCount ?? 0} />
            <ChartHeader name="Ambassadors" value={usersCount?.ambassadorsCount ?? 0} />
            <ChartHeader name="Agents" value={usersCount?.agentsCount ?? 0} />
            <ChartHeader name="Today's new" value={usersCount?.todayNew ?? 0} theme="text-success" />
          </div>
        </Row>
      </CardHeader>
      <CardBody className="p-0 pb-2">
        <div className="w-100">
          <div dir="ltr">
            <UserGrowthChart data={newUsersByDay ?? []} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default UserGrowth;

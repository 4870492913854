import { useQuery } from "@tanstack/react-query";
import { getAvatarModel } from "../avaturn.service";

type UseGetAvatarModel = {
  url: string;
};

const useGetAvatarModel = ({ url }: UseGetAvatarModel) => {
  return useQuery({
    queryKey: ["avatar-model", url],
    queryFn: () => getAvatarModel(url),
    retry: false,
  });
};

export default useGetAvatarModel;

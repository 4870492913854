import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
} from "reactstrap";

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  ColumnFilter,
  OnChangeFn,
  ColumnFiltersState,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table";

import { fetchPlayerRakeRest } from "@/services/rake/rake-service";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "@/common/showToast";
import DebouncedInput from "@/components/DebounceInput";

import Form40X from "@/common/forms/Form40X";
import Flatpickr from "react-flatpickr";
import { AxiosError } from "axios";
import { QueryPeriods } from "@/common/QueryPeriods";
import { TableData } from "@/common/tableData/types";
import { useNavigate } from "react-router-dom";
import routes from "@/routes/routes-enums";

const RakeTable: React.FC = () => {
  const navigate = useNavigate();

  const today = new Date().toISOString().split("T")[0];
  const [toDate, setToDate] = useState(today);
  const [fromDate, setFromDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 90);
    return date.toISOString().split("T")[0];
  });

  const [data, setData] = useState<TableData[]>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([]);
  const [sortingState, setSortingState] = useState<SortingState>([]);

  useEffect(() => {

    if (!(fromDate && toDate) ) return;

    fetchPlayerRakeRest({
      fromDate,
      toDate,
      period: QueryPeriods.day,
      groupByDate: true,
      userId: true,
    })
      .then((response: TableData[]) => {
        setData(response);
      })
      .catch((error: AxiosError) => {
        showToast(`Error when fetching server data: ${error.message}`, "warning");
      });
    }, [fromDate, toDate]);

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "dataRange",
        header: "Date",
        type: "date",
      },
      {
        accessorKey: "alias",
        header: "Alias",
        type: "string",
      },
      {
        accessorKey: "totalAmount",
        header: "Generated Rake",
        type: "num",
      },
    ],
    []
  );

  const tableInstance = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      sorting: sortingState,
    },
    onColumnFiltersChange: setColumnFilters as OnChangeFn<ColumnFiltersState>,
    onSortingChange: setSortingState as OnChangeFn<SortingState>,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  const handleDateChange = (dates: Date[]) => {
    if (dates.length === 2) {
      const [start, end] = dates.map(
        (date) => date.toISOString().split("T")[0]
      );
      setFromDate(start);
      setToDate(end);
    }
  };

  const formatCell = (value: any, accessorKey: string) => {
    if (accessorKey === "isAgent" || accessorKey === "isAmbassador") {
      return value ? "Yes" : "No";
    }
    if (accessorKey === "createdAt") {
      return value ? new Date(value).toLocaleDateString() : "";
    }
    return value;
  };

  function formatISOToDate(isoString: string|undefined) {
    if (!isoString) return ""
    const date = new Date(isoString);
    return date.toISOString().split('T')[0];
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <div>
              <div>
                <div className="d-flex flex-row mb-3  flex-row-reverse">
                <div className="col-sm-auto mx-3">
                  <div className="input-group">
                    <Flatpickr
                      className="form-control border-0 dash-filter-picker shadow"
                      options={{
                        mode: "range",
                        dateFormat: "Y-m-d",
                        defaultDate: [fromDate, toDate],
                      }}
                      onChange={handleDateChange}
                    />
                    <div className="input-group-text bg-primary border-primary text-white">
                      <i className="ri-calendar-2-line"></i>
                    </div>
                  </div>
                </div>
                  
                  {/* <ButtonGroup>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="button"
                        className="btn btn-soft-primary text-white ms-auto"
                      >
                        <i className="ri-equalizer-line align-bottom me-1"></i>{" "}
                        Filters
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-md p-4">
                        {tableInstance.getHeaderGroups().map((headerGroup) => (
                          <div key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <div key={header.id} className="my-3">
                                {header.isPlaceholder ? null : (
                                  <div className="mx-3">
                                    <span
                                      className="me-3"
                                      onClick={() =>
                                        header.column.toggleSorting()
                                      }
                                    >
                                      {
                                        columns.filter(
                                          (column) =>
                                            column.accessorKey === header.id
                                        )[0]?.header
                                      }
                                    </span>

                                    {["isAgent", "isAmbassador"].includes(
                                      header.id
                                    ) ? (
                                      <div className="form-check form-switch form-switch-right form-switch-md">
                                        <Input
                                          className="form-check-input code-switcher"
                                          type="checkbox"
                                          checked={Boolean(
                                            header.column.getFilterValue()
                                          )}
                                          onChange={(e) =>
                                            header.column.setFilterValue(
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <DebouncedInput
                                        type="text"
                                        isLoading={false}
                                        value={
                                          header.column.getFilterValue()
                                            ? String(
                                                header.column.getFilterValue()
                                              )
                                            : ""
                                        }
                                        onChange={(value: string | number) =>
                                          header.column.setFilterValue(
                                            String(value)
                                          )
                                        }
                                        placeholder={`Filter ${header.id}...`}
                                        className=""
                                        debounce={500}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </ButtonGroup> */}
                </div>

                <Card className="card mb-2">
                  <CardBody className="card-body">
                    <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                      <thead>
                        {tableInstance.getHeaderGroups().map((headerGroup) => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <th key={header.id}
                              className="cursor-pointer">
                                {header.isPlaceholder ? null : (
                                  <>
                                    <span
                                      onClick={() =>
                                        header.column.toggleSorting()
                                      }
                                    >
                                      {
                                        columns.filter(
                                          (column) =>
                                            column.accessorKey === header.id
                                        )[0]?.header
                                      }
                                      {header.column.getIsSorted() ? (
                                        header.column.getIsSorted() === "desc" ? (
                                          <i className=" ri-arrow-down-s-line" />
                                        ) : (
                                          <i className=" ri-arrow-up-s-line" />
                                        )
                                      ) : (
                                        <i className="ms-1 ri-arrow-up-down-line" />
                                      )}
                                    </span>
                                  </>
                                )}
                              </th>
                            ))}
                            <th style={{maxWidth:"100px"}}>
                              Actions
                            </th>
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                      {tableInstance.getRowModel().rows.map((row) => (
                          <tr
                            key={row.id}
                          >
                            <td>
                              {formatISOToDate(row.original.dataRange)}
                            </td>
                            <td>
                              {row.original.alias}
                            </td>
                            <td>
                              {row.original.totalAmount.toFixed(2)}
                            </td>
                            <td>
                            <button
                            onClick={() => navigate(`${routes.USER_EDIT}/${row.original.userId}`)}
                            className="btn btn-primary"
                          >
                            <i className="ri-eye-line"></i>
                          </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default RakeTable;

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import AgentRakeDistribution from "./panels/1-agent-rake-distribution";
import UpdatePHNMPrice from "./panels/3-update-phnm-price";
import TransferPHNMtoTreasury from "./panels/4-transfer-phnm-to-treasury";
import NetRakeDistribution from "./panels/2-net-rake-distribution";
import RevenueDistribution from "./panels/5-revenue-distribution";
import TransferLeftOverRakeToTreasury from "./panels/6-transfer-left-over-rake-to-treasury";
import FundsRewardsManager from "./panels/7-funds-rewards-manager";
import RewardsDistribution from "./panels/8-rewards-distribution";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "@/common/showToast";
import EndRewardsDistribution from "./panels/9-end-rewards-distribution";
import { useReadContract } from "wagmi";
import config from "@/config";
import { abi } from "@/common/abis/token-flow-orchestrator";
import NavItemStep, { Step } from "./components/NavItemStep";

interface NavigationButtonsProps {
  currentStep: number;
  totalSteps: number;
  onPrevious?: () => void;
  onNext?: () => void;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  currentStep,
  totalSteps,
  onPrevious,
  onNext,
}) => {
  return (
    <div className="d-flex align-items-start gap-3 mt-4">
      {currentStep > 1 && (
        <button
          type="button"
          className="btn btn-light btn-label previestab"
          onClick={onPrevious}
        >
          <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
          Previous
        </button>
      )}
      {currentStep < totalSteps && (
        <button
          type="button"
          className="btn btn-light btn-label right ms-auto nexttab"
          onClick={onNext}
        >
          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
          Next
        </button>
      )}
    </div>
  );
};

export interface WizardTransition {
  currentState: number;
  setState: (state: number) => void;
  setToast: (message: string, type?: "success" | "warning") => void;
  enabled: boolean;
}

export enum ProcessState {
  Idle,
  DistributeAgentRake,
  DistributeNetRake,
  SetPHNMPrice,
  TransferPHNMToTreasury,
  DistributeRevenue,
  TransferLeftoverRevenue,
  FundRewardsManager,
  DistributeReferralAndRakeRewards,
}
const steps: Step[] = [
  { id: 1, label: "Agent Rake Distribution" },
  { id: 2, label: "Net Rake Distribution" },
  { id: 3, label: "Update PHNM Price" },
  { id: 4, label: "Transfer PHNM to Treasury" },
  { id: 5, label: "Revenue Distribution" },
  { id: 6, label: "Transfer leftover rake to Treasury" },
  { id: 7, label: "Funds Rewards Manager" },
  { id: 8, label: "Rewards Distribution" },
];

const TokenFlowOrchestrator = () => {
  const [activeVerticalTab, setActiveVerticalTab] = useState(0);
  const [passedverticalSteps, setPassedverticalSteps] = useState([0]);
  const tokenFlowOrchestratorState = useReadContract({
    address: config.contracts.orchestrator,
    abi: abi,
    functionName: "currentState",
  });

  const orchestrator = Number(tokenFlowOrchestratorState?.data);

  function toggleVerticalTab(tab: any) {
    if (activeVerticalTab !== tab) {
      var modifiedSteps = [...passedverticalSteps, tab];
      if (tab >= 0 && tab <= 9) {
        setActiveVerticalTab(tab);
        setPassedverticalSteps(modifiedSteps);
      }
    }
  }

  function handleSetActiveVerticalTab(tab: any) {
    setActiveVerticalTab(tab);
    tokenFlowOrchestratorState.refetch();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <div>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardHeader>
                      <h4 className="card-title mb-0">
                        Token Flow Wizard {orchestrator + 1 === 4}
                      </h4>
                    </CardHeader>
                    <CardBody className="form-steps">
                      <div className="vertical-navs-step">
                        <Row className="gy-5">
                          <Col lg={3}>
                            <form className="vertical-navs-step form-steps">
                              <Nav className="flex-column custom-nav nav-pills">
                                {steps.map((step) => (
                                  <NavItemStep
                                    step={step}
                                    orchestrator={orchestrator}
                                    activeVerticalTab={activeVerticalTab}
                                    toggleVerticalTab={toggleVerticalTab}
                                  />
                                ))}
                              </Nav>
                            </form>
                          </Col>
                          <Col lg={9}>
                            <div className="px-lg-4">
                              <TabContent activeTab={activeVerticalTab}>
                                <TabPane tabId={1}>
                                  <AgentRakeDistribution
                                    currentState={activeVerticalTab}
                                    setState={handleSetActiveVerticalTab}
                                    setToast={showToast}
                                    // Distribute agent rake might start by setting the orchestrator to 1 but fail in the backend and not reset the orchestrator so we need to allow the action to be triggered again
                                    enabled={orchestrator <= 1}
                                  />
                                  <NavigationButtons
                                    currentStep={activeVerticalTab}
                                    totalSteps={steps.length}
                                    onPrevious={undefined}
                                    onNext={() =>
                                      toggleVerticalTab(activeVerticalTab + 1)
                                    }
                                  />
                                </TabPane>

                                <TabPane tabId={2}>
                                  <NetRakeDistribution
                                    currentState={activeVerticalTab}
                                    setState={handleSetActiveVerticalTab}
                                    setToast={showToast}
                                    enabled={
                                      0 < orchestrator && orchestrator <= 2
                                    }
                                  />
                                  <NavigationButtons
                                    currentStep={activeVerticalTab}
                                    totalSteps={steps.length}
                                    onPrevious={() =>
                                      toggleVerticalTab(activeVerticalTab - 1)
                                    }
                                    onNext={() =>
                                      toggleVerticalTab(activeVerticalTab + 1)
                                    }
                                  />
                                </TabPane>

                                <TabPane tabId={3}>
                                  <UpdatePHNMPrice
                                    currentState={activeVerticalTab}
                                    setState={handleSetActiveVerticalTab}
                                    setToast={showToast}
                                    enabled={
                                      1 < orchestrator && orchestrator <= 3
                                    }
                                  />
                                  <NavigationButtons
                                    currentStep={activeVerticalTab}
                                    totalSteps={steps.length}
                                    onPrevious={() =>
                                      toggleVerticalTab(activeVerticalTab - 1)
                                    }
                                    onNext={() =>
                                      toggleVerticalTab(activeVerticalTab + 1)
                                    }
                                  />
                                </TabPane>

                                <TabPane tabId={4}>
                                  <TransferPHNMtoTreasury
                                    currentState={activeVerticalTab}
                                    setState={handleSetActiveVerticalTab}
                                    setToast={showToast}
                                    enabled={
                                      2 < orchestrator && orchestrator <= 4
                                    }
                                  />
                                  <NavigationButtons
                                    currentStep={activeVerticalTab}
                                    totalSteps={steps.length}
                                    onPrevious={() =>
                                      toggleVerticalTab(activeVerticalTab - 1)
                                    }
                                    onNext={() =>
                                      toggleVerticalTab(activeVerticalTab + 1)
                                    }
                                  />
                                </TabPane>

                                <TabPane tabId={5}>
                                  <RevenueDistribution
                                    currentState={activeVerticalTab}
                                    setState={handleSetActiveVerticalTab}
                                    setToast={showToast}
                                    enabled={
                                      3 < orchestrator && orchestrator <= 5
                                    }
                                  />
                                  <NavigationButtons
                                    currentStep={activeVerticalTab}
                                    totalSteps={steps.length}
                                    onPrevious={() =>
                                      toggleVerticalTab(activeVerticalTab - 1)
                                    }
                                    onNext={() =>
                                      toggleVerticalTab(activeVerticalTab + 1)
                                    }
                                  />
                                </TabPane>

                                <TabPane tabId={6}>
                                  <TransferLeftOverRakeToTreasury
                                    currentState={activeVerticalTab}
                                    setState={handleSetActiveVerticalTab}
                                    setToast={showToast}
                                    enabled={
                                      4 < orchestrator && orchestrator <= 6
                                    }
                                  />
                                  <NavigationButtons
                                    currentStep={activeVerticalTab}
                                    totalSteps={steps.length}
                                    onPrevious={() =>
                                      toggleVerticalTab(activeVerticalTab - 1)
                                    }
                                    onNext={() =>
                                      toggleVerticalTab(activeVerticalTab + 1)
                                    }
                                  />
                                </TabPane>

                                <TabPane tabId={7}>
                                  <FundsRewardsManager
                                    currentState={activeVerticalTab}
                                    setState={handleSetActiveVerticalTab}
                                    setToast={showToast}
                                    enabled={
                                      5 < orchestrator && orchestrator <= 7
                                    }
                                  />
                                  <NavigationButtons
                                    currentStep={activeVerticalTab}
                                    totalSteps={steps.length}
                                    onPrevious={() =>
                                      toggleVerticalTab(activeVerticalTab - 1)
                                    }
                                    onNext={() =>
                                      toggleVerticalTab(activeVerticalTab + 1)
                                    }
                                  />
                                </TabPane>

                                <TabPane tabId={8}>
                                  <RewardsDistribution
                                    currentState={activeVerticalTab}
                                    setState={handleSetActiveVerticalTab}
                                    setToast={showToast}
                                    enabled={
                                      6 < orchestrator && orchestrator <= 8
                                    }
                                  />
                                  <NavigationButtons
                                    currentStep={activeVerticalTab}
                                    totalSteps={steps.length}
                                    onPrevious={() =>
                                      toggleVerticalTab(activeVerticalTab - 1)
                                    }
                                    onNext={() =>
                                      toggleVerticalTab(activeVerticalTab + 1)
                                    }
                                  />
                                </TabPane>

                                <TabPane tabId={9}>
                                  <EndRewardsDistribution />
                                </TabPane>
                              </TabContent>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

export default TokenFlowOrchestrator;

import { usePrivy } from "@privy-io/react-auth";
import { useDispatch } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import { AuthEvents, authActionCreator } from "@/services/auth/authActions";
import LoadingSpinner from "@/components/LoadingSpinner";
import ROUTES from "@/routes/routes-enums";

const AuthGuard = () => {
  const { ready, authenticated } = usePrivy();
  const dispatch = useDispatch();

  if (!ready) {
    return (
      <div className="h-dvh">
        <LoadingSpinner size="23" />
      </div>
    );
  }

  if (!authenticated) {
    return <Navigate to={ROUTES.AUTHENTICATION} />;
  }
  dispatch(authActionCreator(AuthEvents.FETCH_AUTHENTICATED_USER_DATA));

  return authenticated ? <Outlet /> : <Navigate to={ROUTES.AUTHENTICATION} />;
};

export default AuthGuard;

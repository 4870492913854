export enum QueryPeriods {
    day = "day",
    week = "week",
    month = "month",
}


export const getDateMinusDays = (days: number) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date.toISOString().split("T")[0];
  };
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button, Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "@/common/showToast";
import { blindCreateStructureRest } from "@/services/game-params/parameters-services";
import { useNavigate } from "react-router-dom";
import FormField from "./form-field";
import FormFields from "@/common/forms/FormFields";
import { BlindStructureDto } from "@/services/game-params/parameters-types";
import routes from "@/routes/routes-enums";

const BlindStructureCreate = () => {
  const navigate = useNavigate();
  const [submission, setSubmission] = useState(false);


  const validationSchema = Yup.object().shape({
    fixedLimit: Yup.boolean().required("Fixed Limit is required"),
    smallBet: Yup.number().when('fixedLimit', {
      is: true,
      then: schema => schema.required("Small Bet is required").min(0.01, "Small Bet must be greater than 0").max(Yup.ref('bigBet'), "Small Bet must be less than or equal to Big Bet"),
      otherwise: schema => schema.nullable()
    }),
    bigBet: Yup.number().when('fixedLimit', {
      is: true,
      then: schema => schema.required("Big Bet is required").min(0.01, "Big Bet must be greater than 0").min(Yup.ref('smallBet'), "Big Bet must be greater than or equal to Small Bet"),
      otherwise: schema => schema.nullable()
    }),
    smallBlind: Yup.number().when('fixedLimit', {
      is: false,
      then: schema => schema.required("Small Blind is required").min(0.01, "Small Blind must be greater than 0").max(Yup.ref('bigBlind'), "Small Blind must be less than or equal to Big Blind"),
      otherwise: schema => schema.nullable()
    }),
    bigBlind: Yup.number().when('fixedLimit', {
      is: false,
      then: schema => schema.required("Big Blind is required").min(0.01, "Big Blind must be greater than 0").min(Yup.ref('smallBlind'), "Big Blind must be greater than or equal to Small Blind"),
      otherwise: schema => schema.nullable()
    }),
    rakePercent: Yup.number()
      .required("Rake Percentage is required")
      .min(0, "Rake Percentage must be at least 0")
      .max(100, "Rake Percentage must be at most 100"),
    rakeCap: Yup.number()
      .required("Rake Cap is required")
      .min(0, "Rake Cap must be at least 0"),
    rakeCapHU: Yup.number()
      .required("Rake Cap Heads Up is required")
      .min(0, "Rake Cap Heads Up must be at least 0"),
  });

  type ValidationSchema = Yup.InferType<typeof validationSchema>;

  const { control, handleSubmit, formState: { errors }, watch, setValue } = useForm<ValidationSchema>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fixedLimit: true,
      smallBet: 0,
      bigBet: 0,
      smallBlind: 0,
      bigBlind: 0,
      rakePercent: 0,
      rakeCap: 0,
      rakeCapHU: 0,
    },
  });


  useEffect(() => {
    if (watch('fixedLimit')) {
      setValue('smallBet', watch('smallBet') || 0);
      setValue('bigBet', watch('bigBet') || 0);
      setValue('smallBlind', 0);
      setValue('bigBlind', 0);
    } else {
      setValue('smallBlind', watch('smallBlind') || 0);
      setValue('bigBlind', watch('bigBlind') || 0);
      setValue('smallBet', 0);
      setValue('bigBet', 0);
    }
  }, [watch('fixedLimit'), setValue]);

  const onSubmit = async (formData: ValidationSchema) => {
    setSubmission(true);
    try {

      
      let blinds
      let bettingStructure

      if (formData.fixedLimit) {
        blinds = null
        bettingStructure = `${(formData.smallBet)}/${(formData.bigBet)}`
      } else {
        blinds = `${(formData.smallBlind)}/${(formData.bigBlind)}`
        bettingStructure = null
      }
      const rakeStructureData: BlindStructureDto = {
        blinds: blinds,
        bettingStructure: bettingStructure,
        points: null,
        rakePercent: formData.rakePercent / 100,
        rakeCap: formData.rakeCap,
        rakeCapHU: formData.rakeCapHU,
      };

      
      
      await blindCreateStructureRest(rakeStructureData).then((data)=>{
        showToast("Blind structure created successfully", "success");
        navigate(`${routes.PARAMETERS_RAKE_STRUCTURE_UPDATE}/${String(data.id)}`);
        return;
      }).catch(error=> {
        if (error?.response?.data?.messages) {
          error?.response?.data.messages.forEach((error: string) => {
          showToast(error, "warning");
        })
      } else {
        showToast(error.response.data.message, "warning");;
      }      })
      
    } catch (error) {
      if (error instanceof Error) {
        showToast(error.message, "warning");
      } else {
        showToast("An unknown error occurred", "warning");
      }
    } finally {
      setSubmission(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content mb-6">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={12}>
            <Card>
              <CardBody>
              <h3 className="mb-5">Create Blind Structure</h3>
                <form onSubmit={handleSubmit(onSubmit)}>

                  <FormFields className="mb-4">                
                    <div className="form-check form-switch form-switch-right form-switch-md ms-2 d-flex align-bottom">
                        <div className="d-flex">
                          <span>Fixed and Mixed Limit Games</span>
                          <Controller
                            name="fixedLimit"
                            control={control}
                            render={({ field }) => (
                              <input
                                className="ms-4 form-check-input code-switcher"
                                type="checkbox"
                                checked={field.value}
                                onChange={e => field.onChange(e.target.checked)}
                              />
                            )}
                          />
                        </div>
                    </div>

                    {watch('fixedLimit') ? (
                      <FormFields>
                        <FormField
                          name="smallBet"
                          control={control}
                          label="Small Bet"
                          type="number"
                          prefix="$"
                          error={errors.smallBet?.message}
                        />
                        <FormField
                          name="bigBet"
                          control={control}
                          type="number"
                          prefix="$"
                          label="Big Bet"
                          error={errors.bigBet?.message}
                        />
                      </FormFields>
                    ) : (
                      <FormFields>
                        <FormField
                          name="smallBlind"
                          control={control}
                          type="number"
                          prefix="$"
                          label="Small Blind"
                          error={errors.smallBlind?.message}
                        />
                        <FormField
                          name="bigBlind"
                          control={control}
                          type="number"
                          prefix="$"
                          label="Big Blind"
                          error={errors.bigBlind?.message}
                        />
                      </FormFields>
                    )}

                  </FormFields>

                  <FormFields>
                    <FormField
                      name="rakePercent"
                      control={control}
                      type="number"
                      label="Rake Percentage"
                      error={errors.rakePercent?.message}
                      prefix="%"
                    />
                  </FormFields>

                  <FormFields>
                    <FormField
                      name="rakeCap"
                      control={control}
                      type="number"
                      label="Rake Cap"
                      error={errors.rakeCap?.message}
                      prefix="$"
                    />
                    <FormField
                      name="rakeCapHU"
                      control={control}
                      type="number"
                      label="Rake Cap Heads Up"
                      error={errors.rakeCapHU?.message}
                      prefix="$"
                    />
                  </FormFields>
                  
                  <Col lg={10} className="mt-5">
                    <div className="d-flex flex-row mt-4 justify-content-end">
                      <Button type="submit" className="ms-5 my-4" color="primary" disabled={submission}>
                        {submission && <Spinner size={"sm"} className="mx-2"/>} Create
                      </Button>
                    </div>
                  </Col>
                </form>
              </CardBody>
            </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default BlindStructureCreate;
import React, { FC } from "react";
import { Input, Col, Label } from "reactstrap";
import { useNavigate } from "react-router-dom";

interface LinkFieldProps {
  name: string;
  value?: any;
  className?: string;
  lg?: number;
  url?: string;
}

const LinkField: FC<LinkFieldProps> = ({ name, value, className, lg = 5, url }) => {
  const navigate = useNavigate();

  const formatValue = (val: any): string => {
    if (val === null || val === undefined) {
      return '';
    }
    if (typeof val === 'string' || typeof val === 'number') {
      return String(val);
    }
    if (Array.isArray(val)) {
      return val.map(formatValue).join(', ');
    }
    if (typeof val === 'object') {
      return Object.entries(val)
        .map(([key, value]) => `${key}: ${formatValue(value)}`)
        .join(', ');
    }
    return JSON.stringify(val);
  };

  return (
    <Col lg={lg} sm={12} className={className}>
      <div>
        <Label htmlFor={name} className="form-label">
          {name}
        </Label>
        <div className="input-group">
          <Input
            type="text"
            className="form-control"
            id={name}
            value={formatValue(value)}
            readOnly
          />
          {url && (
            <span
              className="input-group-text btn btn-primary"
              onClick={() => navigate(url)}
              style={{ cursor: 'pointer' }}
            >
              <i className="ri-eye-line"></i>
            </span>
          )}
        </div>
      </div>
    </Col>
  );
};

export default LinkField;
import React, { useEffect, useState, useCallback } from "react";
import { Button, Card, Col, Container, Row, Input, Alert, CardBody } from "reactstrap";

import InfoField from "@/common/forms/InfoField";
import InfoFieldBoolean from "@/common/forms/InfoFieldBoolean";
import { TableInfoDto } from "@/services/table/table-types";
import LoadingPage from "@/common/LoadingPage";

import { PokerVariantIds, TableStatus } from "@/common/types/phenomTypes";
import TableGameParamShow from "./table-game-params-show";

const TableDetailsInfo: React.FC <{table: TableInfoDto; isStudGame: boolean; isLimitGame: boolean;}>= ({table, isStudGame, isLimitGame}) => {

  if (!table) {
    return <LoadingPage />;
  }

  return (
      <div>

                <h3 className="mb-3">Table Details</h3>

                <div className="d-flex flex-wrap mb-3 gap-4">
                  <InfoField name="Game Mode" value={table?.type} />
                  {table?.variants &&
                          <Col lg={5} sm={12} className={`mb-4`}>
                            <div className="mb-3">
                              <b>Variants</b>
                            </div>
                            <ul>
                              {table?.variants?.map((variant: string) => (
                                <li key={variant}>{variant.replace(/([a-z])([A-Z])/g, '$1 $2')}</li>
                              ))}
                            </ul>
                          </Col>}
                </div>
                <div className="d-flex flex-wrap mb-3 gap-4">
                  {isStudGame && <InfoField name="Bring In" value={table?.bringIn} />}
                </div>
                <div className="d-flex flex-wrap mb-3 gap-4">
                  <InfoField name="Min Buy in" value={table?.minBuyin} />
                  <InfoField name="Max Buy in" value={table?.maxBuyin} />
                </div>
                <div className="d-flex flex-wrap mb-3 gap-4">
                  <InfoField name="Small Blind" value={table?.smallBlind} />
                  <InfoField name="Big Blind" value={table?.bigBlind} />
                </div>
                {isLimitGame && (
                  <div className="d-flex flex-wrap mb-3 gap-4">
                    <InfoField name="Small Bet" value={table?.smallBet} />
                    <InfoField name="Big Bet" value={table?.bigBet} />
                  </div>
                )}
                <div className="d-flex flex-wrap mb-3 gap-4">
                  <InfoField name="Ante" value={table?.ante} />
                  {isLimitGame && <InfoField name="Betting Cap" value={table?.bettingCap} />}
                </div>
                <div className="d-flex flex-wrap mb-3 gap-4">
                  <InfoField name="Max Players" value={table?.maxPlayers} />
                  <InfoField name="Time Out in Seconds" value={table?.timeoutInSeconds} />
                </div>
                <div className="d-flex flex-wrap mb-3 gap-4">
                  <InfoField name="Rake Percent" value={`${table?.rakePercent * 100} %`} />
                  <InfoField name="Rake Cap" value={table?.rakeCap} />
                </div>
                <div className="d-flex flex-wrap mb-3 gap-4">
                  <InfoField name="Rake Cap Heads Up" value={table?.rakeCapHU} />
                </div>
                <div className="d-flex flex-wrap mb-3 gap-4">
                  <InfoFieldBoolean name="Is Public" value={table?.isPublic} />
                  <InfoFieldBoolean name="Is Real Money" value={table?.isRealMoney} />
                </div>


                {table.gameParams?.map((params, index) => (
                  <TableGameParamShow key={index} mixedGame={(table.gameParams?.length ?? 0) > 1} variant={params.variantId as PokerVariantIds} params={params}/>
                ))}

        </div>
  );
};

export default TableDetailsInfo;
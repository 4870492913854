import React from "react";
import { useReadContract } from "wagmi";
import TokenBalance from "./TokenBalance";
import { Address } from "viem";

type ContractTokenBalanceProps = {
  tokenAddress: Address;
  contractAddress: Address;
  abi: readonly unknown[];
  decimals: number;
  tokenSymbol: string;
  simpleFormat?: boolean;
};
const ContractTokenBalance: React.FC<ContractTokenBalanceProps> = ({
  tokenAddress,
  contractAddress,
  abi,
  decimals,
  tokenSymbol,
  simpleFormat = false,
}) => {
  const {
    data: balance,
    isLoading,
    refetch,
  } = useReadContract({
    address: tokenAddress,
    abi: abi,
    functionName: "balanceOf",
    args: [contractAddress],
  });

  return (
    <TokenBalance
      balance={balance as bigint}
      tokenSymbol={tokenSymbol}
      tokenDecimals={decimals}
      formatDecimals={2}
      isLoading={isLoading}
      onClickRefresh={() => {
        refetch();
      }}
      simpleFormat={simpleFormat}
    />
  );
};

export default ContractTokenBalance;

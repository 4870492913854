import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Alert, Button, Card, CardBody, Col, Container, Row } from "reactstrap";

const Form40X: React.FC<{ message: string | undefined , moveToPageTxt: string, moveToPageURL: string}> = ({ message, moveToPageTxt, moveToPageURL }) => {
  const navigate = useNavigate();

  const moveToPage = () => {
    navigate(`${moveToPageURL}`);
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="container-fluid">
          <Row className="gy-2 mb-2">
            <Card className="card mb-2">
              <Col lg={10}>
                <CardBody className="card-body">
                  <div>
                    <Alert color="warning">{message}</Alert>
                    <Button 
                        onClick={() => window.location.reload()} 
                        color="primary"
                      >
                        Refresh Page
                    </Button>
                    <Button onClick={moveToPage} color="primary mx-3">
                      {moveToPageTxt}
                    </Button>
                  </div>
                </CardBody>
              </Col>
            </Card>{" "}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Form40X;

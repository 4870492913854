import React, { FC, ReactNode } from "react";
import { Row } from "reactstrap";

interface FormFieldsProps {
  children: ReactNode;
  className?: string;
}

const FormFields: FC<FormFieldsProps> = ({ children, className }) => (
  <Row className={`g-4 ${className ? className : ""} `}>{children}</Row>
);

export default FormFields;

import { payRakeShareRest } from "@/services/rake/rake-service";
import { useState } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import RakeSharePayoutComponents from "@/pages/rake/rake-share-payouts-component";
import { WizardTransition } from "../token-flow-orchestrator";
import { ContractInfo } from "@/components/ContractInfo";
import config from "@/config";

const AgentRakeDistribution: React.FC<WizardTransition> = ({
  currentState,
  setState,
  setToast,
  enabled,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const agentRakeDistribution = () => {
    setSubmitting(true);
    payRakeShareRest()
      .then(() => {
        setToast("Agent rake distributed", "success");
        setState(2);
      })
      .catch((error) => {
        setToast(
          `Transfering left over rake failed ${error?.response?.data?.message}`,
          "warning"
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return show ? (
    <Card>
      <CardHeader>
        <h2>
          <span className="btn btn-light mx-2">1</span>Agent Rake Distribution
        </h2>
      </CardHeader>
      <CardBody>
        <div className="d-flex justify-content-end mb-3">
          <button className="btn btn-light" onClick={() => setShow(false)}>
            x
          </button>
        </div>
        <RakeSharePayoutComponents />
      </CardBody>
    </Card>
  ) : (
    <Card>
      <CardHeader>
        <h2>
          <span className="btn btn-light mx-2">1</span>Agent Rake Distribution
        </h2>
      </CardHeader>
      <CardBody>
        <ContractInfo
          className="col-sm-12 col-lg-3"
          style={{ borderWidth: 1, minWidth: "fit-content" }}
          contractAddress={config.contracts.rakeManager}
          contractName="RakeManager"
          infoConfig={{ showUsdtBalance: true, showPhnmBalance: false }}
        />
        <div>
          <p>
            Start distributing the remaining rake, sending half of the remaining
            USDT in the RakeManager contract to the SafeTreasury and the other
            half to the RevenueShareManager. Create a transaction record in the
            database to reflect this distribution.
          </p>
        </div>
      </CardBody>
      <CardFooter>
        {/* <ul>
                      <li>Rake Manager Balance</li>
                      <li>Pending Payouts</li>
                      <li>Last Run</li>
                  </ul> */}
        <div className="d-flex justify-content-end gap-2">
          <button
            className="btn btn-soft-primary text-white"
            onClick={() => {
              if (!enabled) {
                setToast("Please follow Orchestrator steps", "warning");
                return;
              }
              setShow(true);
            }}
          >
            Rake Distribution
          </button>
          <button
            disabled={submitting || !enabled}
            className="btn btn-primary"
            onClick={() => {
              if (!enabled) {
                setToast("Please follow Orchestrator steps", "warning");
                return;
              }
              agentRakeDistribution();
            }}
          >
            {submitting ? "Distributing..." : "Distribute"}
          </button>
        </div>
      </CardFooter>
    </Card>
  );
};
export default AgentRakeDistribution;

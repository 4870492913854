import { distributeNetRakeRest } from "@/services/rake/rake-service";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { WizardTransition } from "../token-flow-orchestrator";
import { useState } from "react";
import { ContractInfo } from "@/components/ContractInfo";
import config from "@/config";

const NetRakeDistribution: React.FC<WizardTransition> = ({
  currentState,
  setState,
  setToast,
  enabled,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const netRakeDistribution = () => {
    setSubmitting(true);
    distributeNetRakeRest()
      .then(() => {
        setToast("Net Rake Distributed", "success");
        setState(3);
      })
      .catch((error) => {
        setToast(`Net rake distribution failed ${error}`, "warning");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Card>
      <CardHeader>
        <h2>
          <span className="btn btn-light mx-2">2</span>Net Rake Distribution
        </h2>
      </CardHeader>
      <CardBody>
        <ContractInfo
          className="col-sm-12 col-lg-3"
          style={{ borderWidth: 1, minWidth: "fit-content" }}
          contractAddress={config.contracts.rakeManager}
          contractName="RakeManager"
          infoConfig={{ showUsdtBalance: true, showPhnmBalance: false }}
        />
        <div>
          <p>
            Start distributing the remaining rake, sending half of the remaining
            USDT in the RakeManager contract to the SafeTreasury and the other
            half to the RevenueShareManager. Create a transaction record in the
            database to reflect this distribution.
          </p>
        </div>
      </CardBody>
      <CardFooter>
        <div className="d-flex justify-content-end gaps-2">
          <button
            disabled={submitting || !enabled}
            className="btn btn-primary"
            onClick={() => {
              if (!enabled) {
                setToast("Please follow Orchestrator steps", "warning");
                return;
              }
              netRakeDistribution();
            }}
          >
            {submitting ? "Distributing..." : "Distribute"}
          </button>
        </div>
      </CardFooter>
    </Card>
  );
};
export default NetRakeDistribution;

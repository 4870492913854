import { api } from "../api";
import {  GetTransactionDto, TransactionsQueryDto } from "./transactions-types";


export const gettransactionsRest = async (params: GetTransactionDto) => {
    const response = await api.get<TransactionsQueryDto>("v1/admin/transactions", {
      params: params,
    });

    return response.data;
  };

export const getDepositStatsRest = async () => {
  const response = await api.get<any>(`/v1/admin/transactions/deposit-stats`);
  return response.data;
}
  
export const adminTransferRest = async (address: string | undefined, amount: number | undefined) => {
  const response = await api.post(`/v1/admin/user/admin-transfer`,{
    amount,
    address
  });
  return response.data;
}
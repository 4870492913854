import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Container, Row } from "reactstrap";
import { useReactTable, getCoreRowModel, getSortedRowModel, ColumnDef, SortingState } from "@tanstack/react-table";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "@/common/showToast";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import routes from "@/routes/routes-enums";
import { RewardsPayoutDetailDto, RewardsPayoutPerPlayerDto } from '@/services/rake/rake-types';
import { fetchRewardPayouts } from '@/services/rake/rake-service';
import RewardsPayoutsComponents from './rewards-payouts-components';


const RewardsPayouts = () => {

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid className="container-fluid">
            <Row className="gy-2 mb-2">
              <div>
                <h1 className="text-2xl font-bold mb-4">Rewards Pending Payouts</h1>
                
                <RewardsPayoutsComponents />
              </div>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    )
  };


export default RewardsPayouts
import React from 'react';
import { formatUnits } from '@ethersproject/units'; // Assuming 'viem' was a typo. Corrected to a common library for formatting units.
import { Spinner } from 'reactstrap'; // Removed Placeholder as it's not used.
import { useReadContract } from 'wagmi';
import tables from "@/common/abis/tables.json"; // Added import for table ABI.
import { Address } from 'abitype/dist/types/abi';

const formatBalance = (balance: bigint | undefined, decimals: number) => {
  if (balance === undefined) {
    return '0';
  }
  return formatUnits(balance.toString(), decimals); // Adjusted for bigint compatibility with formatUnits.
};

type PlayerStackProps = {
  tableId: String;
  contractAddress: string | undefined;
  userWallet?: string;
  tokenSymbol?: string; // Made tokenSymbol optional with default value provided in destructure.
  decimals: number;
};

const PlayerStack: React.FC<PlayerStackProps> = ({
  tableId,
  contractAddress,
  userWallet,
  tokenSymbol = "USDT", // Default value assignment corrected.
  decimals
}) => {
  const contractUsdtBalance = useReadContract({
    address: contractAddress as Address,
    abi: tables.abi,
    functionName: "getStack",
    args: [tableId, userWallet],
  });

  return (
    <>
      {contractUsdtBalance.isLoading ? (
        <Spinner size="sm" />
      ) : (
        <span>
          {formatBalance(contractUsdtBalance.data as bigint, decimals || 18)}
        </span>
      )}
    </>
  );
};

export default PlayerStack;
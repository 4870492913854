
import { api } from "@/services/api";
import { AgreementDisplay, CreateAgreementDto, GetUserAgreementDto, UserAgreementDto, UserAgreementResponseDto } from "./agreement-types";

export const fetchAgreementRest = async (params : GetUserAgreementDto) => {
    const response = await api.get<UserAgreementResponseDto>(`/v1/agreements/`,{
      params: params,
    })
    return response.data;
  }; 

export const fetchAgreemnentByIdRest = async (
    agreementId: string
  ) => {
    const response = await api.get<AgreementDisplay[]>(`/v1/agreements/${agreementId}`);
    return response.data;
  }; 

export const postAgreementRest = async (
  createAgreementDto: CreateAgreementDto
  ) => {
    const response = await api.post<AgreementDisplay>(`/v1/agreements/`, {
      ...createAgreementDto
    });
    return response.data;
  }; 
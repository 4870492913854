import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Button, Card, CardBody, Col, Container, Row, Spinner, Input
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "@/common/showToast";
import { createGameRest } from "@/services/game-params/parameters-services";
import { useNavigate } from "react-router-dom";
import { PokerVariantIds } from "@/common/types/phenomTypes";
import MultiSelectWithOrder from "@/components/sortable/MultiSelectWithOrder";
import routes from "@/routes/routes-enums";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Game Name is required"),
  variants: Yup.array()
    .of(Yup.string().required("Variant is required"))
    .min(2, "At least two variants must be selected")
    .required("At least one variant is required")
});

type ValidationSchema = Yup.InferType<typeof validationSchema>;

const MixedGameCreate = () => {
  const navigate = useNavigate();
  const [submission, setSubmission] = useState(false);
  const [variants, setVariants] = useState<PokerVariantIds[]>([]);
  const [selectedVariant, setSelectedVariant] = useState('');

  const { control, handleSubmit, formState: { errors }, setValue } = useForm<ValidationSchema>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      variants: []
    },
  });


  const handleVariant = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const pokerVariantId = e.target.value as PokerVariantIds;
    if (pokerVariantId) {
      setVariants(prevVariants => [...prevVariants, pokerVariantId]);
      showToast("Variant added successfully", "success");
      setSelectedVariant('');
    }
  };

  useEffect(() => {
    setValue("variants", variants);
  }, [variants, setValue]);

  const onSubmit = async (formData: ValidationSchema) => {
    setSubmission(true);
    try {
      const response = await createGameRest({ ...formData, variants });

      if (response.status === 201 || response.status === 200) {
        showToast("Game created successfully", "success");
        navigate(routes.PARAMETERS_MIXED_GAMES_STRUCTURE);
      }
    } catch (error) {
      showToast("Failed to create game", "warning");
    } finally {
      setSubmission(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content mb-6">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Card className="card mb-2">
                <CardBody className="card-body">
                <h3 className="mb-5">Create Mixed Game</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-4">
                    <Col lg={5}>
                      <label htmlFor="name">Mixed Game Name</label>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <Input {...field} type="text" placeholder="Enter Game Name" invalid={!!errors.name} />
                        )}
                      />
                      {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
                    </Col>
                  </div>
                  <div className="mb-4">
                    <Col lg={5}>
                      <label>Poker Variant:</label>
                      <select
                        name="variant"
                        value={selectedVariant}
                        onChange={handleVariant}
                        className={`form-select ${errors?.variants ? "is-invalid" : ""}`}
                        aria-label="Default select"
                      >
                        <option value="">Select a variant</option>
                        {Object.entries(PokerVariantIds).map(([key, value]) => (
                          <option key={key} value={value}>
                            {key}
                          </option>
                        ))}
                      </select>
                      {errors?.variants && <div className="invalid-feedback">{errors.variants.message}</div>}
                    </Col>
                    {variants.length > 0 && (
                      <Col lg={5} className="mt-4 mb-6">
                        <label>Selected Variants</label>
                        <MultiSelectWithOrder
                          variants={variants}
                          setVariants={setVariants}
                        />
                      </Col>
                    )}
                  </div>
                  <Col lg={5}>
                    <div className="d-flex flex-row mt-4 justify-content-end gap-4">
                      <button className="btn btn-dark" type="button" onClick={() => navigate(routes.PARAMETERS_MIXED_GAMES_STRUCTURE)}>
                        Cancel
                      </button>
                      <Button type="submit" color="primary" disabled={submission}>
                        {submission && <Spinner size={"sm"} className="mx-2"/>} Create
                      </Button>
                    </div>
                  </Col>
                </form>
              </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default MixedGameCreate;
export function formatCurrency(
  amount: number | undefined = 0,
  decimals: number = 2,
  locale: string = "en-US"
): string {
  let finalDecimals = decimals;

  if (decimals === 0) {
    finalDecimals = Number.isInteger(amount) ? 0 : 2;
  }

  return new Intl.NumberFormat(locale, {
    style: "decimal",
    minimumFractionDigits: finalDecimals,
    maximumFractionDigits: finalDecimals,
  }).format(amount);
}

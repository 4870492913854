import React, { useState, useEffect, useMemo } from "react";
import { Card, CardHeader, CardBody, Container, Row } from "reactstrap";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  SortingState,
} from "@tanstack/react-table";
import { fetchRakebackPayouts } from "@/services/rake/rake-service";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "@/common/showToast";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import routes from "@/routes/routes-enums";
import { RakeBackPayoutPerPlayerDto } from "@/services/rake/rake-types";
import { useQuery } from "@tanstack/react-query";
import { formatCurrency } from "@/common/utils/formatCurrency";

const RakeBackPayoutComponents: React.FC = () => {
  const navigate = useNavigate();

  const [sortingState, setSortingState] = useState<SortingState>([]);

  const { data, isError, error } = useQuery({
    queryKey: ["rewards", false],
    queryFn: () => fetchRakebackPayouts(false),
  });

  const totalUnpaidRakeback = useMemo(() => {
    const total = data?.reduce((acc, curr) => acc + curr.amount, 0);
    return formatCurrency(total || 0);
  }, [data]);

  useEffect(() => {
    if (isError) {
      showToast(
        `Error when fetching server data: ${(error as AxiosError).message}`,
        "warning"
      );
    }
  }, [isError, error]);
  const columns: ColumnDef<RakeBackPayoutPerPlayerDto>[] = [
    {
      accessorKey: "",
      header: "Amount",
      cell: (info) => info.getValue<number>().toFixed(2),
    },
    {
      accessorKey: "count",
      header: "Count",
    },
    {
      accessorKey: "user.alias",
      header: "Alias",
    },
    {
      accessorKey: "user.isAgent",
      header: "Is Agent",
      cell: (info) => (info.getValue() ? "Yes" : "No"),
    },
    {
      accessorKey: "user.isAmbassador",
      header: "Is Ambassador",
      cell: (info) => (info.getValue() ? "Yes" : "No"),
    },
  ];

  const tableInstance = useReactTable({
    data: data || [],
    columns,
    state: {
      sorting: sortingState,
    },
    onSortingChange: setSortingState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  return (
    <Card>
      <CardHeader>
        <span className="fw-bold">Unpaid Rake Back</span> ({totalUnpaidRakeback}
        )
      </CardHeader>
      <CardBody>
        <table className="table table-hover table-centered align-middle table-nowrap mb-0">
          <thead>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <th>User Alias</th>
                <th>Role</th>
                <th className="text-end">Amount</th>
                {/* {headerGroup.headers.map((header) => (
                            <th key={header.id} className="cursor-pointer">
                              {header.isPlaceholder ? null : (
                                <span onClick={() => header.column.toggleSorting()}>
                                  {header.column.columnDef.header as string}
                                  {header.column.getIsSorted() ? (
                                    header.column.getIsSorted() === "desc" ? (
                                      <i className="ri-arrow-down-s-line" />
                                    ) : (
                                      <i className="ri-arrow-up-s-line" />
                                    )
                                  ) : (
                                    <i className="ms-1 ri-arrow-up-down-line" />
                                  )}
                                </span>
                              )} 
                            </th>
                          ))} */}
                <th className="text-end">Records</th>
                <th className="text-center" style={{ maxWidth: "100px" }}>
                  Actions
                </th>
              </tr>
            ))}
          </thead>
          <tbody>
            {tableInstance.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                <td>{row.original?.user?.alias}</td>
                <td>
                  {row.original?.user?.isAgent
                    ? "Agent"
                    : row.original?.user?.isAmbassador
                    ? "Ambassador"
                    : ""}
                </td>
                <td className="text-end">
                  {formatCurrency(row.original?.amount)}
                </td>
                <td className="text-end">{row.original?.count}</td>
                <td className="text-center">
                  <button
                    onClick={() =>
                      navigate(
                        `${routes.RAKE_SHARE_PER_PLAYER}/${row.original?.user?.id}`
                      )
                    }
                    className="btn btn-primary"
                  >
                    <i className="ri-eye-line"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};

export default RakeBackPayoutComponents;

import React, { useCallback, useEffect, useState } from 'react';
import {
  UserInfo,
  converUserInfoToUpdateUserDto,
  UserRoles,
  userTiers
} from '@/services/users/user-types';
import { fetchUserByIdRest, updateUserRest } from '@/services/users/users-rest';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Container,
  Col,
  Row,
  Spinner,
  Input,
} from 'reactstrap';
import InfoField from '@/common/forms/InfoField';
import FormField from '@/common/forms/FormField';
import FormFields from '@/common/forms/FormFields';
import { AxiosError } from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '@/common/showToast';
import { ValidationErrors } from '@/common/forms/table-handling/checkTableParams';
import CopyField from '@/common/forms/CopyField';
import AvatarImage from '@/features/avatar/AvatarImage';
import { getAvaturnAvatar } from '@/services/avaturn/avaturn.service';
import AvatarRender from '../../features/avatar/AvatarRender';
import ContractTokenBalance from "@/components/ContractTokenBalance";
import config from "@/config/index";
import { abi as usdtABI } from "@/common/abis/usdt";
import { abi as phnmABI } from "@/common/abis/phnm";
import { TransactionsDto } from "@/services/transactions/transactions-types";
import { gettransactionsRest, adminTransferRest } from '@/services/transactions/transactions-service';
import TransactionsTable from '@/pages/table/components/TransactionsTable';
import { toast } from "react-toastify";

const UserEdit = () => {
  const { userId } = useParams();

  const [formData, setFormData] = useState<UserInfo | undefined>(undefined);

  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
  const [submission, setSubmission] = useState<boolean>(false);
  const [updatedUser, setUpdatedUser] = useState<string | undefined>(undefined);
  const [transferAmount, setTransferAmount] = useState<number>(0);

  const [userTransactions, setUserTransactions] = useState<TransactionsDto[]>([]);

  const fetchTransactions = async () => {
    const response = await gettransactionsRest({ userId, skip: 0, take: 100 });
    setUserTransactions(response.transactions);
  }
    
  useEffect(() => {
    fetchTransactions();
  }, []);

  const [formError, setFormError] = useState<{
    [key: string]: string | undefined;
  }>({});

  const [avatar, setAvatar] = useState<any>(undefined);

  useEffect(() => {
    if (userId) {
      fetchUserByIdRest(userId)
        .then((response: any) => {
          setUserInfo(response);
          setFormData(response);
        })
        .catch((error: AxiosError) => {
          showToast(
            `Error when fetching server data: ${error.message}`,
            'warning'
          );
        });

      getAvaturnAvatar(userId)
        .then((response) => {
          setAvatar(response.data);
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  }, [userId]);

  const formValidate = useCallback((): boolean => {
    if (!formData) {
      setFormError({ header: 'There is no form data available.' });
      return false;
    }
    const validationErrors: ValidationErrors = {};

    if (formData.referralCode === formData.referrerCode) {
      validationErrors.referralCode = 'Referral code and Referrer code cannot match';
      validationErrors.referrerCode = 'Referral code and Referrer code cannot match';
    }

    if (!formData.firstName) {
      validationErrors.firstName = 'First Name is required';
    }

    if (!formData.lastName) {
      validationErrors.lastName = 'Last Name is required';
    }

    if (!formData.email) {
      validationErrors.minBuyin = 'Email is required';
    }

    if (formData.isAgent && formData.isAmbassador) {
      validationErrors.isAgent = 'User cannot be both Agent and Ambassador';
      validationErrors.isAmbassador =
        'User cannot be both Agent and Ambassador';
    }

    setFormError(validationErrors);
    return Object.keys(validationErrors).length === 0;
  }, [formData]);

  useEffect(() => {
    formValidate();
  }, [formData, formValidate]);

  const handleSubmitTransfer = async () => {
    try {
      setSubmission(true);
      const receiptHash = await adminTransferRest(userInfo?.smartWalletAddress, transferAmount);
      showToast(`Transfer to ${userInfo?.smartWalletAddress} success. Amount -- ${transferAmount}`);
      toast.success(
        (
          <div>
            <p>Transfer successful</p>
            <CopyField 
              className="w-100"
              name="txHash"
              label={false}
              value={receiptHash}
              link={`${config.blockExplorerUrl}/tx/${receiptHash}`}
            />
          </div>
        ),
        {
          pauseOnHover: true,
          autoClose: false,
          closeOnClick: false,
          hideProgressBar: true
        }
      )
      setTransferAmount(0);
    } catch (error) {
      showToast('Error completing transfer');
    } finally {
      setSubmission(false);
    }
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setFormData((prevState) =>
      prevState
        ? {
            ...prevState,
            [name]: value,
          }
        : undefined
    );
  };

  const [userRole, setUserRole] = useState<UserRoles>(
    formData?.isAgent
      ? UserRoles.AGENT
      : formData?.isAmbassador
      ? UserRoles.AMBASSADOR
      : UserRoles.NO_ROLE
  );

  useEffect(() => {
    if (formData?.isAgent && formData?.isAmbassador) {
      setFormData((prevState) =>
        prevState
          ? {
              ...prevState,
              isAgent: false,
              isAmbassador: false,
            }
          : undefined
      );
      setUserRole(UserRoles.NO_ROLE);
      return;
    }

    setUserRole(
      formData?.isAgent
        ? UserRoles.AGENT
        : formData?.isAmbassador
        ? UserRoles.AMBASSADOR
        : UserRoles.NO_ROLE
    );
  }, [formData?.isAgent, formData?.isAmbassador]);

  const handleRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const isAgent = userRole === UserRoles.AGENT;
    const isAmbassador = userRole === UserRoles.AMBASSADOR;

    const roleValue = e.target.value as UserRoles;
    setUserRole(roleValue);

    setFormData((prevState: UserInfo | undefined) =>
      prevState
        ? {
            ...prevState,
            isAgent,
            isAmbassador,
          }
        : undefined
    );
  };

  const handleTier = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData((prevState: UserInfo | undefined) =>
      prevState
        ? {
            ...prevState,
            tier: {
              name: e.target.value
            }
          }
        : undefined
    );
  }

  const handleBanned = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState: UserInfo | undefined) =>
      prevState
        ? {
            ...prevState,
            banned: e.target.checked
          }
        : undefined
    );
  }

  const handleIsPropPlayer = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState: UserInfo | undefined) =>
      prevState
        ? {
            ...prevState,
            isPropPlayer: e.target.checked
          }
        : undefined
    );
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formValidate() || !formData || !userId || !userInfo) {
      showToast('Please review the highlighted fields and correct any errors before resubmitting', 'warning');
      return;
    }

    setSubmission(true);

    await updateUserRest(userId, {
      ...converUserInfoToUpdateUserDto(formData),
      isAgent: userRole === UserRoles.AGENT,
      isAmbassador: userRole === UserRoles.AMBASSADOR,
    })
      .then((response) => {
        if (response.status === 200 && response.data.id) {
          setSubmission(false);
          showToast('Template updated successfully', 'success');
          return;
        }
      })
      .catch((error) => {
        setSubmission(false);

        const handleErrors = error.response.data.message;

        if (!handleErrors) {
          showToast('Unknown error from server', 'warning');
        }

        // Check if errors is an array, if not, make it an array with one element
        const errors = Array.isArray(handleErrors)
          ? handleErrors
          : [handleErrors];

        if (errors) {
          errors.forEach((element: string) => {
            showToast(element, 'warning');
          });
          return;
        } else {
          showToast(error.message, 'warning');
        }
      });
  };

  function formatISOToDate(
    isoString: Date | string | undefined,
    full: boolean = false
  ) {
    if (!isoString || String(isoString) === '') return '';
    const newDate = new Date(String(isoString));
    return (
      newDate.toISOString().split('T')[0] +
      (full ? ` ${newDate.toTimeString().split(' ')[1]}` : '')
    );
  }

  if (!userId) return null;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Card className="card mb-2">
                <CardBody className="card-body">
                  <h3 className="mb-5">Edit User</h3>
                  {formError.header && (
                    <Alert color="danger">{formError.header}</Alert>
                  )}
                  <Container>
                    <Row className="mb-3 align-items-center">
                          <Col lg={2} md={2} sm={2}>
                            <label>Is Banned</label>
                          </Col>
                          <Col lg={2} md={2} sm={2}>
                            <Input checked={formData?.banned} onChange={handleBanned} type="checkbox" />
                          </Col>
                    </Row>
                    {userInfo?.smartWalletAddress && (
                      <>
                        <Row className="mb-3 align-items-center">
                          <Col lg={2} md={2} sm={2}>
                            <label>Is Prop Player</label>
                          </Col>
                          <Col lg={2} md={2} sm={2}>
                            <Input checked={formData?.isPropPlayer} onChange={handleIsPropPlayer} type="checkbox" />
                          </Col>
                        </Row>
                        <Row className="mb-3 align-items-center">
                          <Col lg={2} md={2} sm={2}>
                            <h6 className="mb-2">USDT Balance</h6>
                          </Col>
                          <Col lg={2} md={2} sm={2}>
                            <ContractTokenBalance
                                tokenAddress={config.contracts.usdt}
                                contractAddress={userInfo?.smartWalletAddress as any}
                                abi={usdtABI}
                                decimals={config.usdtDecimals}
                                tokenSymbol="USDT"
                                simpleFormat={true}
                              />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2} md={2} sm={2}>
                            <h6 className="mb-2">PHNM Balance</h6>
                          </Col>
                          <Col lg={2} md={2} sm={2}>
                            <ContractTokenBalance
                              tokenAddress={config.contracts.phnm}
                              contractAddress={userInfo?.smartWalletAddress as any}
                              abi={phnmABI}
                              decimals={config.phnmDecimals}
                              tokenSymbol="PHNM"
                              simpleFormat={true}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </Container>
                  <Container>
                    <Row className="mb-3 my-3 align-items-center">
                          <Col lg={2} md={2} sm={2}>
                            <h6 className="mb-0">Total Rake:</h6>
                          </Col>
                          <Col lg={2} md={2} sm={2}>
                            ${userInfo?.totalGeneratedRake?.toFixed(2)}
                          </Col>
                    </Row>
                  </Container>
                  <form onSubmit={handleSubmit}>
                    <FormFields className="mb-4">
                      <Col
                        lg={5}
                        className="d-flex justify-content-center align-items-center"
                      >
                        {avatar?.modelUrl && (
                          <AvatarRender userId={userId} avaturnUser={avatar} />
                        )}
                      </Col>
                      <Col lg={5}>
                        <InfoField
                          name="User Id"
                          value={userId}
                          lg={12}
                          className="mb-sm-4"
                        />
                        <InfoField
                          name="Email"
                          value={formData?.email}
                          lg={12}
                          className="mb-sm-4"
                        />
                        <FormField
                          name="alias"
                          value={formData?.alias}
                          onChange={handleChange}
                          label="Alias"
                          error={formError.alias}
                          className="w-100"
                        />
                        <InfoField
                          name="cellphone"
                          value={
                            formData?.countryCode &&
                            formData.phone &&
                            formData.countryCode +
                              '-' +
                              formData.phone?.toString()
                          }
                          lg={12}
                          className="mb-sm-4"
                        />
                      </Col>
                    </FormFields>
                    <FormFields>
                      <FormField
                        name="firstName"
                        value={formData?.firstName}
                        onChange={handleChange}
                        label="First Name"
                        error={formError.firstName}
                      />
                      <FormField
                        name="lastName"
                        value={formData?.lastName}
                        onChange={handleChange}
                        label="Last Name"
                        error={formError.lastName}
                      />
                    </FormFields>
                    <FormFields className="mb-4">
                      <InfoField
                        name="Member since"
                        value={formatISOToDate(formData?.createdAt)}
                      />
                      <InfoField
                        name="Last Login"
                        value={formatISOToDate(formData?.lastLogin)}
                      />
                    </FormFields>
                    <FormFields className="mb-4">
                      <Col lg={5}>
                        <label>Tier</label>
                        <select
                            name="Tier"
                            onChange={handleTier}
                            required
                            className="form-select mb-3"
                            aria-label="Default select"
                            value={formData?.tier?.name}
                          >
                            {Object.values(userTiers).map(tier => (
                              <option value={tier}>
                                {tier}
                              </option>
                            ))}
                          </select>
                      </Col>
                    </FormFields>
                    <FormFields className="mb-4">
                      <Col lg={5}>
                        <label>User Role</label>
                        <select
                          name="gameMode"
                          onChange={handleRole}
                          required
                          className="form-select mb-3"
                          aria-label="Default select"
                          value={userRole}
                        >
                          <option value={UserRoles.NO_ROLE}>
                            {UserRoles.NO_ROLE}
                          </option>
                          <option value={UserRoles.AGENT}>
                            {UserRoles.AGENT}
                          </option>
                          <option value={UserRoles.AMBASSADOR}>
                            {UserRoles.AMBASSADOR}
                          </option>
                        </select>
                      </Col>
                      <InfoField
                        name="Agreement"
                        value={formData?.userAgreements?.map(
                          (agreement) => `${agreement.agreement}`
                        )}
                      />
                    </FormFields>
                    <FormFields className="mb-4">
                      {formData?.smartWalletAddress ? (
                        <CopyField
                          name="Smart Wallet Address"
                          value={formData?.smartWalletAddress}
                        />
                      ) : (
                        <InfoField
                          name="Smart Wallet Address"
                          value={formData?.smartWalletAddress}
                        />
                      )}
                      {formData?.mainWalletAddress ? (
                        <CopyField
                          name="Main Wallet Address"
                          value={formData?.mainWalletAddress}
                        />
                      ) : (
                        <InfoField
                          name="Main Wallet Address"
                          value={formData?.mainWalletAddress}
                        />
                      )}
                    </FormFields>
                    <FormFields>
                      <FormField
                        name="referrerCode"
                        value={formData?.referrerCode}
                        onChange={handleChange}
                        label="Player referral Code"
                        error={formError.referrerCode}
                      />
                      <FormField
                        name="referralCode"
                        value={formData?.referralCode}
                        onChange={handleChange}
                        label="Referred by Code"
                        error={formError.referralCode}
                      />
                    </FormFields>
                    <Col lg={10}>
                      <div className="d-flex flex-row mt-4 justify-content-end">
                        <Button
                          type="submit"
                          className="ms-5 my-4"
                          color="primary"
                          disabled={submission}
                        >
                          {submission && (
                            <Spinner size={'sm'} className="mx-2" />
                          )}{' '}
                          Update
                        </Button>
                      </div>
                    </Col>
                  </form>
                </CardBody>
              </Card>
              <Card className="card mb-2">
                <CardBody>
                  <TransactionsTable transactions={userTransactions} refreshTable={fetchTransactions} />
                </CardBody>
              </Card>
              <Card className="card mb-2">
                <CardBody>
                  <h3>Transfer:</h3>
                  <div className="d-flex justify-content-start gap-2 align-items-center">
                    <input value={transferAmount} onChange={(e: any) => setTransferAmount(e.target.value)}className="form-control w-25" type="number" />
                    <Button disabled={submission} onClick={handleSubmitTransfer} color="primary">
                      Submit
                      {' '}
                      {submission && (
                            <Spinner size={'sm'} className="mx-2" />
                      )}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default UserEdit;


import { TableAggregate } from "@/services/table/table-types";
import React from "react";
import ReactApexChart from "react-apexcharts";

const TableOverviewChart: React.FC<{
  dataColors: string[];
  ts: TableAggregate[];
}> = ({ dataColors, ts }) => {
  // Example dataColors array
  const exampleDataColors = ["#FF5733", "#33FF57", "#3357FF"];

  if (!ts) return <div>No data</div>;

  // Extract categories and series data from the ts array
  const categories = ts.map(
    (t) => new Date(t.dataRange).toISOString().split("T")[0]
  );
  const seriesData = ts.map((t) => t.totalAmount);

  var revenueExpensesChartsColors = dataColors || exampleDataColors;

  var options = {
    chart: {
      height: 350,
      type: "area" as const, // Add 'as const' here
      toolbar: { show: true },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth" as const,
    },
    xaxis: {
      categories: categories ? categories : [],
    },
    yaxis: {
      labels: {
        formatter: function (value: any) {
          return value.toFixed(2);
        },
      },
      tickAmount: 5,
      min: 0,
      max: Math.max(...seriesData) + 10,
    },
    colors: revenueExpensesChartsColors,
    fill: {
      opacity: 0.06,
      colors: revenueExpensesChartsColors,
      type: "solid",
    },
  };

  return (
    <div style={{ overflow: "hidden", padding: "10px", maxWidth: "100%" }}>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={[{ name: "Player Count", data: seriesData }]}
        type="area"
        height="290"
        className="apex-charts"
      />
    </div>
  );
};

export default TableOverviewChart;

import { useState, useMemo } from "react";
import { ToastContainer } from "react-toastify";
import { CardBody, Container, Card, Row, Col, Button } from "reactstrap";
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    flexRender,
    getPaginationRowModel
} from "@tanstack/react-table";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { fetchPropPlayerSessions } from '@/services/users/users-rest';
import { useQuery } from "@tanstack/react-query";
import CopyField from "@/common/forms/CopyField";
import config from '@/config/index';

import './prop-player-table.css';

const usePropPlayerPayPeriod = () => {
    const payPeriodOptions = useMemo((): string[] => {
        // Format a UTC date to YYYY/MM/DD
        const formatUTCDate = (date: Date): string => {
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            return `${year}/${month}/${day}`;
        };
    
        // Start date: November 4, 2024
        const startDate = new Date(Date.UTC(2024, 10, 4));
        
        // Current date in UTC
        const now = new Date();
        const currentDate = new Date(Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate()
        ));
    
        const ranges: string[] = [];
        let periodStart = new Date(startDate);
    
        while (periodStart <= currentDate) {
            // Calculate period end (7 days later)
            const periodEnd = new Date(periodStart);
            periodEnd.setUTCDate(periodEnd.getUTCDate() + 7);
    
            // Create the formatted range string
            const rangeString = `${formatUTCDate(periodStart)} - ${formatUTCDate(periodEnd)}`;
            ranges.push(rangeString);
    
            // Move to next period
            periodStart = new Date(periodEnd);
        }
    
        return ranges;
    }, []);

    const [selectedPayPeriod, setSelectedPayPeriod] = useState<string | null>(payPeriodOptions.reverse()[0]);

    return {
        payPeriodOptions,
        selectedPayPeriod,
        setSelectedPayPeriod
    };
}

const PropPlayers = () => {
    const {
        payPeriodOptions,
        selectedPayPeriod,
        setSelectedPayPeriod
    } = usePropPlayerPayPeriod();

    const { data } = useQuery({
        queryKey: ["propPlayerSessions", selectedPayPeriod],
        queryFn: () => fetchPropPlayerSessions(selectedPayPeriod),
    });

    const handlePayPeriodChange = (e: any) => {
        setSelectedPayPeriod(e.target.value);
    }

    return (
        <div className="page-content">
            <Col className="py-4 w-25">
                <select
                    onChange={handlePayPeriodChange}
                    className='form-select'
                    value={selectedPayPeriod || ''}
                >
                    <option value="">Select pay period</option>
                    {payPeriodOptions?.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>    
                    ))}
                </select>
            </Col>
            {data && (
                <PropPlayersTable selectedPayPeriod={selectedPayPeriod} data={data} />
            )}
            <ToastContainer />
        </div>
    );
};

const PropPlayersTable = ({ selectedPayPeriod, data }: { selectedPayPeriod: string | null, data: any }) => {
    const propPlayersCsv = useMemo(() => {
        const csv = [...data].sort((a,b) => b.pendingPayment - a.pendingPayment).map((row: any) => {
            return `${row.alias},${row.firstName},${row.lastName},${row.smartWalletAddress},${row.email},${row.minutesPlayed},${row.rakeGeneratedPerhour},${row.totalTimeSittingOut},${row.paidIn},${row.pendingPayment}`;
        }).join('\n');
        return `data:text/csv;charset=utf-8,alias,firstName,lastName,smartWalletAddress,email,minutesPlayed,rakeGeneratedPerHour,totalTimeSittingOut,paidIn,pendingPayment\n${csv}`;
    }, [data]);
    
    const columns = useMemo(() => {
        return [
            {
                id: 'alias',
                header: 'Alias',
                accessorKey: 'alias',
            },
            {
                id: 'firstName',
                header: 'First Name',
                accessorKey: 'firstName',
            },
            {
                id: 'lastName',
                header: 'Last Name',
                accessorKey: 'lastName',
            },
            {
                id: 'smartWallet',
                size: 200,
                header: 'Smart Wallet',
                accessorKey: 'smartWalletAddress',
                cell: (info: any) => {
                    const cellData = info.getValue();
                    return (
                        <CopyField
                            className="w-100"
                            name="userSmartWallet"
                            label={false}
                            value={cellData}
                            link={`${config.blockExplorerUrl}/address/${cellData}`}
                        />
                    );
                },
            },
            {
                id: 'email',
                header: 'Email',
                size: 200,
                accessorKey: 'email',
                cell: (info: any) => {
                    const cellData = info.getValue();
                    return (
                        <CopyField
                            className="w-100"
                            name="email"
                            label={false}
                            value={cellData}
                        />
                    );
                },
            },
            {
                id: 'minutesPlayed',
                header: 'Total Minutes Played',
                accessorKey: 'minutesPlayed',
            },
            {
                id: 'rakeGeneratedPerhour',
                header: 'Rake generated / HR',
                accessorKey: 'rakeGeneratedPerhour',
            },
            {
                id: 'totalTimeSittingOut',
                header: 'Total minutes sitting out',
                accessorKey: 'totalTimeSittingOut',
            },
            {
                id: 'paidIn',
                header: 'Paid In',
                accessorKey: 'paidIn',
            },
            {
                id: 'pendingPayment',
                header: 'Pending Payment',
                accessorKey: 'pendingPayment',
            },
        ];
    }, []);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        columnResizeDirection: 'ltr'
    });

    return (
        <Container fluid>
                <Row className="gy-2 mb-2">
                    <Card className="card mb-2">
                        <CardBody className="overflow-auto">
                            <Button link download={`prop-players__${selectedPayPeriod}.csv`} href={encodeURI(propPlayersCsv)}>Export</Button>
                            <table className="table table-container" style={{ width: table.getCenterTotalSize() }}>
                                    <thead>
                                        {table.getHeaderGroups().map((headerGroup) => {
                                            return (
                                                <tr key={headerGroup.id}>
                                                    {headerGroup.headers.map((header) => {
                                                        return (
                                                            <th
                                                                key={header.id}
                                                                colSpan={header.colSpan}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                                style={{ width: header.getSize(), textAlign: 'left' }}
                                                                className="table-header"
                                                            >
                                                                <div className="table-header-content">
                                                                    {header.isPlaceholder ? null : flexRender(
                                                                        header.column.columnDef.header,
                                                                        header.getContext()
                                                                    )}
                                                                    <div>
                                                                                {{
                                                                                asc: <FaArrowDown />,
                                                                                desc: <FaArrowUp />,
                                                                                default: (
                                                                                    <span>
                                                                                    <FaArrowDown/>
                                                                                    <FaArrowUp />
                                                                                    </span>
                                                                                )
                                                                                }[header.column.getIsSorted() as string || 'default']}          
                                                                    </div>
                                                                </div>
                                                                <div
                                                                        {...{
                                                                            onDoubleClick: () => header.column.resetSize(),
                                                                            onMouseDown: header.getResizeHandler(),
                                                                            onTouchStart: header.getResizeHandler(),
                                                                            className: `resizer ${
                                                                                table.options.columnResizeDirection
                                                                            } ${
                                                                                header.column.getIsResizing() ? 'isResizing' : ''
                                                                            }`,
                                                                        }}
                                                                    />
                                                                
                                                            </th>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </thead>
                                    <tbody>
                                        {table.getRowModel().rows.map((row) => (
                                                <tr key={row.id}>
                                                {row.getVisibleCells().map((cell: any) => (
                                                    <td className="table-cell" key={cell.id} style={{ width: cell.column.getSize() }}>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                            ))}
                                    </tbody>
                            </table>
                        </CardBody>
                        <div className="d-flex flex-row justify-content-center gap-2">
                            <Button
                                color="primary"
                                onClick={() => table.setPageIndex(0)}
                                disabled={!table.getCanPreviousPage()}
                            >
                                {"<<"}
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                            >
                                {"<"}
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                            >
                                {">"}
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                disabled={!table.getCanNextPage()}
                            >
                                {">>"}
                            </Button>
                        </div>
                    </Card>
                </Row>
            </Container>
    );
}

export default PropPlayers;
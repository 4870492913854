import { NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";

export interface Step {
  id: number;
  label: string;
}

const NavItemStep: React.FC<{
  step: Step;
  orchestrator: number;
  activeVerticalTab: number;
  toggleVerticalTab: (tab: number) => void;
}> = ({ step, orchestrator, activeVerticalTab, toggleVerticalTab }) => {
//   const classNames = orchestrator >= step.id ? "nav-link done" : "nav-link";
  const classNames = `nav-link ${orchestrator >= step.id ? "done" : ""} ${
    activeVerticalTab === step.id ? "active" : ""
  }`;
  return (
    <NavItem>
      <NavLink
        to={`#step-${step.id}`}
        className={()=>classNames}
        onClick={() => {
          toggleVerticalTab(step.id);
        }}
      >
        <span className="step-title me-2">
          <i className="ri-close-circle-fill step-icon me-2"></i>
          Step {step.id}
        </span>
        {step.label}
      </NavLink>
    </NavItem>
  );
};

export default NavItemStep;

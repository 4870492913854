import React, { useEffect, useState } from "react"
import { tableActivePlayersStatsRest, tablePlayersPerDayRest, tableWaitingPlayersStatsRest } from "@/services/table/table-service"
import { useQuery } from "@tanstack/react-query";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import KeyFigureStatic from "@/common/charts/KeyFigureStatic";
import { TableQueryDto } from "@/services/table/table-types";
import TableOverviewChart from "../analytics/components/TableOverviewChart";
import { QueryPeriods } from "@/common/QueryPeriods";
import { getDateMinusDays } from "@/common/query/QueryPeriods";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "@/common/showToast";



const TableAnalytics = () => {
  
  const today = new Date().toISOString().split("T")[0];

  const [days, setDays] = useState<number>(30);
  const [toDate, setToDate] = useState(today);
  const [fromDate, setFromDate] = useState(() => getDateMinusDays(days));
  
  const setRange = (range: number) => () => {  
    setDays(range)
    setFromDate(getDateMinusDays(range));
    setToDate(today); // Assuming 'today' is defined elsewhere in your code
  }

    const handleDateChange = (dates: Date[]) => {
        if (dates.length === 2) {
          const [start, end] = dates.map(
            (date) => date.toISOString().split("T")[0]
          );
          setFromDate(start);
          setToDate(end);
        }
      };

    
    const [params, setParams] = useState<TableQueryDto>({
        fromDate: fromDate,
        toDate: toDate,
        groupByDate: true,
        period: QueryPeriods.day,
    })

    useEffect(() => {

        const differenceInDays = Math.abs(new Date(toDate).getTime() - new Date(fromDate).getTime()) / (24 * 60 * 60 * 1000);        if (differenceInDays < 0) return (showToast("Invalid date range", "warning"))

        setDays(differenceInDays)

        setParams((prevState) => ({
            ...prevState,
            fromDate,
            toDate
        }));
    }, [fromDate, toDate]);
  
    
    const { data: playersPerDay, error: error0, isLoading: isLoading0 } = useQuery({
      queryKey: ['playersPerDay', params],
      queryFn: ({ queryKey }) => {
        const [, params] = queryKey as [string, TableQueryDto];
        return tablePlayersPerDayRest(params);
    }
  });

    const { data: activePlayersData, error: error1, isLoading: isLoading1 } = useQuery({
        queryKey: ['activePlayers'],
        queryFn: tableActivePlayersStatsRest
    });

    const { data: waitingPlayersData, error: error2, isLoading: isLoading2 } = useQuery({
        queryKey: ['waitingPlayers'],
        queryFn: tableWaitingPlayersStatsRest
    });

    const itemGenerator = (value : number, label: string, prefix = "", icon = "bx bx-dollar-circle", decimals=2) => {

        return {
            id: 1,
            cardColor: "primary",
            label: label,
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            counter: value,
            link: "View daily rake",
            bgcolor: "secondary",
            icon,
            decimals,
            prefix: prefix,
            suffix: "",
            separator: ",",
          }
        
        }

    return (
        <React.Fragment>
            <div className="page-content">
              <Container fluid className="container-fluid">
                <Row className="gy-2 mb-2 d-flex flex-row justify-content-between">
                  <KeyFigureStatic item={ itemGenerator(activePlayersData?.totalTables, "Tables Running", "", "bx bx-table",0)} />
                  <KeyFigureStatic item={ itemGenerator(activePlayersData?.totalStack, "USDT in play", "$") } />
                  <KeyFigureStatic item={ itemGenerator(activePlayersData?.totalPlayers, "Active Players", "", "bx bx-user-circle",0)} />
                </Row>
            
          <Row className="gy-2 mb-2">
              <Row className="g-3 mt-5 mb-2 d-flex justify-content-end">
                    <div className="col-sm-auto">
                      <div className="input-group">
                        <Flatpickr
                          className="form-control border-0 dash-filter-picker shadow"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            defaultDate: [fromDate, toDate],
                          }}
                          onChange={handleDateChange}
                        />
                        <div className="input-group-text bg-primary border-primary text-white">
                          <i className="ri-calendar-2-line"></i>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-rows gap-2 justify-content-end">
                      <button className={"btn" + (days === 30 ? " btn-primary" : " btn-dark")} onClick={setRange(30)}>30</button>
                      <button className={"btn" + (days === 60 ? " btn-primary" : " btn-dark")} onClick={setRange(60)}>60</button>
                      <button className={"btn" + (days === 90 ? " btn-primary" : " btn-dark")} onClick={setRange(90)}>90</button>
                  </div>
              </Row>
          </Row>

            <Row className="gy-2 mb-2">
              <Col lg={12}>
                <Row>
                  <Card>
                    <CardHeader className="border-0 align-middle d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">Players per day</h4>
                    </CardHeader>
                    <CardBody>
                      <TableOverviewChart dataColors={["#FF5733", "#33FF57", "#3357FF"]} ts={playersPerDay} />
                  </CardBody>
                  </Card>

              </Row>
              </Col>
            </Row>
        </Container>
        </div>
        
        <ToastContainer/>
        </React.Fragment>
        )
}


export default TableAnalytics
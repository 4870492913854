import React, { useEffect, useState } from "react";
import {
  createTemplateRest,
} from "@/services/template/templateRest";
import routes from "@/routes/routes-enums";
import FormField from "./form-field";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "@/common/showToast";
import { PokerVariantIds } from "@/common/types/phenomTypes";
import { Button, Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { maxPlayersOptions } from "@/common/forms/options/MaxPlayerOptions";
import checkVariants from "@/common/forms/table-handling/checkVariants";
import { useForm, Control, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { AxiosError } from "axios";
import FormFields from "@/common/forms/FormFields";
import { useNavigate } from "react-router-dom";

const TemplateCreate = () => {
  const navigate = useNavigate();


  const [isLimitGame, setIsLimitGame] = useState<boolean>(false);
  const [isStudGame, setIsStudGame] = useState<boolean>(false);
  const [submission, setSubmission] = useState<boolean>(false);

  const createRedirection = (templateId : number) => {
    navigate(`${routes.TABLES}/details/${String(templateId)}`);
  };


  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Template Name is required"),
    game: Yup.string().required("Game Name is required"),
    variants: Yup.string()
      .oneOf(Object.values(PokerVariantIds), "Invalid variant")
      .required("Variants are required"),
    minBuyin: Yup.number().required("Min Buyin is required"),
    maxBuyin: Yup.number()
      .required("Max Buyin is required")
      .moreThan(Yup.ref('minBuyin'), "Max Buyin should be greater than Min Buyin"),
    smallBlind: Yup.number().required("Small Blind is required"),
    bigBlind: Yup.number()
      .required("Big Blind is required")
      .moreThan(Yup.ref('smallBlind'), "Big Blind should be greater than Small Blind"),
    smallBet: Yup.number().when('isLimitGame', {
      is: true,
      then: schema => schema.required("Small Bet is required"),
      otherwise: schema => schema.nullable()
    }),
    bigBet: Yup.number().when('isLimitGame', {
      is: true,
      then: schema => schema.required("Big Bet is required"),
      otherwise: schema => schema.nullable()
    }),
    bettingCap: Yup.number().when('isLimitGame', {
      is: true,
      then: schema => schema.required("Betting Cap is required"),
      otherwise: schema => schema.nullable()
    }),
    bringIn: Yup.number().when('isStudGame', {
      is: true,
      then: schema => schema.required("Bring In is required"),
      otherwise: schema => schema.nullable()
    }),
    ante: Yup.number().required("Ante is required"),
    rakePercent: Yup.number().required("Rake Percentage is required"),
    rakeCap: Yup.number().required("Rake Cap is required"),
    rakeCapHU: Yup.number().required("Rake Cap Heads Up is required"),
    maxPlayers: Yup.number().required("Max Players is required"),
    isLimitGame: Yup.boolean(),
    isStudGame: Yup.boolean(),
    id: Yup.number().notRequired(),
  });

  type ValidationSchema = Yup.InferType<typeof validationSchema>;

  const { register, handleSubmit, setValue, control, formState: { errors }, reset } = useForm<ValidationSchema>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      game: "",
      minBuyin: 0,
      maxBuyin: 0,
      smallBlind: 0,
      bigBlind: 0,
      smallBet: 0,
      bigBet: 0,
      bettingCap: 0,
      bringIn: 0,
      ante: 0,
      maxPlayers: 0,
      rakePercent: 0,
      rakeCap: 0,
      rakeCapHU: 0,
      isLimitGame,
      isStudGame,
    },
  });

  const onSubmit = async (formData: ValidationSchema) => {
    const variants: PokerVariantIds[] = [];
    if (!formData.variants) {
      showToast("Please, review the data provided.", "warning");
      return;
    }
    variants.push(formData.variants as PokerVariantIds);

    setSubmission(true)
    try {
      const response = await createTemplateRest({
        name: formData.name,
        game: formData.game,
        variants,
        maxPlayers: formData.maxPlayers * 1,
        ante: formData.ante * 1,
        maxBuyin: formData.maxBuyin * 1,
        minBuyin: formData.minBuyin * 1,
        smallBlind: formData.smallBlind * 1,
        bigBlind: formData.bigBlind * 1,
        bringIn: isStudGame ? formData.bringIn ? formData.bringIn * 1 : undefined : undefined,
        bettingCap: isLimitGame ? formData.bettingCap ? formData.bettingCap * 1 : undefined : undefined,
        smallBet: isLimitGame ? formData.smallBet ? formData.smallBet * 1 : undefined : undefined,
        bigBet: isLimitGame ? formData.bigBet ? formData.bigBet * 1 : undefined : undefined,
        rakePercent: formData.rakePercent,
        rakeCap: formData.rakeCap * 1,
        rakeCapHU: formData.rakeCapHU * 1,
      });

      if ((response.status === 201 || response.status === 200) && response.data.id) {
        createRedirection(response.data.id);
        setSubmission(true)
        return;
      }

    } catch (error) {
      setSubmission(false);
      if (error instanceof AxiosError && error?.response?.data) {
        const customError = error.response.data as {
          message: string[];
          statusCode: number;
          error: string;
        };
        if (customError.statusCode === 400 && customError.message) {
          customError.message.forEach((element) => {
            showToast(element, "warning");
          });
          return;
        } else {
          showToast(error.message, "warning");
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content mb-6">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Card className="card mb-2">
                <CardBody className="card-body">
                  <h3 className="mb-5">Template Creation</h3>
                  <form onSubmit={handleSubmit(onSubmit)}>

                  <FormFields>
                    <FormField
                      name="name"
                      control={control}
                      label="Template Name"
                      error={errors.name?.message}
                    />
                    <FormField
                      name="game"
                      control={control}
                      label="Game Name"
                      error={errors.game?.message}
                    />
                  </FormFields>
                  <FormFields>
                    <Col lg={5} sm={12} className={`mb-4`}>
                    <label htmlFor="variants">Select Variants</label>
                      <div className="input-group">
                      <Controller
                        name="variants"
                        control={control}
                        render={({ field }) => (
                          <select
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              const { stud, limit } = checkVariants([e.target.value as PokerVariantIds]);
                              setIsStudGame(stud);
                              setIsLimitGame(limit);
                            }}
                            className={`form-select ${errors.variants ? "is-invalid" : ""}`}

                          >
                            <option value="">Select variant</option>
                            {Object.entries(PokerVariantIds).map(([key, value]) => (
                              <option key={key} value={value}>
                                {key}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                    </div>
                    {errors.variants && <span className="invalid-feedback">{errors.variants.message}</span>}
                    </Col>
                  </FormFields>
                  <FormFields>
                    <FormField
                      name="minBuyin"
                      control={control}
                      label="Min Buyin"
                      error={errors.minBuyin?.message}
                      prefix="$"
                    />
                    <FormField
                      name="maxBuyin"
                      control={control}
                      label="Max Buyin"
                      error={errors.maxBuyin?.message}
                      prefix="$"
                    />
                  </FormFields>
                  <FormFields>
                    <FormField
                      name="smallBlind"
                      control={control}
                      label="Small Blind"
                      error={errors.smallBlind?.message}
                      prefix="$"
                    />
                    <FormField
                      name="bigBlind"
                      control={control}
                      label="Big Blind"
                      error={errors.bigBlind?.message}
                      prefix="$"
                    />
                  </FormFields>
                    {isLimitGame && (
                      <>
                      <FormFields>
                          <FormField
                            name="smallBet"
                            control={control}
                            label="Small Bet"
                            error={errors.smallBet?.message}
                            prefix="$"
                          />
                          <FormField
                            name="bigBet"
                            control={control}
                            label="Big Bet"
                            error={errors.bigBet?.message}
                            prefix="$"
                          />
                        </FormFields>
                        <FormField
                          name="bettingCap"
                          control={control}
                          label="Betting Cap"
                          error={errors.bettingCap?.message}
                          prefix="$"
                        />
                      </>
                    )}
                    {isStudGame && (
                      <FormField
                        name="bringIn"
                        control={control}
                        label="Bring In"
                        error={errors.bringIn?.message}
                        prefix="$"
                      />
                    )}
                  <FormFields>
                    <FormField
                      name="ante"
                      control={control}
                      label="Ante"
                      error={errors.ante?.message}
                      prefix="$"
                    />
                    <FormField
                      type="select"
                      name="maxPlayers"
                      control={control}
                      label="Table Size"
                      error={errors.maxPlayers?.message}
                      options={maxPlayersOptions()}
                    />
                  </FormFields>
                  <FormFields>
                    <Col lg={5} sm={12} className={`mb-4 `}>
                    <div className="form-field">
                      <label htmlFor="rakePercent" >
                        Rake Percentage
                      </label>
                      <Controller
                        name="rakePercent"
                        control={control as Control<ValidationSchema>}
                        render={({ field: { onChange, value } }) => (
                          
                          <div className="input-group">
                            <span className="input-group-text">%</span>
                            <input
                            type="number"
                            placeholder="Enter percentage"
                            className={`form-control ${errors.rakePercent?.message ? "is-invalid" : ""}`}
                            value={(value || value === 0) ? value * 100 : ''}
                            onChange={(e) => {
                              const valueAsNumber = parseFloat(e.target.value);
                              if (!isNaN(valueAsNumber)) {
                                onChange(valueAsNumber / 100);
                              } else {
                                onChange('');
                              }
                            }}
                            />
                          </div>
                        )}
                      />


                      {errors.rakePercent?.message && <span className="error">{errors.rakePercent?.message}</span>}
                    </div>
                    </Col>
                  </FormFields>
                  
                  <FormFields>
                    <FormField
                      name="rakeCap"
                      control={control}
                      label="Rake Cap"
                      error={errors.rakeCap?.message}
                      prefix="$"
                    />
                    <FormField
                      name="rakeCapHU"
                      control={control}
                      label="Rake Cap Heads Up"
                      error={errors.rakeCapHU?.message}
                      prefix="$"
                    />
                  </FormFields>
                    <Col lg={10} className="mt-5">
                      <div className="d-flex flex-row mt-4 justify-content-end">
                        <Button type="submit" className="ms-5 my-4" color="primary" disabled={submission}>
                          { submission && <Spinner size={"sm"} className="mx-2"/>} Create

                        </Button>
                      </div>
                    </Col>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default TemplateCreate;

import { endRewardsDistributionRest, payRewardsRest } from "@/services/rewards/rewards-manager-service"
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap"

const EndRewardsDistribution = () => {

    const endRewardsDistribution = () => {
        payRewardsRest().then(()=>{}).catch(()=>{})
    }

    return (
        <Card>
            <CardHeader>
            <h1>Process ended</h1>
            </CardHeader>
            <CardBody>
            <p>Revenuew, Rewards and share payment process was successfully completed.</p>
            </CardBody>

        </Card>
        )
    
    }

    export default EndRewardsDistribution
import React, { useEffect, useState, useRef, useCallback } from "react";

interface DebouncedInputProps {
  type?: string;
  value: string | number;
  placeholder?: string;
  className?: string;
  onChange: (value: string | number) => void;
  debounce?: number;
  isLoading: boolean;
}

const DebouncedInput: React.FC<DebouncedInputProps> = ({
  type,
  value: externalValue,
  placeholder,
  className,
  onChange,
  debounce = 300,
  isLoading,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState<string | number>(externalValue);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Sync internal state with external value
  useEffect(() => {
    setInternalValue(externalValue);
  }, [externalValue]);

  const debouncedOnChange = useCallback((value: string | number) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      onChange(value);
    }, debounce);
  }, [onChange, debounce]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInternalValue(newValue);
    debouncedOnChange(newValue);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="position-relative">
      <input
        {...props}
        ref={inputRef}
        type={type}
        className={className}
        placeholder={placeholder}
        value={internalValue}
        onChange={handleChange}
      />
      {isLoading && (
        <div className="spinner-border spinner-border-sm text-primary position-absolute top-50 end-0 translate-middle" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </div>
  );
};

export default DebouncedInput;